import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from "react-router-dom";

import Card from '@material-ui/core/Card';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoIcon from '@material-ui/icons/Info';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import RockyIcon from '../Modules/RockyIcon';
//import ForumIcon from '@material-ui/icons/Forum';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingAbout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openSetting: false,
        };
    }

    render() {
        //const { user } = this.props;
        const { theme, classes } = this.props;
        //console.log("window params", window)
        return (
            <div className={classes.grow}>
                <Card style={theme.cardSettings}>
                    {this.props.defaultSet?.programCompany?.length > 2  &&
                        <p style={theme.textSettings}>
                            <span style={theme.textSettings.settingsTextValue}>
                                {
                                //<a href="https://www.rocky.ai" target="_blank" rel="noopener noreferrer">www.rocky.ai</a>
                                }
                                <span style={{color: theme.palette.secondary.main}}
                                    onClick={() => window.open((this.props.defaultSet?.programSupportURL?.length > 2 ? this.props.defaultSet?.programSupportURL : "https://www.rocky.ai"), "_blank")}>
                                    {this.props.defaultSet?.programCompany}
                                </span>
                            </span>
                            <span style={theme.textSettings.settingsTextDescription}>
                                <InfoIcon />
                            </span>
                        </p>      
                    }
                    {this.props.defaultSet?.programCompany?.length > 2 
                    ?
                        true
                        ?   ""
                        :
                            <p style={theme.textSettings}>
                                <span style={theme.textSettings.settingsTextValue}>
                                    {
                                    //<a href="https://www.rocky.ai" target="_blank" rel="noopener noreferrer">www.rocky.ai</a>
                                    }
                                    <span style={{color: theme.palette.secondary.main}}
                                        onClick={() => window.open("https://www.rocky.ai", "_blank")}>
                                        Powered by Rocky.ai
                                    </span>
                                </span>
                                <span style={theme.textSettings.settingsTextDescription}>
                                    <RockyIcon />
                                </span>
                            </p>
                    :
                        <p style={theme.textSettings}>
                            <span style={theme.textSettings.settingsTextValue}>
                                {
                                //<a href="https://www.rocky.ai" target="_blank" rel="noopener noreferrer">www.rocky.ai</a>
                                }
                                <Link style={{color: theme.palette.secondary.main}}
                                    to={void(0)}
                                    onClick={() => window.open("https://www.rocky.ai", "_blank")}>
                                    www.rocky.ai
                                </Link>
                            </span>
                            <span style={theme.textSettings.settingsTextDescription}>
                                <InfoIcon />
                            </span>
                        </p>
                    }   
                    <p style={theme.textSettings}>
                        <span style={theme.textSettings.settingsTextValue}>
                            {
                            //<a href="https://www.rocky.ai/customersupport" target="_blank" rel="noopener noreferrer">Help & Feedback</a>
                            }
                            <Link style={{color: theme.palette.secondary.main}}
                                to={void(0)}
                                onClick={ () => window.open((this.props?.defaultSet?.programSupportURL?.length>2 ? this.props?.defaultSet?.programSupportURL : "https://www.rocky.ai/customersupport"), "_blank")}>
                                Service & Support
                            </Link>
                        </span>
                        <span style={theme.textSettings.settingsTextDescription}>
                            <HelpOutlineIcon />
                        </span>
                    </p>
                    <p style={theme.textSettings}>
                        <span style={theme.textSettings.settingsTextValue}>
                            {
                             //<a href="https://www.rocky.ai/privacy-policy" target="_blank" rel="noopener noreferrer"> Privacy Policy  </a>
                            }
                            <Link style={{color: theme.palette.secondary.main}}
                                to={void(0)}
                                onClick={ () => window.open("https://www.rocky.ai/privacy-policy", "_blank")}>
                                Privacy Policy
                            </Link>
                        </span>
                        <span style={theme.textSettings.settingsTextDescription}>
                            <VpnKeyIcon />
                        </span>
                    </p>
                        <p style={theme.textSettings}>
                        <span style={theme.textSettings.settingsTextValue}>
                            {
                            // <a href="https://www.rocky.ai/terms-and-conditions" target="_blank" rel="noopener noreferrer"> Terms & Conditions </a>
                            }
                            <Link style={{color: theme.palette.secondary.main}}
                                to={void(0)}
                                onClick={ () => window.open("https://www.rocky.ai/terms-and-conditions", "_blank")}>
                                Terms & Conditions
                            </Link>
                        </span>
                        <span style={theme.textSettings.settingsTextDescription}>
                            <HowToRegIcon />
                        </span>
                    </p>
                    {window!== undefined && window.AppVersion !== undefined && window.AppVersion.version !== undefined  &&
                            <p style={theme.textSettings}>
                                <span style={theme.textSettings.settingsTextDescription} >
                                    <span >Version &nbsp;{ window.AppVersion.version }</span>
                                </span>
                            </p>
                    }
                </Card>
            </div>
                
        );
    }
}


export default compose(
  withStyles(styles, { withTheme: true }),
)(SettingAbout);
