//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        //paddingLeft:  20,
        //paddingRight: 20,
        backgroundColor: palette.backPrimary,
        borderRadius: 12,
        '& .MuiTypography-root': {
            color: palette.themeText,
        },
        '& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover': {
        //'& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover, .MuiPaper-root:hover': {
            //backgroundColor: palette.secondary.light,
            //color: palette.themeAlertRed,
            backgroundColor: palette.secondary.light,
            color: palette.white + " !important",
            //borderRadius: 0,
            boxShadow: 'none'
        },
    },
    "&$expanded": {
        backgroundColor: palette.backPrimary,
    }
}}

export default muiStyle;