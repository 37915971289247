//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        color: palette.icon,
        padding: 6,
        marginLeft: 6,
        marginRight: 6,
        '&:hover': {
            backgroundColor: palette.secondary.light,
            color: palette.white,
        }
    }
}}

export default muiStyle;