import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import { AddAnalytics } from '../Modules/AddAnalytics';
import {StoreMobileDeviceInfo } from '../Modules/StoreMobileDeviceInfo';
// style used same as from singin module
import {stylesSignin, linksInfo} from '../SignIn';
import {SignInSocialForward} from '../SignIn/SignInPage';
import {pageText} from '../SignIn';
import {PushForwardAfterSigning} from '../SignIn';

import PropTypes from "prop-types";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import logo from "../../images/rockyai-logo-image-only.png";
// import { SignInGoogle, SignInFacebook, SignInTwitter } from '../SignIn';
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
//import backgroundImage from "../../images/login-background.jpg";
//import people from "../../images/register-screen-people.png";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LoadingProgress from '../Modules/LoadingProgress';

//HN
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import queryString from 'query-string';

/// Anonymous sign up
import Switch from '@material-ui/core/Switch';
import { AddCodeToExistingAccountSet, MyInstallationOfflineDataSet, GetDefaultUserSetup, GetUltimimUserSetup } from "../Modules/DefaultCode";

class SignUpPage extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        
        var loginType = 'normal';
        if (window.location && window.location.search !== undefined && window.location.search!==""){
            //var query = queryString.parse(window.location.search)
            var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
            var query = queryString.parse(urlSearch);
            //console.log("detail linking singup", query)
            if(query.scope!==undefined && query.scope === "profile:user_id"){
                loginType = "alexaLinking"
            }
        }
        this.state = { ...INITIAL_STATE , loginType};
    }

    componentDidMount() {
        this._isMounted = true;
        this.internetConnectionCheck();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    internetConnectionCheck() {
        this.props.firebase.onDisconnectSimple(this, (connected) => {
            if(connected === false && this.state.firebaseConnectionError === undefined){
                if(this._isMounted){
                    this.setState({firebaseConnectionMessage : 'checking your internet...'})
                }
            }
            else if(connected === false){
                if(this._isMounted){
                    this.setState({firebaseConnectionMessage : 'Trying to connect with the internet!'})
                }
            }
        });
    }

    render () {
        const { classes, theme, defaultSet } = this.props;

        if(this.state.firebaseConnectionError===true){
            return(
                <div className={classes.rootSignup}>
                    <div>
                        <div style={{paddingTop: '25vH', paddingBottom: 20}} >
                            <LoadingProgress 
                                defaultSet={defaultSet}
                                type={'LoadingPageAnimated'} color='light' loadingText={"I'm getting ready"} />
                        </div>
                        <div >
                            {this.state.firebaseConnectionMessage !== undefined && this.state.firebaseConnectionMessage !== ''
                                &&
                                <p style={theme.textCenterPadding}>
                                    &nbsp; {this.state.firebaseConnectionMessage}
                                </p> 
                            }
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className={classes.rootFrame}>
                    <div className={classes.rootSignup}>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} style={{ maxWidth: 500 }}>
                                <div className={classes.login}>
                                    <div className={classes.logoContainer}>
                                        <img src={logo} className={classes.logo} alt="Rocky.ai Logo Chatbot Journaling" />
                                        <p style={theme.divCenter}>
                                            <strong>Hi there, it's nice to meet you!</strong><br/><br/>
                                            Get started with your digital coach to develop yourself
                                            {this.state.loginType === "alexaLinking"
                                                ?  <strong> <br/> and connect with Alexa!</strong>
                                                :  <span>!</span>
                                            }
                                        </p>
                                    </div>
                                    <SignUpFormSimple 
                                        loginType={this.state.loginType}
                                    />
                                    <div>
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td><hr /></td>
                                                    <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                        <span className={classes.socialLoginPrompt}>Already signed up?</span>
                                                    </td>
                                                    <td><hr /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className={classes.socialLogins}>
                                            <Link to={ROUTES.SIGN_IN}>
                                                <Button className={classes.buttonBottom}>
                                                    Log In
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={classes.footer}>
                                        {linksInfo(defaultSet)}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ); //end return
        }
    }; //end redner
}; //end class


export function CreateUserWithEmail(that, username, email, password, myInstallationUserDataSet=MyInstallationOfflineDataSet){
    //console.log('CreateUserWithEmail', email, myInstallationUserDataSet)
    that.props.firebase
    .doCreateUserWithEmailAndPassword(email, password)
    .then(authUser => {
        that.userId = authUser.user.uid;
        var onboardingSet = '';
        if(window.sessionStorage.getItem('onboardingResponses')){
            try{
                myInstallationUserDataSet['onboardingResponses'] = window.sessionStorage.getItem('onboardingResponses').split(',');
                onboardingSet = myInstallationUserDataSet['onboardingResponses'];
            }
            catch(err) {
                console.log('Error getting onboardingResponses', err)
            }
            window.sessionStorage.removeItem('onboardingResponses');
        }
        AddAnalytics('sign_up', that.state.checkAnonymous? 'anonymous' : 'email', onboardingSet);
        //console.log('CreateUserWithEmail adding user', authUser.user.uid)
        that.props.firebase.user(authUser.user.uid).set(
            myInstallationUserDataSet,
            { merge: true }
        )
        .then(() => {
            console.log('CreateUserWithEmail snapshot')
            PushForwardAfterSigning(that, myInstallationUserDataSet);
            /***
            if(that.props.loginType === "alexaLinking"){
                that.props.history.push(ROUTES.ALEXA_SIGN_IN);
            }
            else if(myInstallationUserDataSet.onboardingRequired === false){
                that.props.history.push(ROUTES.HOME);
            }
            else if(myInstallationUserDataSet.onboardingDone !== true){
                that.props.history.push(ROUTES.ONBOARDING);
            }
            else{
                that.props.history.push(ROUTES.HOME);
            }
             */
        })
        .catch(err => {
            console.log('SignUp EEerror', err);
            return;
        });
    })
    .catch(error => {
        //console.log('SignUp Catch Eerror', err);
        if (error.code === pageText.ERROR_CODE_ACCOUNT_EXISTS || error.code ==='auth/email-already-in-use') {
            error.message = ERROR_MSG_ACCOUNT_EXISTS_ACTION;
            if(!that.state.checkAnonymous && password !== undefined && password !== ''){
                that.props.firebase
                .doSignInWithEmailAndPassword(email, password)
                .then((authUser) => {
                    console.log('User already exists')
                    error.message = pageText.ERROR_MSG_ACCOUNT_EXISTS;
                    that.setState({ error });
                    var receivedUserId = authUser.user.uid;
                    StoreMobileDeviceInfo(that.props.firebase.user(receivedUserId));
                    UpdateExistingUserSignUp(that, authUser.user.uid, myInstallationUserDataSet, username);
                    setTimeout(() => {
                        console.log('timeeout, Stored user set')
                    }, 1000);
                    ////// Ensure that the user is not repeating the onboarding
                    //myInstallationUserDataSet['onboardingDone'] = true;
                    PushForwardAfterSigning(that, myInstallationUserDataSet);
                    /***
                    that.props.history.replace(ROUTES.PROFILE);
                    if(that.props.loginType === "alexaLinking"){
                        that.props.history.push(ROUTES.ALEXA_SIGN_IN);
                    }
                    else if(myInstallationUserDataSet.onboardingRequired === false){
                        that.props.history.push(ROUTES.HOME);
                    }
                    else if(myInstallationUserDataSet.onboardingDone !== true){
                        that.props.history.push(ROUTES.ONBOARDING);
                    }
                    else{
                        that.props.history.push(ROUTES.HOME);
                    }
                    */
                })
                .catch(error => {
                    if (error.code === pageText.ERROR_CODE_WRONG_PASSWORD) {
                        error.message = pageText.ERROR_MSG_WRONG_PASSWORD;
                        that.props.firebase.fetchSignInMethodsForEmail(email)
                        .then(result => {
                            if(result.includes('google.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_GOOGLE;
                                that.setState({ error });
                            }
                            else if(result.includes('apple.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_APPLE;
                                that.setState({ error });
                            }
                            else if(result.includes('facebook.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_FACEBOOK;
                                that.setState({ error });
                            }
                            else if(result.includes('microsoft.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_MICROSOFT;
                                that.setState({ error });
                            }
                        })
                    }
                    if (error.code === pageText.ERROR_CODE_INVALID_EMAIL) {
                        error.message = pageText.ERROR_MSG_INVALID_EMAIL;
                    }
                    that.setState({ error });
                });
            }
        }
        else if (error.code === pageText.ERROR_CODE_INVALID_EMAIL) {
            error.message = pageText.ERROR_MSG_INVALID_EMAIL;
        }
        that.setState({ error });
    });
}

export function UpdateExistingUserSignUp(that, uid, myInstallationUserDataSet, username=""){
    try{
        ///// OVERWRITE SESSION username INFO
        var userUpdate = {};
        var update = false;
        if(username !== ''){
            userUpdate['username'] = username;
            update = true;
            //that.props.firebase.user(uid).update({username});
        }
        if(myInstallationUserDataSet?.groups?.length > 0){
            userUpdate['groups'] = that.props.firebase.fieldValue.arrayUnion(...myInstallationUserDataSet.groups);
            update = true;
        }
        if(myInstallationUserDataSet?.programs?.length > 0){
            userUpdate['programs'] = that.props.firebase.fieldValue.arrayUnion(...myInstallationUserDataSet.programs);
            update = true;
        }
        ///// OVERWRITE CODE ROLE
        if(myInstallationUserDataSet?.roles?.includes('REMOTECOACH') || myInstallationUserDataSet?.roles?.includes('COACHADMIN')){
            //console.log('update user roles', myInstallationUserDataSet)
            var newRoles = myInstallationUserDataSet.roles;
            userUpdate['roles'] = that.props.firebase.fieldValue.arrayUnion(...newRoles);
            update = true;
            if(myInstallationUserDataSet?.adminPrograms?.length > 0){
                //userUpdate['adminPrograms'] = that.props.firebase.fieldValue.arrayUnion(...myInstallationUserDataSet.adminPrograms);
            }
            //that.props.firebase.user(uid).update({roles: that.props.firebase.fieldValue.arrayUnion(...newRoles)});
        }
        ///// OVERWRITE SESSION STROAGE INFO
        if(window.sessionStorage.getItem('onboardingResponses')){
            var arrayOnboardingResponses = window.sessionStorage.getItem('onboardingResponses').split(',');
            update = true;
            userUpdate['onboardingResponses'] = arrayOnboardingResponses;
            //that.props.firebase.user(uid).update({onboardingResponses: arrayOnboardingResponses});
            window.sessionStorage.removeItem('onboardingResponses');
        }
        ///// Overwrite notification after resignin 
        if(true){
            userUpdate["mobileNotifications"] = "daily";
            userUpdate["emailNotificationsInitiated"] = false;
            userUpdate["mobileNotificationsInitiated"] = false;
            update = true;
        }
        if(update){
            //console.log('userUpdate', userUpdate)
            that.props.firebase.user(uid).update(userUpdate);
        }
    }
    catch(err) {
        console.log('Error getting UpdateExistingUserSignUp', err)
    }
}

const INITIAL_STATE = {
    username: "",
    email: "",
    passwordOne: "",
    coachcode: "",
    error: null,
    openCode: false,
};

const ERROR_MSG_ACCOUNT_EXISTS_ACTION = (
    <span>
        Hi there, we’ve already met! Looks like you already have an account. Please{" "}
        <Link to={ROUTES.SIGN_IN}> go to Log In</Link> and use this email address.{" "}
    </span>
);

class SignUpFormSimpleBase extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        var loadedCode = undefined;
        var coachcode = window?.sessionStorage?.getItem('urlCoachCode') || "";
        if(coachcode?.length > 3 && coachcode[0] === "#"){
            loadedCode = coachcode;
        }
        var coachPricode = window?.sessionStorage?.getItem('urlPrioCoachCode') || "";
        if(coachPricode?.length > 3 && coachPricode[0] === "#"){
            loadedCode = coachPricode;
        }
        //console.log('loaded form gtemp', coachcode, dataSet)
        this.state = { 
            ...INITIAL_STATE, 
            falseCode: false, 
            coachcode: loadedCode
        };
        this.userId = "";
    }

    
    componentDidMount() {
        this._isMounted = true;
        if (this.props.location && this.props.location.state && this.props.location.state.checkAnonymous){
            if((this.props.coach === undefined || this.props.coach !== true )
                && this.state.openCode !== true 
                && this.state.loginType !== "alexaLinking"){
                this.setState({
                    checkAnonymous: this.props.location.state.checkAnonymous,
                    username: "Padawan",
                    //error:{ message : 'You can add en email address later if you want to get notifications or access your account from other devices!'}
                }); 
            }
        }
        /// this function is used by SignUpCoachPage and overwrites the default setup
        var defaultDataSet = this.state.installationSet || {};
        if(this.props.defaultSignUpCode !== undefined && this.props.defaultSignUpCode!==''){
            GetDefaultUserSetup(this.props.firebase, defaultDataSet, this.props.defaultSignUpCode, true).then((result) => {
                if(this._isMounted){
                    this.setState({installationSet: result}); 
                }
            });
        }
        else if (false){
            GetDefaultUserSetup(this.props.firebase, defaultDataSet).then((result) => {
                if(this._isMounted){
                    this.setState({installationSet: result}); 
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSubmitDefault = async (event) => {
        //GetUltimimUserSetup dsfvdsvsdf 
        this.setState({signUpDetected : true, error: null})
        console.log('init db', this.state.defaultAccount, this.state, this.props);
        const { username, passwordOne, coachcode, defaultAccount } = this.state;
        var password  = passwordOne;
        var email  = this.state.email;
        var falseCodeProvided = false;
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        var myInstallationUserDataSet = this.props.myInstallationOfflineDataSet || MyInstallationOfflineDataSet;
        if(myInstallationUserDataSet?.["offlineSetUsed"] === true){
            myInstallationUserDataSet = await GetUltimimUserSetup(this.props.firebase, myInstallationUserDataSet, this.props.defaultSet, this.props.defaultSignUpCode, this.props.defaultSet?.programCode || "")
            console.log(' addNewUser myInstallationUserDataSet', myInstallationUserDataSet)
        }
        //// ANONYNPUS
        if(this.state.checkAnonymous){
            var dateEmail = new Date();
            var timestampEmail = dateEmail.getTime();
            email = "anonymous" + timestampEmail + '@rocky.ai';
            password = Math.random().toString(36).slice(-8);
            //console.log(this.state.checkAnonymous, 'anonymous email',  email)
        }
        const isInvalid = password === "" || email === "" || username === "";
        if(isInvalid){
            event.preventDefault();
            var errorMissingData = new Error(pageText.ERROR_MSG_ADD_INFO);
            if(this._isMounted){
                this.setState({ error : errorMissingData });
            }
        }
        else{
            try{
                email = email.toLowerCase();
            }
            catch(err) {
                console.log(err)
            }
        }
        //console.log('code starting', code)
        ////// IF a code is added on top
        if (coachcode?.includes("#")){
            event.preventDefault();
            //const [codeExists, newSet] = ['false', {}];
            //let codeResults = this.checkForCode(code);
            //console.log('is a code there?', codeResults.codeExists)
            var codeSet = await this.props.firebase.signupCodeByName(coachcode).get()
            //console.log('newSet', myInstallationUserDataSet, codeSet)
            if(codeSet.exists){
                var newSet = codeSet.data().addingCode; 
                myInstallationUserDataSet['code'] = coachcode;
                myInstallationUserDataSet = AddCodeToExistingAccountSet(myInstallationUserDataSet, newSet);
                //console.log('singup set second', myInstallationUserDataSet, newSet)
                //this.setState({ error: null, falseCode: false});
                event.persist();
            }
            else{
                falseCodeProvided = true;
                //console.log('singup no extra data', myInstallationUserDataSet);
                var error = new Error(pageText.ERROR_MSG_INVALID_CODE); 
                if(this._isMounted){
                    this.setState({ error, falseCode: true});
                }
            }
        };//end of if code
        ////// TEST ONLY
        //falseCodeProvided = true;
        //console.log('falseCodeProvided', falseCodeProvided, myInstallationUserDataSet   );
        // this falseCode does not work yet due to async
        if(!falseCodeProvided && !isInvalid){
            if(this.state.checkAnonymous){
                Object.assign(myInstallationUserDataSet, {username, email, journeyStartedAt: timestamp, anonymousSignup: true});
                myInstallationUserDataSet['emailNotifications'] = 'inactive';
                myInstallationUserDataSet['anonymousPassword'] = password;
            }
            else{
                Object.assign(myInstallationUserDataSet, {username, email, journeyStartedAt: timestamp});
            }
            //console.log('im in', myInstallationUserDataSet);
            CreateUserWithEmail(this, username, email, password, myInstallationUserDataSet);
        }// end FalseCode
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    onChangeCode = event => {
        var newCode = event.target.value.trim()
        if(newCode.length > 0) { 
            newCode = newCode.replace(/[^a-z0-9-]/gi, '');
            newCode = newCode.toUpperCase();
            if(newCode.charAt(0) !== '#'){
                newCode = '#'+newCode;
            }
        }
        this.setState({ [event.target.name]: newCode });
    };
    onChangeEmail = event => {
        this.setState({ [event.target.name]: event.target.value.trim() });
    };
    onChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };
    // HN handler for code
    handleCodeClick = () => {
        if(this.state.openCode === false && (this._isMounted)){
            this.setState({openCode: true, checkAnonymous: false});
        }
        if(this.state.openCode === true && (this._isMounted)){
            this.setState({openCode: false, coachcode:''});
        }        
        //console.log('close', this.state.openCode);
    };

    render() {
        //console.log(this.state)
        const { classes, theme } = this.props;
        const {
            username,
            email,
            passwordOne,
            coachcode,
            error
        } = this.state;
        return(
            <div>
                <Dialog
                    open={this.state.signUpDetected || false}
                    onClose={()=>{this.setState({signUpDetected:false})}}
                    PaperProps={{style:{borderRadius: theme.borderRadius}}}
                    >
                    <DialogContent>
                        <div className={classes.form}>
                            {!error && 
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} loadingText={"creating account"} />
                            }
                            {error && <p className={classes.error}>{error.message}</p>}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{this.setState({signUpDetected:false})}} color="primary">
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {(this.props.coach === undefined || this.props.coach !== true )
                    //&& this.state.openCode !== true 
                    && this.state.loginType !== "alexaLinking"
                    &&
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        style={{width:'100%'}}
                    >
                        <Grid item xs={5} style={{textAlign:'center', paddingRight:10}}>
                            {this.state.checkAnonymous
                                ? 
                                    <span style={{color: theme.palette.secondary.main, textDecoration: 'underline' }}
                                        onClick={ () => this.setState({checkAnonymous: false, error:[] }) }
                                        >
                                        {"Serious Go-Getter"}
                                    </span>
                                :
                                    <strong style={{textAlign:'center',  fontSize:'large',  color: theme.palette.secondary.light }}>
                                        {"Serious Go-Getter"}
                                    </strong>
                            }
                        </Grid>
                        <Grid item xs={2} style={{textAlign:'center'}}>
                            <Switch
                                checked={this.state.checkAnonymous||false}
                                onTouchStart={
                                    this.state.modeOnMouseDown
                                        ?   void(0)
                                        :   this.state.checkAnonymous
                                            ? () => { this.setState({modeOnTouchStart: true, checkAnonymous: false, error:[], username: this.state.username === "Padawan" ? '' : this.state.username }) }
                                            : () => { this.setState({modeOnTouchStart: true, checkAnonymous: true, openCode: false, coachcode: ''
                                                        // error:{ message : 'You can add en email address later if you want to get notifications or access your account from other devices!'}
                                    }) }
                                }
                                onMouseDown={
                                    this.state.modeOnTouchStart
                                        ?   void(0)
                                        :   this.state.checkAnonymous
                                            ? () => { this.setState({modeOnMouseDown: true, checkAnonymous: false, error:[], username: this.state.username === "Padawan" ? '' : this.state.username }) }
                                            : () => { this.setState({modeOnMouseDown: true, checkAnonymous: true, openCode: false, coachcode: ''
                                                        // error:{ message : 'You can add en email address later if you want to get notifications or access your account from other devices!'}
                                    }) }
                                }
                                style={{
                                    color: theme.palette.secondary.main,
                                    //color:  "#BDC2C5",
                                    '&$checked': {
                                        color:  theme.palette.secondary.light,
                                    },
                                }}
                                name="checkAnonymous"
                                color="secondary"
                            />
                        </Grid>
                        <Grid item xs={5} style={{textAlign:'center', paddingLeft:10}}>
                            {this.state.checkAnonymous
                                ? 
                                    <strong style={{ fontSize:'large', color: theme.palette.secondary.light }}>
                                        {"Anonymous Tester"}
                                    </strong>
                                :
                                    <span style={{ color: theme.palette.secondary.main, textDecoration: 'underline' }}
                                        onClick={ () => this.setState({checkAnonymous: true, error:[] }) }
                                        >
                                        {"Anonymous Tester"}
                                    </span>
                            }
                        </Grid>
                    </Grid>
                }
                <form onSubmit={this.onSubmitDefault}>
                    <TextField
                        name="username"
                        label={this.state.checkAnonymous ? "Nick name" : pageText.form_username}
                        value={username}
                        onChange={this.onChange}
                        type="text"
                        variant="outlined"
                        placeholder={this.props.coach ? "Name" : pageText.form_username}
                        fullWidth
                        className={classes.textField}
                    />
                    {this.state.checkAnonymous!== true &&
                        <TextField
                            name="email"
                            label={pageText.form_email}
                            value={email}
                            onChange={this.onChangeEmail}
                            type="text"
                            variant="outlined"
                            placeholder={pageText.form_email}
                            fullWidth
                            className={classes.textField}
                        />
                    }
                    {this.state.checkAnonymous!== true &&
                        <TextField
                            name="passwordOne"
                            label={pageText.form_password}
                            value={passwordOne}
                            onChange={this.onChange}
                            type={this.state.showPassword ? "text" : "password"}
                            variant="outlined"
                            placeholder={"Set a new password"}
                            fullWidth
                            className={classes.textField}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({showPassword: !this.state.showPassword})}
                                        >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                  </InputAdornment>
                                )
                            }}
                        />
                    }

                    {/* 2019-06-14 Remove the password confirmation */}
                    {/* <TextField */}
                    {/*   name="passwordTwo" */}
                    {/*   //label="Confirm Password" */}
                    {/*   value={passwordTwo} */}
                    {/*   onChange={this.onChange} */}
                    {/*   type="password" */}
                    {/*   placeholder="Confirm Password" */}
                    {/*   fullWidth */}
                    {/* /> */}

                    {error && <p className={classes.error}>{error.message}</p>}

                    {this.state.checkAnonymous !== true && this.props.hideCoachcode !== true 
                        ?
                            <div style={{ marginTop: 10, marginBottom:  10 }}> 
                                <p style={{marginTop:10, textAlign: 'right'}}>
                                    Do you have a coach-code?
                                </p>
                                <div style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                                    <Button onClick={() => this.setState({showSignUpButtons: true, openCode: true, showSignCoachCode: true})}>
                                        Yes
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button onClick={() => this.setState({showSignUpButtons: true, showSignCoachCode: false, openCode: false, coachcode:''})}>
                                        No
                                    </Button>
                                </div>
                                <Collapse in={this.state.openCode} timeout="auto" unmountOnExit>
                                    <TextField
                                        name="coachcode"
                                        label={"Coach-Code"}
                                        value={coachcode}
                                        onChange={this.onChangeCode}
                                        type="text"
                                        variant="outlined"
                                        placeholder={pageText.form_code}
                                        fullWidth
                                    />
                                </Collapse>
                            </div>
                        :   this.props.allowURLCoachcode && (coachcode?.includes("#") || coachcode === "")
                            ?
                                <div style={{ marginTop: 20, marginBottom:  10, display: 'block' }}> 
                                    <span style={theme.textSupportCenter}>
                                        {pageText.label_code}
                                    </span>
                                    <TextField
                                        name="coachcode"
                                        //label={"Coach-Code"}
                                        value={coachcode}
                                        onChange={this.onChangeCode}
                                        type="text"
                                        variant="outlined"
                                        placeholder={pageText.form_code}
                                        fullWidth
                                    />
                                </div>
                            :
                                ""
                    }
                    <div className={classes.socialLogins}>
                        <div style={{margin: 30}}>
                        <Button
                            className={classes.buttonBottom}
                            disabled={coachcode?.length>2 ? false : ((this.props.codeRequired === false ? false : this.props.coach) || false)}
                            
                            type="submit"
                        >
                            Sign&nbsp;up&nbsp;for&nbsp;FREE
                        </Button>
                        </div>
                    </div>
                    {(this.props.coach === undefined || this.props.coach!==true || this.props.showSocial === true)
                        &&
                        <div>
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td><hr /></td>
                                        <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                            <span className={classes.socialLoginPrompt}>or sign up with</span>
                                        </td>
                                        <td><hr /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <SignInSocialForward 
                                defaultSet={this.props.defaultSet || {}}
                                defaultSignUpCode={this.props.defaultSignUpCode || undefined} 
                                myInstallationOfflineDataSet= {this.props.myInstallationOfflineDataSet || undefined}
                                coachcode = {coachcode || undefined}
                                username={username!==undefined && username.length>1 ? username : undefined}
                            />
                            {this.state.openCode  &&
                                <p className={classes.socialLoginPrompt}>{pageText.label_code_info}</p>
                            }
                        </div>
                    }
                    <div className={classes.socialLogins}>
                        <p style={{textAlign: 'center', marginTop: 20, fontSize: 14}}> 
                            By signing up, you agree to the Rocky.ai
                            {" "}
                            <span style={{color: theme.palette.secondary.main, textDecoration: 'underline'}}
                                onClick={ () => window.open("https://www.rocky.ai/terms-and-conditions", "_blank")}>
                                Terms of Service
                            </span>
                            {
                            //<a href="https://www.rocky.ai/terms-and-conditions" target="_blank" rel="noopener noreferrer"> Terms of Service </a>
                            }
                            {". "}
                            Read our 
                            {" "}
                            <span style={{color: theme.palette.secondary.main, textDecoration: 'underline'}}
                                onClick={ () => window.open("https://www.rocky.ai/privacy-policy", "_blank")}>
                                Privacy Policy
                            </span>
                            {
                                //<a href="https://www.rocky.ai/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            }
                        </p>
                    </div>
                </form>
            </div>
        );
    }
}

SignUpPage.propTypes = {
    classes: PropTypes.object.isRequired
};


export const SignUpFormSimple = compose(
    withStyles(stylesSignin, { withTheme: true }),
    withFirebase,
    withRouter,
)(SignUpFormSimpleBase);


export default compose(
    withRouter,
    withStyles(stylesSignin, { withTheme: true }),
    withFirebase,
)(SignUpPage);