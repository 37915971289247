import React, { Component } from 'react';
//import { Switch, Route } from 'react-router-dom';
//import {  Link } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
//import PropTypes from 'prop-types';
import { withFirebase } from '../../Firebase';
import LoadingProgress from '../../Modules/LoadingProgress';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import {GetNewExplanationRockyAI, GetNewSentencesRockyAI} from '../../Coach/ManageQuestions';
import {AddExampletoNLUTraining, DescriptionSentiment, UtterancesDescriptions, SentimentTopicIntentsPrefixes, SelectIntentsList, SentimentPrefixChanger, TopicIntentsPrefixes, UtterancesLeadQuestions, UtterancesMainQuestions} from '../Training/TrainingDataManagement';
import * as ROLES from '../../../constants/roles';
//import * as ROUTES from '../../../constants/routes';
import * as chatAgent from '../../../chatAgent';

import DialogIntents from '../Modules/DialogIntents';
import VizSensor from 'react-visibility-sensor';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import FileCopyIcon from '@material-ui/icons/FileCopy';
// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ButtonSwitch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';

// Icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
//import DialogKeyWords from '../Admin/Content/DialogKeyWords';
import StarsIcon from '@material-ui/icons/Stars';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

/// filter radio buttons
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Icons
import AddIcon from '@material-ui/icons/Add';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import Tooltip from '@material-ui/core/Tooltip';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import SchoolIcon from '@material-ui/icons/School';
import ChildCareIcon from '@material-ui/icons/ChildCare';
// Modules for parameter change
//import SettingContentBase from '../Modules/SettingContentBase';
//import SettingContentActive from '../Modules/SettingContentActive';
//import SettingContentNumber from '../Modules/SettingContentNumber';
import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Modules/SettingContentRichText';
import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentSelect from '../Modules/SettingContentSelect';
import SettingContentSpecialUtteranceText from '../Modules/SettingContentSpecialUtteranceText';
import SettingContentArrayCheckbox from '../Modules/SettingContentArrayCheckbox';
import { QualityID } from '../../Modules/LoadCategoriesAndQualities';

const styles = {
    iCircleActive: {
        background: "#39cccc",
        color: "#fff",
        padding: "3px 5px",
        borderRadius: "50%",
        fontSize: "12px",
    },
    iCircleInActive: {
        background: "lightgrey",
        color: "darkgrey",
        padding: "3px 5px",
        borderRadius: "50%",
        fontSize: "12px",
    }
};

export function GetAdminLeadershipCategoriesArray(that) {
    var myArray = [];
    var myArrayText = [];
    var categoriesByName = {};
    if(that.context.roles?.includes('ADMIN') || that.context.roles?.includes('TEAM')){
        myArray = ['default', 'asIntent'];
        myArrayText = ['default for every context', 'default if INTENT is selected'];
    }
    var adminPrograms = that?.context?.adminPrograms || [];
    if(adminPrograms.includes('default_all')){
        adminPrograms.push('default')
    }
    if(adminPrograms.length === 0 && that.context?.roles?.includes('ADMIN')){
        adminPrograms = ['default']
    }
    //console.log('adminPrograms', adminPrograms, that)
    if(!(adminPrograms.length === 0) ){
        that.props.firebase
        .leadershipCategories() 
        .orderBy('text', 'asc')
        //.where('active', '==', true)
        .where('program', 'in', adminPrograms)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                return;
            }
            snapshot.forEach(doc => {
                if(true){
                    myArray.push(doc.data().value);
                    myArrayText.push(doc.data().text);
                    var itemName = doc.data().text;
                    if (doc.data().active === false){
                        itemName = "inactive - " + itemName;
                    }
                    categoriesByName[doc.data().value] = itemName;
                };
            });
            that.setState({
                leadershipCategoriesValues: myArray,
                leadershipCategoriesValuesText: myArrayText,
                categoriesByName
            });
        });
    }
}

export function GetAdminLeadershipQualitiesArray(that) {
    //console.log('Admin leadershipQualitiesGroups start', that)
    var myArray = [];
    var myArrayText = [];
    var leadershipQualitiesGroups = {};
    var leadershipQualitiesGroupsText = {};
    var adminPrograms = that?.context?.adminPrograms || [];
    if(adminPrograms.includes('default_all')){
        adminPrograms.push('default')
    }
    if(that.context.roles?.includes('ADMIN') || that.context.roles?.includes('TEAM')){
        myArray = ['default', 'asIntent'];
        myArrayText = ['default for every context', 'default if INTENT is selected'];
        leadershipQualitiesGroups = {'ADMIN': ['default', 'asIntent']};
        leadershipQualitiesGroupsText = {'ADMIN' : ['default for every context', 'default if INTENT is selected']};
    }
    if(adminPrograms.length === 0 && that.context?.roles?.includes('ADMIN')){
        adminPrograms = ['default']
    }
    if(!(adminPrograms.length === 0) ){
        that.props.firebase
        .leadershipQualities()
        //.where('active', '==', true)
        .where('program', 'in', adminPrograms)
        .orderBy('category', 'asc')
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
                return;
            }
            snapshot.forEach(doc => {
                var qualityDict = doc.data();
                if(true){
                    var value = QualityID (qualityDict.value || qualityDict.value);
                    var category = qualityDict.category;
                    var catName = category;
                    if(that.state.categoriesByName?.[category]?.length > 0){
                        catName = that.state.categoriesByName[category];
                    }
                    if(!Object.keys(leadershipQualitiesGroups).includes(catName)){
                        leadershipQualitiesGroups[catName] = [];
                        leadershipQualitiesGroupsText[catName] = [];
                    }
                    myArray.push(value);
                    leadershipQualitiesGroups[catName].push(value);
                    leadershipQualitiesGroupsText[catName].push(qualityDict.text);
                    if(category!=="" && category.length>12){
                        category = category.substring(0,11)+'...'
                    }
                    myArrayText.push("(" + catName + ") " + qualityDict.text);
                };
            });
            that.setState({
                leadershipQualitiesValues: myArray,
                leadershipQualitiesValuesText: myArrayText,
                leadershipQualitiesGroups,
                leadershipQualitiesGroupsText
            });
            //console.log('Admin leadershipQualitiesGroups set', leadershipQualitiesGroups)
        });
    }
}

export async function UpdateAIQuestions(that, questionIds, key, subKey, addAsIntent=false){
    console.log('questionIds', questionIds);
    var runningUpdate = !(that.state.runningUpdate || false);
    that.setState({runningUpdate});
    console.log('STARTED', runningUpdate)
    var dt = new Date(); 
    var dtSeconds = dt.getTime() / 1000 - 21600;// minus 4 weeks
    console.log('seconds', dtSeconds)
    if(runningUpdate){
        var updated = 0;
        for(let i = 0; i < questionIds.length; i++){
            var qID = questionIds[i];
            /// load item from the different pages
            var item = that.state.questionsLoaded?.[qID] || that.state.content || {};
            if(updated > 2 && that.state.runningUpdate!==undefined && !that.state.runningUpdate){
                console.log('STOPP')
                break;
            }
            if((true || item?.status === 'active') && (item.nlu?.topic === undefined || item.updatedAt?.seconds < dtSeconds)){
                console.log('q', that.state.runningUpdate, item)
                var mood = 'default';
                if(item.sentiment?.length > 2){
                    mood = item.sentiment;
                }
                var text = item.template?.text || '';
                if(text?.length >= 2){
                    var intentResult = await chatAgent.sendMessage({ 'text': text}, 'nluAnalysis');
                    var intent = intentResult?.intent?.name || 'none';
                    var intentAcc = intentResult?.intent?.confidence || 0;
                    var topic = intentResult?.response_selector?.[intent]?.response?.intent_response_key || 'none';
                    topic = topic.replace(intent+'/','')
                    var topicAcc = intentResult?.response_selector?.[intent]?.response?.confidence || 0;
                    //console.log('intent SHort', intent, intentAcc, topic, topicAcc);
                    var updateDict = {
                        updatedAt : that.props.firebase.fieldValue.serverTimestamp(),
                        nlu : {
                            intent,
                            intentAcc,
                            topic,
                            topicAcc
                        }
                    }
                    //// preFix to replace to intention modules
                    var intentTopic = '' + topic;
                    intentTopic = '' + intentTopic.replace('inform_', '');
                    intentTopic = '' + intentTopic.replace('question_', '');
                    console.log('intentResult', intentTopic, topicAcc, intentResult);
                    if(intentTopic.indexOf('_') >= 0 && topicAcc > 0.7){
                        var intentionsList = [];
                        intentTopic = intentTopic.substring(intentTopic.indexOf('_') + 1);
                        //// mood to discover which items to add
                        if (mood === 'negative'){
                            intentionsList.push('inform_challenge_'+intentTopic);
                            intentionsList.push('inform_indicator_'+intentTopic);
                        }
                        else if (mood === 'positive'){
                            intentionsList.push('inform_sentiment_'+intentTopic);
                            intentionsList.push('inform_indicator_'+intentTopic);
                        }
                        else{
                            intentionsList.push('inform_activity_'+intentTopic);
                            intentionsList.push('inform_indicator_'+intentTopic);
                        }
                        updateDict['intents'] = that.props.firebase.fieldValue.arrayUnion(...intentionsList);
                        //console.log('updateDict', updated, subKey, updateDict)
                        updated += 1;
                    }
                    if(addAsIntent){
                        updateDict['leadership'] = that.props.firebase.fieldValue.arrayUnion('asIntent');
                        updateDict['category'] = that.props.firebase.fieldValue.arrayUnion('asIntent');
                    }
                    that.props.firebase.agentUtter(subKey, qID).set(updateDict, {merge:true } );
                }
            }
        }
        that.setState({runningUpdate: false});
    }
}

class AdminQuestionUtterancesPage extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        var errorMessage = '';
        console.log('kick lading utterances')
        this.state = {
            loading: true,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            errorMessage: errorMessage,
            text: '',
            value: '',
            program: '',
            open: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        console.log('start lading utterances')

        var name = '';

        this.unsubscribe = this.props.firebase
        .leadershipQualities()
        .orderBy('text', 'asc')
        .get().then(snapshot => {
        //.onSnapshot(snapshot => {
            let contentsTab = {'default':[{value:'default', program:'default', category:'default', explanationShort:'default'}]};
            let contentsLabels = ['default'];
            var keySorting = 'category'
            snapshot.forEach(doc =>{
            if(this.context.roles?.includes('ADMIN')){
                if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                    name = doc.data()[keySorting];
                    if(name === undefined){
                        name = '---undefined---'
                    }
                    if(!(name in contentsTab)){
                        //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                        contentsTab[name] = []
                        contentsLabels.push(name );
                    }
                    contentsTab[name].push({ ...doc.data(), uid: doc.id });
                } // end if true
            } // end of if ADMIN
            else if(this.context.roles?.includes('COACHADMIN')){
                if(this.context.programs?.includes(doc.data().program) && doc.data().program !=='default'){
                    name = doc.data()[keySorting];
                    if(name === undefined){
                        name = '---undefined---'
                    }
                    if(!(name in contentsTab)){
                        //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                        contentsTab[name] = []
                        contentsLabels.push(name );
                    }
                    contentsTab[name].push({ ...doc.data(), uid: doc.id });
                }//end if program / no default
            } // end of if ADMIN
            });//end of snapshot

            //console.log('contents', contentsTab)

            if(this.state.showTab === undefined || this.state.showTab === ''){
                this.setState({showTab: name})
            }
            this.setState({
                contentsTab: contentsTab,
                contentsLabels,
                loading: false,
            });
        });
        GetAdminLeadershipCategoriesArray(this);
        GetAdminLeadershipQualitiesArray(this);
        //this.getleadershipCategoriesArray();
        //this.getleadershipQualitiesArray();
        this.getIntentsArray();
    }

    getIntentsArray() {
        //var intentGroups = {};
        var intentIds = [];
        var intentIdsText = [];
        var intentIdsDict = {};
        var intentIdsDictText = {};
        this.props.firebase
        .agentIntentClasses()
        .where('active', '==', true)
        .orderBy('intent', 'asc')
        .orderBy('sentiment', 'asc')
        .get().then(snapshot => {
            if(this._isMounted){
                snapshot.forEach(doc => {
                    var groupId = "undefined";
                    var intentData = doc.data();
                    if(intentData.sentiment.includes('_short') && !intentData.sentiment.includes('_past') && !intentData.sentiment.includes('_future') ){
                        groupId = intentData.sentiment;
                        var flagAddIntent = true;
                        //console.log('this.context.adminIntents', this.context.adminIntents, intentData.intentTags)
                        if(intentData.intentTags !== undefined && this.context.adminIntents !== undefined && this.context.adminIntents.length>0){
                            flagAddIntent = false;

                            this.context.adminIntents.map(tag => {
                                if(intentData.intentTags[tag]){
                                    flagAddIntent = true;
                                }
                                return true;
                            })
                        }
                        if(flagAddIntent){
                            var response = "n/a";
                            if(intentData.responses!== undefined && intentData.responses.length > 0){
                                //console.log(intentData.responses)
                                response = intentData.responses[0]
                                if (response.length > 30){
                                    response = response.substring(0,30) + "..."
                                }
                            }
                            var text = intentData.intent + ' - ' + response
                            //var text = "(" + groupId.split('_')[1] + ") " + intentData.intent + ' - ' + intentData.tenses
                            intentIds.push(intentData.class);
                            intentIdsText.push(text);
                            if(!Object.keys(intentIdsDict).includes(groupId)){
                                intentIdsDict[groupId] = [];
                                intentIdsDictText[groupId] = [];
                            }
                            intentIdsDict[groupId].push(intentData.class);
                            intentIdsDictText[groupId].push(text);
                        }
                    }
                });
            }
            if (this._isMounted) {
                //console.log(intentIdsDictText)
                this.setState({
                    //intentGroups,
                    intentIds,
                    intentIdsText,
                    intentIdsDict,
                    intentIdsDictText
                });
            }
        });
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };

    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };

    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.text !== '' && this.state.value !== '' && this.state.program !== '')
        {
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        this.props.firebase.leadershipQualities().add({
            text: this.state.text,
            value: this.state.value,
            program: this.state.program,
            active: false,
            createdAt: timestamp
        });
        this.setState({errorMessage : 'Added item (category undefined)'})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a category'})
        }
        
        this.setState({
            text: '',
            value: '',
            program: '',
        });

        this.handleClose();
    }

    componentWillUnmount() {
        this._isMounted = false;
        //this.unsubscribe();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
            showTab: newValue
        });
    }

    render() {
        const { showTab, contentsTab, contentsLabels, loading } = this.state;
        console.log('tabs', contentsLabels)
        if(loading){
            return(
                <div> 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                </div>
            )
        }
        else{
            return (
                <div style={{height: '100vh', backgroundColor: "#DDDDDD"}}>
                    {loading && 
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"many, many, data"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                    }
                    {this.state.errorMessage !== '' && 
                        <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                    }
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={this.handleTabChange}
                        initialselectedindex={0}
                        value={showTab}
                    >
                    {contentsLabels.length>0 && 
                        contentsLabels.map( (key) => (
                        <Tab 
                            value={key} 
                            key={key}
                            label={key + " ("+contentsTab[key].length+")"}/>
                        ))
                    }
                    </Tabs>
                    
                    {contentsLabels.length>0 && 
                        contentsLabels.map( (key, index) => (
                            <div 
                                value={key} 
                                key={key+'_'+index} 
                                hidden={showTab !==key}>
                                <Table size="small">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Active</TableCell>
                                        <TableCell>Text</TableCell>
                                        <TableCell>Program</TableCell>
                                        <TableCell align="right">Edit</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {contentsTab[key].map((content, index) => (
                                        <TableRow key={content.uid+"-"+index}>
                                            <TableCell  onClick={(e) => {this.setState({selectedLeadershipQuality : content.value })}}> 
                                                {content.active!== undefined
                                                ?
                                                    content.active===true
                                                    ?<ButtonSwitch disabled color="secondary" checked
                                                    value={content.active} size="small" 
                                                    style={{ backgroundColor: 'blue' || 'blue' }}/>
                                                    :<ButtonSwitch 
                                                    disabled checked={false} 
                                                    value={content.active} size="small" />
                                                :
                                                    <span>{"-"}</span>
                                                }
                                            </TableCell>
                                            <TableCell >{content.value}</TableCell>
                                            
                                            <TableCell><strong>Program:</strong> {content.program}</TableCell>
                                            <TableCell >{content.explanationShort}</TableCell>
                                            <TableCell  onClick={(e) => {this.setState({selectedLeadershipQuality : content.value })}} align="right">
                                                Edit
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </div>
                        ))
                    }
                    <MongoUtterances 
                        leadershipQuality={this.state.selectedLeadershipQuality} 
                        leadershipCategory={showTab} 
                        availableCategories={this.state.contentsLabels} 
                        availableQualities={this.state.contentsTab[showTab]}
                        leadershipQualities={this.state.leadershipQualities}
                        leadershipQualitiesText={this.state.leadershipQualitiesText}
                        leadershipQualitiesGroups={this.state.leadershipQualitiesGroups}
                        leadershipQualitiesGroupsText={this.state.leadershipQualitiesGroupsText}
                        leadershipCategories={this.state.leadershipCategories}
                        leadershipCategoriesText={this.state.leadershipCategoriesText}
                        intentIds = {this.state.intentIds}
                        intentIdsText = {this.state.intentIdsText}
                        intentIdsDict = {this.state.intentIdsDict}
                        intentIdsDictText= {this.state.intentIdsDictText}
                    />
                </div>
            );
        }
    }
}



class MongoUtterancesListBase extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);

        var leadershipQuality = 'undefined'
        if(this.props.leadershipQuality !== 'undefined' && this.props.leadershipQuality !== ''){
            leadershipQuality = this.props.leadershipQuality
        }
        //console.log('MongoUtterancesListBase create', this.props)
        this.state = {
            loading: true,
            content: null,
            errorMessage: '',
            leadershipQuality,
            leadershipQualities: (this.props.leadershipQualities || undefined),
            leadershipQualitiesValues: (this.props.leadershipQualitiesValues || undefined),
            leadershipQualitiesValuesText: (this.props.leadershipQualitiesValuesText || undefined),
            leadershipCategoriesValues: (this.props.leadershipCategoriesValues || undefined),
            leadershipCategoriesValuesText: (this.props.leadershipCategoriesValuesText || undefined),
        };
    }

    componentDidUpdate(prevProps){
        if(this.props.leadershipQuality?.length > 0){
            if(this.props.searchAutoUpdateFlag && this.props.searchTextArray !== prevProps.searchTextArray && this.state.loading !== true){
                //console.log('update search', this.props.searchTextArray)
                this.loadUtteranceByText(this.props.searchTextArray)
            }
            else if(this.props.leadershipCategory !== prevProps.leadershipCategory ) {
                this.setState({ leadershipCategory : this.props.leadershipCategory})
                //console.log('load cat', this.props.leadershipCategory)
                this.loadUtterances('default', this.props.leadershipCategory)
            }
            else if(this.props.leadershipQuality !== prevProps.leadershipQuality ) {
                this.setState({ leadershipQuality : this.props.leadershipQuality})
                this.loadUtterances(this.props.leadershipQuality)
            }
        }
        if(this.props.openOverlapMatch === true && this.state.open !== true && this.state.resultOverlapID!==undefined){
            this.setState({open: true, contentId: this.state.resultOverlapID, utterId: this.state.resultOverlapUtter});
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if(this.props.searchByText && this.props.searchTextArray!==undefined){
            var searchTextArray = this.props.searchTextArray || [];
            //console.log('searchTextArray', searchTextArray)
            this.loadUtteranceByText(searchTextArray)
        }
        else{
            this.props.firebase
            .agentUtterClasses().get()
            .then(snapshot => {
                var allClasses = [];
                let utterDef = {};
                snapshot.forEach(doc =>{
                    if(this.context.roles?.includes('ADMIN')){
                        if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                            var name = doc.data().utterance_class;
                            allClasses.push(name);
                            utterDef[name] = doc.data()
                        } // end if true
                    } // end of if ADMIN
                })
                if(this._isMounted){
                    this.setState({
                        allClasses,
                        utterDef
                    });
                }
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined) this.unsubscribe();
        if(this.unsubscribe2!==undefined) this.unsubscribe2();
    }

    searchTextHandler = () => {
        if(this.unsubscribe!==undefined) this.unsubscribe();
        if(this.unsubscribe2!==undefined) this.unsubscribe2();
        this.loadUtteranceByText(this.state.searchText);
    }


    loadUtterances = (leadershipQuality, category="") => {
        if(leadershipQuality !== undefined && leadershipQuality !== null && leadershipQuality !== ''){
            //console.log('search for ', leadershipQuality)
            var dbCall = this.props.firebase.agentUttersList([leadershipQuality, 'default'])
            if(category.length > 0){
                dbCall = this.props.firebase.agentUttersListByCat([category, 'default'])
            }
            this.setState({loading: true});
            //.orderBy('active', 'asc')
            //.get().then(snapshot => {
            this.unsubscribe = dbCall
            .onSnapshot(snapshot => {
                if(this._isMounted ){
                    let utterancesTab = {};
                    let utterancesLabels = [
                        'utter_ask_energy_level',
                        'utter_ask_satisfaction_level',
                        'utter_welcome_to_priming',
                        'utter_welcome_to_reflection',
                        'utter_welcome_to_program',
                        'utter_inform_mindset_phase',
                        'utter_affirm_greeting',
                        'utter_inform_leadership_quality_phase',
                        'utter_inform_todays_leadership_quality_phase',
                        'utter_confirm_content',
                        'utter_inform_skill_assessment_phase',
                        'utter_confirm_assessment',
                        'utter_ask_mindset',
                        'utter_ask_awareness',
                        'utter_ask_leadership_quality_question',
                        'utter_ask_quiz',
                        'utter_ask_second_leadership_quality_question',
                        'utter_ask_assessment',
                        'utter_confirm_priorities',
                        'utter_inform_priority_phase',
                        'utter_ask_activity',
                        'utter_ask_task',
                        'utter_ask_priority',
                        'utter_ask_priority_highest',
                        'utter_ask_priority_question',
                        'utter_ask_priority_highest_task',
                        'utter_ask_priorities',
                        'utter_ask_priorities_today',
                        'utter_ask_goal_today',
                        'utter_inform_goal_phase',
                        'utter_ask_goal',
                        'utter_ask_goal_assessment',
                        'utter_ask_goal_habit',
                        'utter_ask_relation_priority_goal',
                        'utter_inform_cta_phase',
                        'utter_ask_cta_activities',
                        'utter_final_question',
                        'utter_ask_feedback',
                        'utter_ask_help',
                        'utter_ask_reflection_topic',
                        'utter_ask_mental_note',
                        'utter_ask_rapport_extended',
                        'utter_ask_habit_insight',
                        'utter_ask_improvement',
                        'utter_ask_achievement',
                        'utter_ask_expectation',
                        'utter_ask_challenges',
                        'utter_ask_satisfaction_reason',
                        'utter_ask_highlight',
                        'utter_ask_preparation',
                        'utter_ask_mindset_more',
                        'utter_ask_mood_more',
                        'utter_happy',
                        'utter_inform_program_session_end',
                        'utter_ask_more_question_check_quiz',
                        'utter_ask_more_question_check_goal',
                        'utter_ask_more_question_check_cta'

                    ];
                    utterancesLabels.forEach(labels =>{ 
                        utterancesTab[labels] = []
                    })
                    let keySorting = 'utterance_class';
                    var name = '---undefined---'
                    snapshot.forEach(doc =>{
                        if(this.context.roles?.includes('ADMIN')){
                            if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                                name = doc.data()[keySorting];
                                if(name === undefined){
                                    name = '---undefined---'
                                }
                                if(!(name in utterancesTab)){
                                    //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                                    utterancesTab[name] = []
                                    utterancesLabels.push(name );
                                }
                                if(doc.data().status === "active"){
                                    utterancesTab[name].unshift({ ...doc.data(), uid: doc.data().mongodb_id });
                                }
                                else{
                                    utterancesTab[name].push({ ...doc.data(), uid: doc.data().mongodb_id });
                                }
                            } // end if true
                        } // end of if ADMIN
                    })

                    this.setState({
                        utterancesTab,
                        utterancesLabels,
                        loading: false,
                    });
                    //console.log(this.state)
                }//end if mounted
            }) //end then snapshpot
        }
    }
    loadUtterancesByUtterance = (utteranceClass) => {
        if(utteranceClass?.length > 1){
            //console.log('search for ', utteranceClass)
            this.unsubscribe2 = this.props.firebase
            .agentUtters(utteranceClass)
            .onSnapshot(snapshot => {
                if(this._isMounted){
                    let utterancesLabelsExtra = this.state.utterancesLabelsExtra
                    if (utterancesLabelsExtra === undefined){
                        utterancesLabelsExtra = []
                    }
                    var utterancesTabExtra = this.state.utterancesTabExtra
                    if (utterancesTabExtra === undefined){
                        utterancesTabExtra = {}
                    }
                    if (utteranceClass in utterancesTabExtra ) {
                        utterancesTabExtra[utteranceClass] = []
                    }
                    snapshot.forEach(doc =>{
                        if(this.context.roles?.includes('ADMIN')){
                            if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                                var name = doc.data().utterance_class;
                                if(!(name in utterancesTabExtra)){
                                    //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                                    utterancesTabExtra[name] = []
                                    utterancesLabelsExtra.push(name );
                                }
                                if(doc.data().status === "active"){
                                    utterancesTabExtra[name].unshift({ ...doc.data(), uid: doc.data().mongodb_id });
                                }
                                else{
                                    utterancesTabExtra[name].push({ ...doc.data(), uid: doc.data().mongodb_id });
                                }
                            } // end if true
                        } // end of if ADMIN
                    })
                    
                    this.setState({
                        utterancesTabExtra,
                        utterancesLabelsExtra,
                    });
                    //console.log(this.state)
                }//end if mounted
            }) //end then snapshpot
        }
    }
    loadUtteranceByText = (searchTextArray) => {
        this.setState({
            loading: true,
        });
        console.log('loadUtteranceByText', searchTextArray );
        var textSearch = searchTextArray;
        var fbSearch = undefined;
        if(Array.isArray(searchTextArray)){
            //textSearch = searchTextArray.map(str => str.trim());
            //textSearch = searchTextArray.map(str => str);
            textSearch = [];
            searchTextArray.forEach(str => {
                if(textSearch.length < 10){
                    str = str.trim();
                    if(!(str in textSearch)){
                        textSearch.push(str);
                    }
                }
                else{
                    textSearch.unshift(str.trim());
                }
            })
            searchTextArray = [].concat(textSearch);
            //console.log('is Array', textSearch)
            //textSearch = searchTextArray[0].trim();
            if(textSearch?.length>10){
                textSearch = textSearch.slice(0,9)
            }
            fbSearch = this.props.firebase.agentUttersByTextList(textSearch)
        }
        else{
            textSearch = searchTextArray.trim();
            if(this.props.showVague){
                //var textSearchList = [textSearch, textSearch.slice(0, textSearch.length*0.6), textSearch.slice(0, textSearch.length*0.4)];
                var shortSearch = textSearch.slice(0, textSearch.length*0.4);
                //console.log("vague", shortSearch)
                fbSearch = this.props.firebase.agentUttersByTextSearch(shortSearch)
            }
            else{
                //console.log("full search", textSearch)
                fbSearch = this.props.firebase.agentUttersByTextSearch(textSearch)
            }
        }
        //console.log('Searching items for:', textSearch );
        //this.unsubscribe = 
        fbSearch
        //.orderBy('active', 'asc')
        //.get().then(snapshot => {
        .onSnapshot(snapshot => {
            if(this._isMounted ){
                let utterancesTab = {};
                let utterancesLabels = [];
                utterancesLabels.forEach(labels =>{ 
                    utterancesTab[labels] = []
                })
                let keySorting = 'utterance_class';
                var name = '---undefined---'
                var resultOverlapMatch = false;
                var resultOverlapID = "";
                var resultOverlapUtter = "";
                snapshot.forEach(doc =>{
                    var documentDict = doc.data();
                    //console.log('search', searchTextArray );
                    if(searchTextArray?.includes(documentDict?.template?.text?.trim())){
                        resultOverlapMatch = true;
                        resultOverlapID = documentDict?.mongodb_id;
                        resultOverlapUtter = documentDict?.utterance_class;
                    }
                    //console.log('newd doc', documentDict?.template?.text)
                    if(this.context.roles?.includes('TEAM') || this.context.roles?.includes('ADMIN') || ROLES.AccessCheckCoachCreator(this.context)){
                        //console.log('role doc', documentDict?.template?.text)
                        if(Array.isArray(textSearch) || documentDict?.template?.text?.includes(textSearch) || this.props.showVague || this.props.showMore){
                            //console.log('text doc', documentDict?.template?.text)
                        //if(true){
                        //if(this.context.programs.includes(documentDict.program) || documentDict.program===undefined){
                            var filterFlag = false;
                            if(this.props.referenceUtters?.length > 0 && !this.props.referenceUtters.includes(documentDict.utterance_class)){
                                filterFlag = true;
                            }
                            if(!filterFlag){
                                name = documentDict[keySorting];
                                if(name === undefined){
                                    name = '---undefined---'
                                }
                                if(!(name in utterancesTab)){
                                    //contentsTab[documentDict.category]={name:documentDict.category, contents: []};
                                    utterancesTab[name] = []
                                    utterancesLabels.push(name );
                                }
                                if(documentDict.status === "active"){
                                    utterancesTab[name].unshift({ ...documentDict, uid: documentDict.mongodb_id });
                                }
                                else{
                                    utterancesTab[name].push({ ...documentDict, uid: documentDict.mongodb_id });
                                }
                            }
                            //console.log(utterancesTab)
                        } // end if true
                    } // end of if ADMIN
                })

                this.setState({
                    utterancesTab,
                    utterancesLabels,
                    loading: false,
                    allClasses : [],
                    utterDef : {},
                    searchText : '',
                    resultOverlapID,
                    resultOverlapUtter
                });
                if(this.props.resultUtters !== undefined && utterancesLabels?.length > 0){
                    this.props.resultUtters(utterancesLabels);
                }
                if(this.props.resultOverlapMatch !== undefined){
                    this.props.resultOverlapMatch(resultOverlapMatch, resultOverlapID, resultOverlapUtter);
                }
                //console.log(this.state)
            }//end if mounted
        }) //end then snapshpot
    }

    render() {
        //console.log("mongoutterances", this.props)
        if(this.state.loading){
            return(
                <LoadingProgress 
                    defaultSet={this.props.defaultSet}
                    type={'LoadingPageAnimated'} 
                    loadingText={"many, many, data"} 
                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                    allowRefresh={true}
                />
            )
        }
        else if(this.state.utterancesLabels !== undefined) {
            var allLabels = this.state.utterancesLabels;
            var utterDef = this.state.utterDef;
            var allExtraLabels = this.state.utterancesLabelsExtra;
            var allTabs = this.state.utterancesTab;
            return(
                <div>
                    {this.props.searchOption !== false
                    &&
                        <div>
                            <TextField
                                name="Search"
                                type="text"
                                label="Search"
                                placeholder="Enter text"
                                value={this.state.searchText !== undefined ?  this.state.searchText : ''}
                                onChange={(e) => {this.setState({searchText:e.target.value})}}
                                fullWidth
                                style={ {fontSize: '16px'} }
                            />
                            <Button onClick={this.searchTextHandler }>Search</Button>
                        </div>
                    }
                    {//allLabels.join()
                    }
                    {this.props.searchByText !== true &&  
                        <div style={{backgroundColor:'grey', padding: 10}}>
                            <span  style={{color:'red'}}>
                                Filter section: {this.props.leadershipCategory} - {this.props.leadershipQuality}
                            </span>
                            &nbsp;
                            <Button onClick={() => {this.addDefaultQuestionSet('quality', this.props.leadershipCategory , this.props.leadershipQuality)} }>
                                Add Initial Module Set
                            </Button>
                            &nbsp;
                            <Button onClick={() => {this.addDefaultQuestionSet('category', this.props.leadershipCategory , this.props.leadershipQuality)} }>
                                Add Initial Program Set
                            </Button>
                            <div>
                                <IconButton style={styles.addButton} onClick={() => {this.setState({openAdding:true})}}>
                                    <AddIcon />
                                </IconButton>
                                {
                                    this.replicateSettingsCheckDialogue()
                                }
                                <Dialog
                                    open={this.state.openAdding!== undefined ? this.state.openAdding: false}
                                    onClose={() => {this.setState({openAdding:false})}}
                                    fullWidth
                                    maxWidth="sm">
                                    <DialogContent>
                                        <DialogTitle>Add a new item</DialogTitle>
                                        <form>
                                            <TextField
                                                name="text"
                                                type="text"
                                                label="Message"
                                                placeholder="Enter text"
                                                value={this.state.newText !== undefined ?  this.state.newText : ''}
                                                onChange={(e) => {this.setState({newText:e.target.value})}}
                                                fullWidth
                                                style={ {fontSize: '16px'} }
                                            />
                                            <TextField
                                                name="value"
                                                type="text"
                                                label="Author of Question"
                                                placeholder="Enter value (small letters, no space)"
                                                value={this.state.newAuthor !== undefined ? this.state.newAuthor : ''}
                                                onChange={(e) => {this.setState({newAuthor:e.target.value})}}
                                                fullWidth
                                                style={ {fontSize: '16px'} }
                                            />
                                            <p>3. Select utterance</p>
                                            <Select
                                                value={this.state.newClass !== undefined ? this.state.newClass : '' }
                                                onChange={(e) => {this.setState({newClass:e.target.value})}}
                                                name='class_option'
                                                >
                                                {allLabels.concat(allExtraLabels).map((entry_option, index) => {
                                                    return (
                                                        <MenuItem key={index+"_add_"+entry_option} value={entry_option} >
                                                            {entry_option}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <br/>
                                            <TextField
                                                name="value"
                                                type="text"
                                                label="Utterance Class"
                                                placeholder="utter_"
                                                value={this.state.newClass !== undefined ? this.state.newClass : 'utter_ask_quiz' }
                                                onChange={(e) => {this.setState({newClass:e.target.value})}}
                                                fullWidth
                                                style={ {fontSize: '16px'} }
                                            />
                                        </form>
                                        {this.state.errorMessage !== '' && 
                                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                                        }
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => {this.setState({openAdding:false})}}>
                                            Cancel
                                        </Button>
                                        <Button onClick={this.handleAddingSubmit}>
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div>
                                {this.state.errorMessage !== '' && 
                                    <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                                }
                            </div>
                            <div>
                                <p>Search for Utterance</p>
                                {this.state.allClasses !== undefined && this.state.allClasses.length>1  &&
                                    <form>
                                        <Select
                                            value={''}
                                            onChange={(e) => {this.loadUtterancesByUtterance(e.target.value)}}
                                            name='searchclass'
                                            >
                                            {this.state.allClasses.map((entry_option_search, index) => {
                                                return (
                                                    <MenuItem key={index+"_addextra_"+entry_option_search} value={entry_option_search} >
                                                        {entry_option_search}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </form>
                                }
                            </div>
                            <div>
                                <Button onClick={(e) => {this.setState({filterAgent : undefined, filterCategory: undefined, filterQuality:undefined,  filterSession : undefined, filterStatus : undefined, filterDay : undefined })}}
                                    >Clear Filters</Button>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Agent</FormLabel>
                                    <RadioGroup aria-label="Agent" name="Agent" onChange={(e) => {this.setState({filterAgent: e.target.value})}}>
                                        <FormControlLabel value="priming" control={<Radio color='primary'/>} label="priming" />
                                        <FormControlLabel value="reflection" control={<Radio color='secondary' />} label="reflection" />
                                        <FormControlLabel value="routine" control={<Radio color='secondary' />} label="routine" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Program</FormLabel>
                                    <RadioGroup aria-label="Agent" name="Agent" onChange={(e) => {this.setState({filterCategory: e.target.value})}}>
                                        <FormControlLabel value="default" control={<Radio color='primary'/>} label="default" />
                                        <FormControlLabel value={this.props.leadershipCategory} control={<Radio color='secondary' />} label={this.props.leadershipCategory} />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Quality</FormLabel>
                                    <RadioGroup aria-label="Agent" name="Agent" onChange={(e) => {this.setState({filterQuality: e.target.value})}}>
                                        <FormControlLabel value="default" control={<Radio color='primary'/>} label="default" />
                                        <FormControlLabel value={this.props.leadershipQuality} control={<Radio color='secondary' />} label={this.props.leadershipQuality} />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Session</FormLabel>
                                    <RadioGroup aria-label="Session" name="Session" onChange={(e) => {this.setState({filterSession: e.target.value})}}>
                                        <FormControlLabel value="default" control={<Radio color='primary'/>} label="default" />
                                        <FormControlLabel value="first" control={<Radio color='secondary' />} label="first" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Status</FormLabel>
                                    <RadioGroup aria-label="Status" name="Status" onChange={(e) => {this.setState({filterStatus: e.target.value})}}>
                                        <FormControlLabel value="active" control={<Radio color='primary'/>} label="active" />
                                        <FormControlLabel value="inactive" control={<Radio color='secondary' />} label="inactive" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Day</FormLabel>
                                    <RadioGroup aria-label="Day" name="Day" onChange={(e) => {this.setState({filterDay: e.target.value})}}>
                                        <FormControlLabel value="default" control={<Radio color='primary'/>} label="default" />
                                        <FormControlLabel value="weekday" control={<Radio color='secondary'/>} label="weekday" />
                                        <FormControlLabel value="weekend" control={<Radio color='secondary' />} label="weekend" />
                                        <FormControlLabel value="friday" control={<Radio color='secondary' />} label="friday" />
                                        <FormControlLabel value="monday" control={<Radio color='secondary' />} label="monday" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    }
                    {this.state.utterancesLabelsExtra !== undefined &&
                        this.state.utterancesLabelsExtra.map((entryextra, indexextra) => (
                            <div key={"utterextra_"+indexextra} value={entryextra}>
                                <span color={'green'}>
                                    {entryextra + ' : ' + this.state.utterancesTabExtra[entryextra].length +  ' items'}
                                </span>
                                {this.props.textOnly
                                    ?
                                        <div>
                                            {this.renderTable(this.state.utterancesTabExtra[entryextra], entryextra, indexextra)}
                                        </div>
                                    :
                                        <VizSensor 
                                            partialVisibility={true}
                                            onChange={(isVisible) => { 
                                                if(isVisible && this.state['isVisible_'+entryextra]!== true && this._isMounted) { 
                                                    this.setState({['isVisible_'+entryextra] : true});
                                                }
                                                else if(!isVisible && this.state['isVisible_'+entryextra]!==false && this._isMounted) { 
                                                    this.setState({['isVisible_'+entryextra] : false});
                                                }
                                            }}
                                            >
                                            {this.state['isVisible_'+entryextra] === true &&
                                                <SwipeableViews
                                                    key={"utterextra_"+indexextra}
                                                    axis={'x'}
                                                    enableMouseEvents = {this.state.open === true? false : true}
                                                    disabled = {this.state.open === true? true : false}
                                                    style={{
                                                        paddingLeft: 0,
                                                        maxWidth: 400,
                                                        overflow: 'visible',
                                                    }}
                                                    slideStyle={{
                                                        paddingRight: 10,
                                                        paddingBottom: 10,
                                                        flex: 'none',
                                                    }}
                                                >

                                                {
                                                    this.state.utterancesTabExtra[entryextra].length>0
                                                    ?
                                                        this.renderCards(this.state.utterancesTabExtra[entryextra], entryextra, indexextra)
                                                    
                                                    :
                                                        <div>No utterances</div>
                                                }
                                                </SwipeableViews>
                                            }
                                        </VizSensor>
                                }
                            </div>
                        ))
                    }
                    {(this.state.searchText === undefined || this.state.searchText === '' ) &&
                        allLabels.map((entry, index) => (
                            allTabs[entry] !== undefined 
                                ?

                                    <VizSensor 
                                        key={"utter_X"+entry+'_'+index}
                                        partialVisibility={true}
                                        onChange={(isVisible) => { 
                                            if(isVisible && this.state['isVisible_X'+entry]!== true && this._isMounted) { 
                                                this.setState({['isVisible_X'+entry] : true});
                                            }
                                            else if(!isVisible && this.state['isVisible_X'+entry]!==false && this._isMounted) { 
                                                this.setState({['isVisible_X'+entry] : false});
                                            }
                                        }}
                                        >
                                        <div key={"utter_"+index} value={entry}>
                                            {allTabs[entry].length > 0 && 
                                                <Tooltip title={entry + " " + UtterancesDescriptions?.[entry]?.description}>
                                                    <AccountTreeIcon fontSize="small" onClick={() => { this.setState({ openReplicate:true, replicateRow:entry })}}/>
                                                </Tooltip>
                                            }
                                            &nbsp;
                                            {UtterancesDescriptions?.[entry]?.type + " - " + (UtterancesDescriptions?.[entry]?.name  || entry ) + ' : ' + allTabs[entry].length +  ' sample(s)'}
                                            {this.props.textOnly !== true &&
                                                <>
                                                    {allTabs[entry].length > 4 && this.state['activeStep_'+entry]!== undefined && this.state['activeStep_'+entry]>=4 
                                                        ?
                                                        <FastRewindIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+entry]:1 })}} />
                                                        : " X "
                                                    }
                                                    {this.state['activeStep_'+entry]!== undefined && this.state['activeStep_'+entry]>=1
                                                        ?
                                                            <ArrowBackIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+entry]: (this.state['activeStep_'+entry]-1)})}} />
                                                        : " X "
                                                    }
                                                    {this.state['activeStep_'+entry]!== undefined 
                                                        ?   (allTabs[entry].length-3) > this.state['activeStep_'+entry]  &&
                                                                <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+entry]: (this.state['activeStep_'+entry]+1)})}}  />
                                                        :   allTabs[entry].length > 1  &&
                                                                <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+entry]: 1})}}  /> 
                                                    }
                                                    {allTabs[entry].length > 4 
                                                        ?  <FastForwardIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+entry]: allTabs[entry].length-3 })}} />
                                                        : " X "
                                                    }
                                                </>
                                            }
                                            {utterDef[entry]!==undefined &&  utterDef[entry].definition && ' "'+utterDef[entry].definition+'"'}
                                            {utterDef[entry]!==undefined && utterDef[entry].do!==undefined 
                                                &&
                                                    <span style={{color:'green'}}>
                                                        &nbsp;Must: {utterDef[entry].do.toString()}
                                                    </span>
                                            }
                                            {utterDef[entry]!==undefined && utterDef[entry].donot!==undefined 
                                                &&
                                                    <span style={{color:'red'}}>
                                                        &nbsp;Cannot: {utterDef[entry].donot.toString()}
                                                    </span>
                                            }
                                            {this.props.textOnly
                                                ?
                                                    <div>
                                                        {this.renderTable(allTabs[entry], entry, index)}
                                                    </div>
                                                :
                                                    <SwipeableViews
                                                        key={"utter_"+index}
                                                        axis={'x'}
                                                        onChangeIndex={(index) => {
                                                            try{
                                                                this.handleStepChange(index, entry)
                                                            }
                                                            catch (err) {console.log('Swipe Error')}
                                                        }}
                                                        index={this.state['activeStep_'+entry]}
                                                        //index={this.state.activeStep}
                                                        enableMouseEvents = {this.state.open === true? false : true}
                                                        disabled = {this.state.open === true? true : false}
                                                        style={{
                                                            paddingLeft: 0,
                                                            maxWidth: 220,
                                                            overflow: 'visible',
                                                        }}
                                                        slideStyle={{
                                                            paddingRight: 10 ,
                                                            paddingBottom: 10,
                                                            flex: 'none',
                                                        }}
                                                    >

                                                    {
                                                        allTabs[entry].length>0
                                                        ?
                                                            this.renderCards(allTabs[entry], entry, index)
                                                        :
                                                            <div>No utterances</div>
                                                    }
                                                    </SwipeableViews>
                                            }
                                        </div>  
                                    </VizSensor>
                                :
                                    <div key='loading'>Loading</div>
                        ))
                    }
                    <QuestionUtterancesItemDialog 
                        contentId={this.state.contentId}
                        utterId={this.state.utterId}
                        leadershipCategory={this.props.leadershipCategory}
                        leadershipQuality={this.props.leadershipQuality}
                        availableQualities={this.props.availableQualities}
                        openDialog={this.state.open || false}
                        closeDialog={(e) => {
                            if(this.props.closeOverlapMatch !== undefined){
                                this.props.closeOverlapMatch();
                            }
                            this.setState({open: false });
                        }}
                        loadQualities={false}
                        leadershipQualities={this.props.leadershipQualities}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipCategoriesValues={this.props.leadershipCategoriesValues || []}
                        leadershipCategoriesValuesText={this.props.leadershipCategoriesValuesText || []}
                        leadershipQualitiesValues={this.props.leadershipQualitiesValues || []}
                        leadershipQualitiesValuesText={this.props.leadershipQualitiesValuesText || []}
                        leadershipQualitiesGroups= { this.props.leadershipQualitiesGroups}
                        leadershipQualitiesGroupsText= {this.props.leadershipQualitiesGroupsText}
                        intentIds = {this.props.intentIds}
                        intentIdsText= {this.props.intentIdsText}
                        intentIdsDict= {this.props.intentIdsDict}
                        intentIdsDictText= {this.props.intentIdsDictText}
                        //allClasses={allLabels.concat(allExtraLabels)}
                        allClasses={allLabels.concat(['utter_ask_mindset','utter_ask_assessment','utter_ask_awareness','utter_ask_activity','utter_ask_quiz', 'utter_ask_rapport_extended', 'utter_ask_preparation', 'utter_ask_improvement', 'utter_ask_highlight', 'utter_ask_achievement', 'utter_ask_expectation', 'utter_ask_challenges', 'utter_ask_habit_insight', 'utter_ask_goal_habit'])}
                        handleDuplicateItem={ (utterClass, utterObject, oldInactive) => {HandleDuplicateItem(this, utterClass, utterObject, oldInactive) }}
                    />
                </div>
            )
        }
        else {return 'No Data'}
    }

    handleStepChange = (step, keyWord) => {
        //console.log(step, keyWord, 'handler')
        if(step !== undefined && keyWord !== undefined) {
            this.setState({['activeStep_'+keyWord] : step});
        }
    };

    renderCards = (table, entry, index) => {
        let views = [];
        if (this.state['isVisible_X'+entry] === true || index < 3 ){
            table.map((item, indextab) => (             
                (this.state.filterAgent === undefined || (item.agent !== undefined && item.agent.includes( this.state.filterAgent)))
                &&
                (this.state.filterSession === undefined || (item.session !== undefined && item.session?.includes( this.state.filterSession)))
                &&
                
                (this.state.filterQuality === undefined || (item.leadership !== undefined && item.leadership.includes( this.state.filterQuality)))
                &&
                (this.state.filterDay === undefined || (item.day !== undefined && item.day.includes( this.state.filterDay)))
                &&
                (this.state.filterStatus === undefined || (item.status === this.state.filterStatus ))
                &&
                (this.state.filterCategory === undefined || (item.category !== undefined && item.category.includes( this.state.filterCategory)))
                
                &&
                views.push(this.renderContentCard(item, item.uid, entry))
            ))
        }
        return(views)
    }

    renderTable = (table, entry, index) => {
        let views = [];
        return (
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Active</TableCell>
                        <TableCell>Rating</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell>Mood</TableCell>
                        <TableCell>Profile</TableCell>
                        <TableCell>Day</TableCell>
                        <TableCell>Edit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table.map((item, indextab) => (             
                        (this.state.filterAgent === undefined || (item.agent !== undefined && item.agent.includes( this.state.filterAgent)))
                        &&
                        (this.state.filterSession === undefined || (item.session !== undefined && item.session?.includes( this.state.filterSession)))
                        &&
                        
                        (this.state.filterQuality === undefined || (item.leadership !== undefined && item.leadership.includes( this.state.filterQuality)))
                        &&
                        (this.state.filterDay === undefined || (item.day !== undefined && item.day.includes( this.state.filterDay)))
                        &&
                        (this.state.filterStatus === undefined || (item.status === this.state.filterStatus ))
                        &&
                        (this.state.filterCategory === undefined || (item.category !== undefined && item.category.includes( this.state.filterCategory)))
                        &&
                            <TableRow key={'tab_'+indextab}
                                onClick={() => {this.setState({open: true, contentId: item.uid, utterId: entry })}}
                                style={{backgroundColor: this.props.searchTextArray?.trim() === item?.template?.text?.trim() ? "orange" : "transparent"}}
                            >
                                <TableCell>{item?.status}</TableCell>
                                <TableCell>
                                    {item.voteCount!== undefined && item.voteCount> 0
                                        &&
                                        <strong>
                                            {item.voteCount > 10 
                                                ?
                                                    Math.trunc((item.vote || 1)/item.voteCount*100) + '% ('+item.voteCount+')'
                                                :
                                                    item.vote+'/'+item.voteCount
                                            }
                                            &nbsp;
                                        </strong>
                                    }
                                </TableCell>
                                <TableCell>
                                    <span style={{color: item?.status === 'inactive' ? "grey" : "black"}}>{item?.template?.text}</span>
                                </TableCell>
                                <TableCell>
                                    {item.sentiment === 'positive'
                                        ?   <EmojiEmotionsIcon fontSize="small" style={{color:"green"}}/>
                                        :   item.sentiment === 'negative'
                                            ?   <SentimentVeryDissatisfiedIcon fontSize="small" style={{color:"red"}}/>
                                            :
                                                <>
                                                    <EmojiEmotionsIcon fontSize="small" style={{color:"green"}}/> / <SentimentVeryDissatisfiedIcon fontSize="small" style={{color:"red"}}/>
                                                </>
                                    }
                                </TableCell>
                                <TableCell>{UserStageRequirements(this, item?.requirements || undefined)}</TableCell>
                                <TableCell>{UserSessionLevel(this, item?.session || undefined)}</TableCell>
                                <TableCell>
                                    <Tooltip title={'Edit/Change'}>
                                        <IconButton> 
                                            <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    renderContentCard = (item, itemId, utterKey) => {
        var secondColor = 'lightgrey';
        if(item.status === 'active' ){
            secondColor = "green";
            if(item.session !== undefined &&  item.session?.includes('first')  ){
                secondColor = "gold";
            }
            else if(item.intents !== undefined && item.intents.length > 0 && !item.intents.includes('default')  ){
                secondColor = "pink";
            }
            else if(item.category !== undefined &&  item.category.includes('default')  ){
                secondColor = "lightblue";
            }
            else if(item.category === undefined || (item.category !== undefined &&  item.category.length === 0)  ){
                secondColor = "lightblue";
            }
        }
        var background = 'linear-gradient(to bottom, '+secondColor+' 0%, #FFFFFF 30%)';
        
        return(
            <Card key={itemId}  style={{width:200, background: background }}   >
                <CardContent>
                    <p>
                        {item.voteCount!== undefined && item.voteCount> 0
                            &&
                            <strong>
                                {item.voteCount > 10 
                                    ?
                                        Math.trunc((item.vote || 1)/item.voteCount*100) + '% ('+item.voteCount+')'
                                    :
                                        item.vote+'/'+item.voteCount
                                }
                                &nbsp;
                            </strong>
                        }
                        {item.agent !== undefined && item.agent.includes("reflection")
                            && <NightsStayIcon fontSize="small" style={{color:"blue"}}/>
                        }
                        {item.agent !== undefined && item.agent.includes("priming")
                            && <WbSunnyIcon fontSize="small" style={{color:"yellow"}}/>
                        }
                        {((item.template !== undefined && item.template.attachment === undefined) || 
                          ((item.template !== undefined && item.template.attachment!== undefined && 
                          (item.template.attachment.explanation === undefined || item.template.attachment.explanation === ""))))
                            && <SmsFailedIcon fontSize="small" style={{color:"pink"}}/>
                        }
                        {item.sentiment === 'positive'
                            && <EmojiEmotionsIcon fontSize="small" style={{color:"green"}}/>
                        }
                        {item.sentiment === 'negative'
                            && <SentimentVeryDissatisfiedIcon fontSize="small" style={{color:"red"}}/>
                        }
                        {((item.leadership !== undefined && item.leadership.includes("default") )
                            ||
                            (item.category !== undefined && item.category.includes("default") ))
                            ? 
                                < StarsIcon fontSize="small" style={{color:"black"}} />
                            : ((item.leadership !== undefined && item.leadership.length > 1 )
                                    ||
                                    (item.category !== undefined && item.category.length > 1 ))
                                ? 
                                    < DoneAllIcon fontSize="small" style={{color:"orange"}} />
                                :
                                    ""
                        }
                        {item.session !== undefined && item.session?.includes("basic")
                            && 
                            <Tooltip title={'Simple question'}>
                                <ChildFriendlyIcon fontSize="small" style={{color:"black"}}/>
                            </Tooltip>
                        }
                        {item.session !== undefined && item.session?.includes("intermediate")
                            && 
                            <Tooltip title={'Intermediate question'}>
                                <MotorcycleIcon fontSize="small" style={{color:"black"}}/>
                            </Tooltip>
                        }
                        {item.session !== undefined && item.session?.includes("advanced")
                            && 
                            <Tooltip title={'Difficult question'}>
                                <AirportShuttleIcon fontSize="small" style={{color:"black"}}/>
                            </Tooltip>
                        }
                        &nbsp;
                        <span>{item.template !== undefined? item.template.text : 'ADD QUESTION'}</span>
                        &nbsp;
                        {item.template?.buttons?.length>0
                            &&
                            <Tooltip title={'Response options available'}>
                                <RadioButtonCheckedIcon fontSize="small" style={{ color: 'blue'}} /> 
                            </Tooltip>
                        }
                    </p>
                    <p>
                        {DayRequirements(item?.day || undefined)}
                        <br/>
                        {UserStageRequirements(this, item?.requirements || undefined)}
                    </p>
                    <hr/>
                    <Button 
                        onClick={() => {this.setState({open: true, contentId: itemId, utterId: utterKey })}}
                    >
                        Edit<AssignmentIcon fontSize="small"/>
                    </Button>
                    &nbsp;
                    <Button onClick={() => HandleDuplicateItem(this, utterKey, item)}>
                        + 1
                    </Button>
                </CardContent>
            </Card>
        )
    }

    handleAddingSubmit = () => {
        if(this.state.newText!== undefined && this.state.newText !== ''
            && this.state.newAuthor!== undefined && this.state.newAuthor !== ''
            && this.state.newClass!== undefined && this.state.newClass !== ''){
                //const timestamp = this.props.firebase.fieldValue.serverTimestamp();
                //var dateEmail = new Date();
                //var newId = this.state.newClass + '_' + dateEmail.getTime() + '_' + 'item';
                var newId = CreateObjectId();
                var defaultCategory = ['default'];
                if(this.props.leadershipCategory!== undefined && this.props.leadershipCategory!=='' ){
                    defaultCategory = [this.props.leadershipCategory];
                }
                const dict = {
                    status: 'inactive',
                    template: {text: this.state.newText, attachment: {explanation: '', utterance_class:this.state.newClass}},
                    author: [this.state.newAuthor],
                    utterance_class: this.state.newClass,
                    leadership: [this.props.leadershipQuality],
                    category: defaultCategory,
                    session: ['default'],
                    persona: ['default'],
                    mindset_type: ['default'],
                    mongodb_id: newId,
                    input_channels: ['rocky', 'firebase'],
                    day: ['default'],
                    day_time: ['default'],
                    sentiment: 'default'
                }
                //console.log('created dict', dict)
                this.props.firebase.agentUtter(this.state.newClass, newId).set(dict, {merge:true } );
                this.setState({errorMessage : 'Added item: '+this.state.newClass})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a class'})
        }
    }

    replicateSettingsCheckDialogue () {
        //console.log(this.props.leadershipCategory)
        //openReplicate:true, replicateRow:entry
        return (
            <Dialog
                open={this.state.openReplicate || false}
                onClose={ ()=> {this.setState({openReplicate:false})}}
                >
                <DialogTitle >
                    <IconButton onClick={ ()=> {this.setState({openReplicate:false})} }>
                        (X)
                    </IconButton>
                    Replicate Row
                </DialogTitle>
                <DialogContent >
                    <div>
                        <h5 style={styles.subtitle}>Replicate row: {this.state.replicateRow} &nbsp; into -&gt;</h5>
                        {this.state.errorMessage !== '' && 
                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                        }
                        {this.state.replicateRow!==undefined && this.state.replicateRow!=='' && (
                            <div>
                                <div>
                                    {this.state.replicaTargetCategory!==undefined && this.state.replicaTargetCategory!=='' &&
                                     this.state.replicaTargetQuality!==undefined && this.state.replicaTargetQuality!=='' &&
                                        <Button onClick={() => {
                                            this.replicateRowHandler(this.state.replicaTargetCategory, this.state.replicaTargetQuality, this.state.replicateRow)
                                         }}>
                                            Replicate:
                                        </Button>
                                        //leadershipQuality={this.state.selectedLeadershipQuality} leadershipCategory
                                    }
                                    
                                    {this.props.availableCategories !== undefined &&  this.props.availableQualities!==undefined &&
                                        <form>
                                            <Select
                                                value={this.state.replicaTargetCategory || ''}
                                                onChange={(e) => {this.setState({ replicaTargetCategory: e.target.value}) }}
                                                name='searchcategoryreplica'
                                                >
                                                {this.props.availableCategories.map((catId, index) => {
                                                    return (
                                                        <MenuItem key={catId} value={catId} >
                                                            {catId}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {this.state.replicaTargetCategory!==undefined && this.state.replicaTargetCategory!=='' &&
                                                <Select
                                                    value={this.state.replicaTargetQuality || ''}
                                                    onChange={(e) => {this.setState({ replicaTargetQuality: e.target.value}) }}
                                                    name='searchcategoryreplica'
                                                    >
                                                    { this.props.availableQualities[this.state.replicaTargetCategory].map((qualId, index) => {
                                                        //console.log(qualId)
                                                        return (
                                                            <MenuItem key={qualId.value+"_"+index} value={qualId.value} >
                                                                {qualId.text}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            }
                                        </form>
                                    }
                                </div>
                                
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog> 
        );
    }

    replicateRowHandler = (targetCategory, targetQuality, utterClass) => {
        //console.log("replica", this.state.utterancesTab, targetQuality, 'Utters', this.state.utterancesTab[utterClass]);
        if(this.state.utterancesTab!==undefined && this.state.utterancesTab[utterClass]!== undefined && this.props.leadershipQuality!==undefined){
            //console.log("replica", targetCategory, targetQuality, 'Utters', this.state.utterancesTab[utterClass]);
            var counter = 0;
            this.state.utterancesTab[utterClass].map( (replicateItem) => {
                if(replicateItem.leadership.includes(this.props.leadershipQuality)) {
                    counter +=1;
                    var text = "Replicate no. " + counter+ " of: " + this.state.utterancesTab[utterClass].length + " to " + targetCategory + " > " + targetQuality + ' from ' + utterClass;
                    //console.log(text)
                    var copyObject = {...replicateItem};
                    copyObject['leadership'] = [targetQuality];
                    copyObject['category'] = [targetCategory];
                    console.log('object to copy', copyObject);
                    this.setState({errorMessage: text});
                    HandleDuplicateItem(this, utterClass, copyObject, false);
                }
                return true;
            })
        }
        return true;
    }

    addDefaultQuestionSet = (type, targetCategory, targetQuality) => {
        //console.log("qualities, ", targetCategory, targetQuality)

        var leadership = [targetQuality]
        if(type==='category'){
            try {
                var relatedQualities = this.props.availableQualities.map(c => c.value);
                if(Array.isArray(relatedQualities) && relatedQualities.length>0){
                    leadership = relatedQualities;
                }
                //console.log('Found qualities', leadership)
            }
            catch (err) {
                console.log('issue with finding the qualities');
            }
        }

        var categoryText = targetCategory
        var qualityText = targetQuality
        try {
            qualityText = this.props.availableQualities.find(c => c.value === targetQuality).text;
            //console.log('Found quality', qualityText)
        }
        catch (err) {
            console.log('issue with finding the quality Text');
        }

        const raw_dict = {
            status: 'active',
            agent: ['priming', 'reflection'],
            author: [this.context.email],
            category: [targetCategory],
            leadership: leadership,
            session: ['default'],
            persona:  ['default'],
            mindset_type: ['default'],
            input_channels: ['rocky', 'firebase'],
            day: ['default'],
            day_time: ['default'],
            sentiment: 'default'
        }

        if(type === 'category'){
            //// General
            var qs = [
                {
                    q: ["How would you define success in terms of your "+categoryText+" journey?"],
                    u: "",
                    e: "",
                },
                {
                    q: ['How motivated are you to tackle '+categoryText+' today?'],
                    u: "",
                    e: "",
                },
                {
                    q: ["Welcome aboard to the "+categoryText+" coaching chat!",
                        "A big welcome to our self-improvement coaching chat",
                        "Welcome to our self-improvement coaching chat"
                    ],
                    u: "",
                    e: "",
                    f: false,
                },
                {
                    q: ["Welcome aboard to the "+categoryText+" coaching chat!",
                        "A big welcome to our self-improvement coaching chat",
                        "Welcome to our self-improvement coaching chat"
                    ],
                    u: "",
                    e: "",
                    f: true,
                },
            ]
            this.addQuestionWithSet(
                'How motivated are you to tackle '+categoryText+' today?',
                'Motivation is an active mental process, initiated by certain needs and wants.',
                'utter_ask_energy_level',
                raw_dict)
            this.addQuestionWithSet(
                'How satisfied are you with your recent activities in '+categoryText+'?',
                'It is good to measure the daily progress.',
                'utter_ask_satisfaction_level',
                raw_dict)
            this.addQuestionWithSet(
                'Please take a minute and think about the relevance of '+categoryText+' in your day and describe it.',
                'This is a warming up task.',
                'utter_ask_mindset',
                raw_dict)
            this.addQuestionWithSet(
                'Welcome to the daily "'+categoryText+'" chat?',
                'The main topic of our chat today is '+categoryText+'.',
                'utter_welcome_to_program',
                raw_dict)
            //// Group actions qui
            this.addQuestionWithSet(
                'What is your greatest strength in '+categoryText+'?',
                'Please provide a self-assessment on your personal strenghts in '+categoryText+'.',
                'utter_ask_quiz',
                raw_dict)
                var qs = [
                    "How would you define success in terms of your "+categoryText+" journey?"
                ]
            this.addQuestionWithSet(
                'What did you learn recently about '+categoryText+'?',
                'What is a recent conclusion, learning or experienge with '+categoryText+'.',
                'utter_ask_awareness',
                raw_dict)
            this.addQuestionWithSet(
                'When will you engage again in '+categoryText+'?',
                'What is your action plan for '+categoryText+'.',
                'utter_ask_feedback',
                raw_dict)
            this.addQuestionWithSet(
                'How prepared do you feel for "'+categoryText+'" now?',
                'Please describe your personal conclusions of this chat today.',
                'utter_ask_feedback',
                raw_dict)
        }
        else if(type === 'quality'){
            this.addQuestionWithSet(
                'Time to talk about: '+qualityText+'',
                qualityText + ' is your selected main topic.',
                'utter_inform_leadership_quality_phase',
                raw_dict)
            this.addQuestionWithSet(
                qualityText +' could inspire us with some action.',
                'Time to talk about activities in ' + qualityText + ':',
                'utter_inform_priority_phase',
                raw_dict)
            this.addQuestionWithSet(
                'Now I will ask a question about your overal understanding of '+qualityText+'.',
                qualityText + ' is your selected main topic.',
                'utter_inform_skill_assessment_phase ',
                raw_dict)
            ///
            this.addQuestionWithSet(
                'What did you learn recently about '+qualityText+'?',
                'Please describe any small or big experience that you had in the context of '+qualityText+'.',
                'utter_ask_awareness',
                raw_dict)
            this.addQuestionWithSet(
                'When are you next going to use '+qualityText+'?',
                'What is a task that you can define for: '+qualityText+'.',
                'utter_ask_activity',
                raw_dict)
            this.addQuestionWithSet(
                'What is a priority for you when thinking about '+qualityText+'?',
                'Out from all the many tasks. What requires attention for '+qualityText+'?',
                'utter_ask_priority',
                raw_dict)
            this.addQuestionWithSet(
                'How can you be better in '+qualityText+'?',
                'In which areas of '+qualityText+' do you have a plan to improve.',
                'utter_ask_quiz',
                raw_dict)
            this.addQuestionWithSet(
                'What is your greatest strength in '+qualityText+'?',
                'Please give a self-assessment on your personal strenghts in '+qualityText+'.',
                'utter_ask_quiz',
                raw_dict)
            this.addQuestionWithSet(
                'What is your greatest weakness in '+qualityText+'?',
                'Please give a self-assessment on where you want to improve in '+qualityText+'.',
                'utter_ask_quiz',
                raw_dict)
        }
        //console.log('duplicated: ', dict)
    }

    addQuestionWithSet(question, explanation, utterClass, raw_dict) {
        var newId = CreateObjectId();
        var dict = {...raw_dict};
        var template = {text:question, attachment: {explanation: explanation, utterance_class : utterClass} };
        dict['mongodb_id'] = newId;
        dict['template'] = template;
        dict['utterance_class'] = utterClass;
        console.log("new created question", dict)
        this.props.firebase.agentUtter(utterClass, newId).set(dict, {merge:true } );
        this.setState({errorMessage : 'Added item: ' + utterClass})
    }
}

export function UserStageRequirements(that, requirements){
    var defaultSituation = false;
    if(false && that?.updateLifeStageButtons !== undefined){
        //console.log('UserStageRequirements that', that)
        //console.log('UserStageRequirements ree', requirements)
    }
    if(requirements?.includes('default') || requirements === undefined){
        defaultSituation = true;
    }
    return(
        <div style={{display: "inline-flex"}}>
            {(defaultSituation || requirements?.includes("default"))
                ?
                    <Tooltip title={'Any life stage'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("default", true, requirements))
                            :   undefined
                        }
                    >
                        <DoneAllIcon fontSize="small" style={{ color: 'green'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Only Selected'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("default", true, requirements))
                            :   undefined
                        }
                    >
                        <DoneAllIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            &nbsp;&nbsp;
            {(defaultSituation || requirements?.includes("teenager"))
                ?
                    <Tooltip title={'Good for K-12'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("teenager", false, requirements))
                            :   undefined
                        }
                    >
                        <ChildCareIcon fontSize="small" style={{ color: 'blue'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Not for K-12'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("teenager", true, requirements))
                            :   undefined
                        }
                    >
                        <ChildCareIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            {(defaultSituation || requirements?.includes("student"))
                ?
                    <Tooltip title={'Good for Higher Education Students'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("student", false, requirements))
                            :   undefined
                        }
                    >
                        <SchoolIcon fontSize="small" style={{ color: 'blue'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Not for Higher Education Student'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("student", true, requirements))
                            :   undefined
                        }
                    >
                        <SchoolIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            {(defaultSituation || requirements?.includes("seeker"))
                ?
                    <Tooltip title={'Good for Job-Seeker/Available'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("seeker", false, requirements))
                            :   undefined
                        }
                    >
                        <WifiTetheringIcon fontSize="small" style={{ color: 'blue'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Not for Job-Seeker/Available'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("seeker", true, requirements))
                            :   undefined
                        }
                    >
                        <WifiTetheringIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            {(defaultSituation || requirements?.includes("professional"))
                ?
                    <Tooltip title={'Good for Professionals'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("professional", false, requirements))
                            :   undefined
                        }
                    >
                        <BusinessCenterIcon fontSize="small" style={{ color: 'blue'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Not for Professionals'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("professional", true, requirements))
                            :   undefined
                        }
                    >
                        <BusinessCenterIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            {(defaultSituation || requirements?.includes("executive"))
                ?
                    <Tooltip title={'Good for Executive/Leaders/Entrepreneur'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("executive", false, requirements))
                            :   undefined
                        }
                    >
                        <BusinessIcon fontSize="small" style={{ color: 'blue'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Not for Executive/Leaders/Entrepreneur'}
                        onClick={that?.updateLifeStageButtons !== undefined
                            ?   () => that.updateLifeStageButtons(UpdateUserStageRequirements("executive", true, requirements))
                            :   undefined
                        }
                    >
                        <BusinessIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
        </div>
    )
}
function UpdateUserStageRequirements (lifeStage, active=true, requirements=[]){
    if(requirements?.length === 0){
        requirements = ['executive', 'professional', 'seeker', 'student', 'teenager'];
    }
    else if(requirements?.length === 1 && requirements?.includes('default') && active === false){
        requirements = ['executive', 'professional', 'seeker', 'student', 'teenager'];
    }
    //console.log('lifestage', lifeStage, active, requirements)
    if(active){
        if(lifeStage === 'default'){
            requirements = ['default']
        }
        else if(lifeStage?.length > 0){
            const index = requirements.indexOf('default');
            if (index > -1) { // only splice array when item is found
                requirements.splice(index, 1); // 2nd parameter means remove one item only
            }
            if(!requirements?.includes(lifeStage)){
                requirements.unshift(lifeStage)
            }
        }
    }
    else{
        if(lifeStage?.length > 0){
            const index = requirements.indexOf(lifeStage);
            if (index > -1) { // only splice array when item is found
                requirements.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
    }
    return requirements
}


export function UserSessionLevel(that, session){
    var defaultSituation = false;
    if(that?.updateSessionButtons !== undefined){
        //console.log('updateChatLevelButtons that', that)
        //console.log('updateChatLevelButtons ree', session)
    }
    if(session?.includes('default') || session === undefined){
        defaultSituation = true;
    }
    return(
        <div style={{display: "inline-flex"}}>
            {(defaultSituation || session?.includes("default"))
                ?
                    <Tooltip title={'Any chat session'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("default", true, session))
                            :   undefined
                        }
                    >
                        <DoneAllIcon fontSize="small" style={{ color: 'green'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Only Selected'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("default", true, session))
                            :   undefined
                        }
                    >
                        <DoneAllIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            &nbsp;&nbsp;
            {(session?.includes("first"))
                ?
                    <Tooltip title={'First chat/onboarding'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("first", false, session))
                            :   undefined
                        }
                    >
                        <StarsIcon fontSize="small" style={{ color: 'gold'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Not priority for first chat'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("first", true, session))
                            :   undefined
                        }
                    >
                        <StarsIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            &nbsp;&nbsp;
            {(defaultSituation || session?.includes("basic"))
                ?
                    <Tooltip title={'Beginner Chats'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("basic", false, session))
                            :   undefined
                        }
                    >
                        <ChildFriendlyIcon fontSize="small" style={{ color: 'blue'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'No Beginner Chats'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("basic", true, session))
                            :   undefined
                        }
                    >
                        <ChildFriendlyIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            {(defaultSituation || session?.includes("intermediate"))
                ?
                    <Tooltip title={'After a few chats'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("intermediate", false, session))
                            :   undefined
                        }
                    >
                        <MotorcycleIcon fontSize="small" style={{ color: 'blue'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'No intermediate chatters'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("intermediate", true, session))
                            :   undefined
                        }
                    >
                        <MotorcycleIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
            {(defaultSituation || session?.includes("advanced"))
                ?
                    <Tooltip title={'For advanced chatters'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("advanced", false, session))
                            :   undefined
                        }
                    >
                        <AirportShuttleIcon fontSize="small" style={{ color: 'blue'}} /> 
                    </Tooltip>
                :
                    <Tooltip title={'Not for advanced chatters'}
                        onClick={that?.updateSessionButtons !== undefined
                            ?   () => that.updateSessionButtons(UpdateUserSessionLevel("advanced", true, session))
                            :   undefined
                        }
                    >
                        <AirportShuttleIcon fontSize="small" style={{ color: 'lightgrey'}} /> 
                    </Tooltip>
            }
        </div>
    )
}
function UpdateUserSessionLevel (level, active=true, session=[]){
    if(session?.length === 0 && active === false){
        session = ['basic', 'intermediate', 'advanced'];
    }
    else if(session?.length === 1 && session?.includes('default') && active === false){
        session = ['basic', 'intermediate', 'advanced'];
    }
    else if(session?.length === 2 && session?.includes('default') && active === false && session?.includes('first')){
        session = ['first', 'basic', 'intermediate', 'advanced'];
    }
    //console.log('level', level, active, session)
    if(active){
        if(level === 'default'){
            if(session?.includes('first')){
                session = ['default', 'first']
            }
            else{
                session = ['default']
            }
        }
        else if(level?.length > 0){
            if(level !== 'first'){
                const index = session.indexOf('default');
                if (index > -1) { // only splice array when item is found
                    session.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            if(!session?.includes(level)){
                session.unshift(level)
            }
        }
    }
    else{
        if(level?.length > 0){
            const index = session.indexOf(level);
            if (index > -1) { // only splice array when item is found
                session.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
    }
    return session
}

export function DayRequirements(days){
    var defaultSituation = false;
    if(days?.includes('default') || days === undefined){
        defaultSituation = true;
    }
    return(
        <>
            {(defaultSituation || days.includes("weekday") || days.includes("monday"))
                ?
                    <span style={styles.iCircleActive}>M</span>
                :
                    <span style={styles.iCircleInActive}>M</span>
            }
            {(defaultSituation || days.includes("weekday") || days.includes("tuesday") || days.includes("midweek"))
                ?
                    <span style={styles.iCircleActive}>T</span>
                :
                    <span style={styles.iCircleInActive}>T</span>
            }
            {(defaultSituation || days.includes("weekday") || days.includes("wednesday") || days.includes("midweek"))
                ?
                    <span style={styles.iCircleActive}>W</span>
                :
                    <span style={styles.iCircleInActive}>W</span>
            }
            {(defaultSituation || days.includes("weekday") || days.includes("thursday") || days.includes("midweek"))
                ?
                    <span style={styles.iCircleActive}>T</span>
                :
                    <span style={styles.iCircleInActive}>T</span>
            }
            {(defaultSituation || days.includes("weekday") || days.includes("friday"))
                ?
                    <span style={styles.iCircleActive}>F</span>
                :
                    <span style={styles.iCircleInActive}>F</span>
            }
            {(defaultSituation || days.includes("weekend") || days.includes("saturday"))
                ?
                    <span style={styles.iCircleActive}>S</span>
                :
                    <span style={styles.iCircleInActive}>S</span>
            }
            {(defaultSituation || days.includes("weekend") || days.includes("sunday"))
                ?
                    <span style={styles.iCircleActive}>S</span>
                :
                    <span style={styles.iCircleInActive}>S</span>
            }
        </>
    )
}

class QuestionUtterancesItemBaseDialog extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        var cid = this.props.contentId;
        var errorMessage = '';

        //console.log('location',cid)
        this.state = {
            loading: false,
            content: null,
            cid: cid,
            utterId: this.props.utterId,
            errorMessage: errorMessage,
        };
    }

    componentDidMount() {
        //console.log('Moun QuestionUtterancesItemBaseDialog',this.state, this.props)
        this._isMounted = true;
        if (this.state.cid) {
            this.setState({ loading: true, errorMessage: "" });
            this.loadUtteranceDoc(this.state.utterId, this.state.cid)
        }
        if((this.props.leadershipQualitiesValues?.length > 0 || this.props.loadQualities===false)){
            //console.log('leadershipQuaitiesValues given')
            this.setState({
                leadershipQualitiesValues : this.props.leadershipQualitiesValues,
                leadershipQualitiesValuesText: this.props.leadershipQualitiesValuesText,
                leadershipQualitiesGroups: this.props.leadershipQualitiesGroups,
                leadershipQualitiesGroupsText: this.props.leadershipQualitiesGroupsText,
                leadershipCategoriesValues: this.props.leadershipCategoriesValues,
                leadershipCategoriesValuesText: this.props.leadershipCategoriesValuesText,
                intentIds: this.props.intentIds,
                intentIdsText: this.props.intentIdsText,
                intentIdsDict: this.props.intentIdsDict,
                intentIdsDictText: this.props.intentIdsDictText,
            })
        }
        else{
            //console.log('leadershipQualitiesValues remake')
            GetAdminLeadershipCategoriesArray(this);
            GetAdminLeadershipQualitiesArray(this);
            //this.getleadershipCategoriesArray();
            //this.getleadershipQualitiesArray();
            this.getIntentsArray();
        }
    }
    componentDidUpdate(prevProps){
        if(this.props !== undefined && this.props.contentId !== '' && prevProps!==undefined && prevProps.contentId !==this.props.contentId ){
            this.loadUtteranceDoc(this.props.utterId, this.props.contentId)
            this.setState({ utterId: this.props.utterId, cid: this.props.contentId});
        }
        if((this.props.leadershipQualitiesValues?.length > 0 && this.props.leadershipQualitiesValues.length !== prevProps.leadershipQualitiesValues?.length )){
            this.setState({
                leadershipQualitiesValues : this.props.leadershipQualitiesValues,
                leadershipQualitiesValuesText: this.props.leadershipQualitiesValuesText,
                leadershipQualitiesGroups: this.props.leadershipQualitiesGroups,
                leadershipQualitiesGroupsText: this.props.leadershipQualitiesGroupsText,
                leadershipCategoriesValues: this.props.leadershipCategoriesValues,
                leadershipCategoriesValuesText: this.props.leadershipCategoriesValuesText,
                intentIds: this.props.intentIds,
                intentIdsText: this.props.intentIdsText,
                intentIdsDict: this.props.intentIdsDict,
                intentIdsDictText: this.props.intentIdsDictText,
            })
        }
    }

    loadUtteranceDoc = (utterance_class, utterance_id) => {
        this.unsubscribe = this.props.firebase
        .agentUtter(utterance_class, utterance_id)
        .onSnapshot(snapshot => {
            //console.log('data',snapshot.data())
            if(this._isMounted){
                this.setState({
                    content: snapshot.data(),
                    utterId: utterance_class,
                    cid: utterance_id
                });
            }
        });
    }

    getIntentsArray() {
        //var intentGroups = {};
        var intentIds = [];
        var intentIdsText = [];
        var intentIdsDict = {};
        var intentIdsDictText = {};
        this.props.firebase
        .agentIntentClasses()
        .where('active', '==', true)
        .orderBy('intent', 'asc')
        .orderBy('sentiment', 'asc')
        .get().then(snapshot => {
            if(this._isMounted){
                snapshot.forEach(doc => {
                    var groupId = "undefined";
                    var intentData = doc.data();
                    var flagAddIntent = true;
                    //console.log('this.context.adminIntents', this.context.adminIntents, intentData.intentTags)
                    if(intentData.intentTags !== undefined && this.context.adminIntents !== undefined && this.context.adminIntents.length>0){
                        flagAddIntent = false;

                        this.context.adminIntents.map(tag => {
                            if(intentData.intentTags[tag]){
                                flagAddIntent = true;
                            }
                            return true;
                        })
                    }
                    if(flagAddIntent){
                        if(intentData.sentiment.includes('_short') && !intentData.sentiment.includes('_past') && !intentData.sentiment.includes('_future') ){
                            groupId = intentData.sentiment
                            var response = "n/a";
                            if(intentData.responses!== undefined && intentData.responses.length > 0){
                                //console.log(intentData.responses)
                                response = intentData.responses[0]
                                if (response.length > 30){
                                    response = response.substring(0,30) + "..."
                                }
                            }
                            var text = intentData.intent + ' - ' + response
                            //var text = "(" + groupId.split('_')[1] + ") " + intentData.intent + ' - ' + intentData.tenses
                            intentIds.push(intentData.class);
                            intentIdsText.push(text);
                            if(!Object.keys(intentIdsDict).includes(groupId)){
                                intentIdsDict[groupId] = [];
                                intentIdsDictText[groupId] = [];
                            }
                            intentIdsDict[groupId].push(intentData.class);
                            intentIdsDictText[groupId].push(text);
                        }
                    }
                });
            }
            if (this._isMounted) {
                //console.log(intentIdsDictText)
                this.setState({
                    //intentGroups,
                    intentIds,
                    intentIdsText,
                    intentIdsDict,
                    intentIdsDictText
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe !== undefined )this.unsubscribe();
        this.setState({});
    }

    render() {
        const { content, loading } = this.state;
        const { theme } = this.props;
        //console.log('state dialog intent', this.state, this.props) 
        var access = false;
        if(this.props.openDialog && content !== undefined && content !== null){
            //console.log('dialog optpo', this.state, this.props) 
            var ownedCats = this.state.leadershipCategoriesValues;
            var ownedQuals = this.state.leadershipQualitiesValues;
            //console.log('access contro', ownedCats, ownedQuals, content, content?.category , content?.leadership) 
            if(this.context.roles !== undefined && ( this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") )){
                access = true;
            }
            else if(ownedCats!== undefined &&  ownedQuals!== undefined && content!==undefined && content.category !== undefined && content.leadership !== undefined){
                //&& ownedQuals.some(item => content.leadership.includes(item) )
                let notAvailabeCat =  [...content.category].filter(x => !ownedCats.includes(x));
                let notAvailabeQual =  [...content.leadership].filter(x => !ownedQuals.includes(x));
                //console.log('access group', ownedCats, content.category , notAvailabeCat)
                //console.log('access quality', ownedQuals, content.leadership , notAvailabeQual)
                if(notAvailabeCat.length === 0 && notAvailabeQual.length === 0){
                    //console.log('we have access')
                    access = true;
                }
            }
        }
        return (
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
                PaperProps={{style: theme.dialogWindow}}
                maxWidth="sm"
            >
                <DialogTitle style={theme.dialogTitle} >
                    <Grid container spacing={0} direction="row"
                        justify="space-between"
                        alignItems="center">
                        <Grid item xs={1}>
                            <IconButton onClick={this.props.closeDialog}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={10}>
                            <span style={theme.dialogTitle.titleText}>
                                Modify Question/Comment
                            </span>
                            <span style={theme.textSupportCenter}>
                                <br/>
                                {content?.template?.text}
                            </span>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {this.props.openDialog 
                    &&
                        <div>
                            {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                            &&
                                <h5 style={styles.subtitle}>{content!==undefined && content!==null ? content.utterance_class : ''}: ({this.state.cid})</h5>
                            }
                            {loading && 
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} 
                                    loadingText={"many, many, data"} 
                                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                    allowRefresh={true}
                                />
                            }
                            {this.state.errorMessage !== '' && 
                                <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                            }
                            {content && (
                                <div>
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                    &&
                                        <div>
                                            <Button onClick={() => { 
                                                    this.setState({errorMessage:"Copying to: " +  content.utterance_class + " > " + (content.category!==undefined? content.status:"check category to make active" ) })
                                                    this.props.handleDuplicateItem( content.utterance_class, content, false ) 
                                                    }}>
                                                Duplicate
                                            </Button>
                                            {this.props.allClasses !== undefined && this.props.allClasses.length>0  &&
                                                <Button onClick={ () =>  {
                                                    if(this.state.moveToUtterClass!== undefined && this.state.moveToUtterClass!== '' ){
                                                        this.setState({errorMessage:"Moving to: " + this.state.moveToUtterClass  + " > " + (content.category!==undefined? content.status:"check category to make active") })
                                                        this.props.handleDuplicateItem( this.state.moveToUtterClass, content, true ) 
                                                    }
                                                    else{
                                                        this.setState({errorMessage:"Please choose a target class"})
                                                    }
                                                }}>
                                                    Move to Class:
                                                </Button>
                                            }
                                            {this.props.allClasses !== undefined && this.props.allClasses.length>0  &&
                                                <form>
                                                    <Select
                                                        value={this.state.moveToUtterClass!== undefined && this.state.moveToUtterClass!== '' ? this.state.moveToUtterClass :''}
                                                        onChange={(e) => {this.setState({ moveToUtterClass: e.target.value}) }}
                                                        name='searchclass'
                                                        >
                                                        {this.props.allClasses.map((entry_option_search, index) => {
                                                            return (
                                                                <MenuItem key={index+"_addmove_"+entry_option_search} value={entry_option_search} >
                                                                    {entry_option_search}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </form>
                                            }
                                            {this.context?.roles?.includes('ADMIN') 
                                            &&
                                                <TextField
                                                    name="utter"
                                                    type="text"
                                                    label="utter_class"
                                                    value={this.state.moveToUtterClass || ''}
                                                    onChange={(e) => {this.setState({moveToUtterClass: e.target.value})}}
                                                    style={ {fontSize: '16px'} }
                                                />
                                            }
                                            <Button 
                                                onClick={() => { 
                                                    this.setState({
                                                        openDialogueUtterance: true
                                                    })
                                                }}>
                                                Find utterance
                                            </Button>
                                            <Button 
                                                onClick={() => { 
                                                    this.setState({
                                                        openDialogueIntents: true
                                                    })
                                                }}>
                                                Add intend
                                            </Button>
                                            {content.template!== undefined &&
                                                <DialogIntents
                                                    openDialogueUtterance = {this.state.openDialogueUtterance || false}
                                                    openDialogueIntents = {this.state.openDialogueIntents || false}
                                                    selectedText = {content.template.text || ""}
                                                    addIntent = {(intent, tense) => {this.addIntent(intent, tense)}}
                                                    selectedExplanation = {content.template.attachment!== undefined && content.template.attachment.explanation !== undefined ? content.template.attachment.explanation : ""}
                                                    closeDialogue = {() => {this.setState({openDialogueUtterance: false, openDialogueIntents:false})}}
                                                    leadershipCategories={this.state.leadershipCategories || []}
                                                    leadershipQualities={this.state.leadershipQualities || []}
                                                    cleanItem = {this.props.cleanItem || undefined}
                                                />
                                            }
                                        </div>
                                    }
                                    <SettingContentSelect 
                                        dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                        title={"Status"} 
                                        content={content} 
                                        params={['status']}
                                        showButtons={access}
                                        selectOptions={['inactive', 'active']}
                                        selectOptionsText={['inactive', 'active']}
                                        access={access}
                                    />
                                    {!(access) &&
                                        <p style={{color: "red", padding: 20}}>
                                            <span>You cannot modify or deactivate this question, as you do not exclusively own it with your programs and modules.</span>
                                            <br/><br/><span>However, you can add or remove your programs and modules, below.</span>
                                        </p>
                                    }
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                        && content.status === 'inactive' && (content.vote === 0 || content.vote === undefined)
                                        &&
                                        <div style={{width: '100%', textAlign: 'right'}}>
                                            <Button onClick={() => HandleDuplicateItem(this, undefined, content, true, true)}>
                                                Remove/Release item
                                            </Button>
                                        </div>
                                    }
                                    {content && content.voteCount!== undefined
                                        &&
                                        <div style={{width: '100%'}}>
                                            <Tooltip title={'Positive votes vs total occasions:'}>
                                                <strong>
                                                    Scores: {content.vote} / Occasions {content.voteCount}&nbsp;
                                                    {content.voteCount > 10 &&
                                                        Math.trunc((content.vote || 1)/content.voteCount*100) + '%'
                                                    }
                                                    &nbsp;
                                                </strong>
                                            </Tooltip>
                                            &nbsp;
                                            {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                                &&
                                                <Button onClick={() => {this.props.firebase.agentUtter(this.state.utterId, this.state.cid).update({vote: 0, voteCount: 0})}}>
                                                    Reset Counter
                                                </Button>
                                            }
                                        </div>
                                    }
                                    <SettingContentSpecialUtteranceText 
                                        dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                        title={"Text & Explanation"} 
                                        showAnswerFeature={true}
                                        content={content} 
                                        utterance_class={this.state.utterId}
                                        showButtonFeature={(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')) ? true : false}
                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                        access={access}
                                    />
                                    {content.template?.buttons?.length>0
                                        &&
                                        <div style={{width: '100%', textAlign: 'right'}}>
                                            <Tooltip title={'Response options available'}>
                                                <RadioButtonCheckedIcon fontSize="small" style={{ color: 'blue'}} /> 
                                            </Tooltip>
                                        </div>
                                    }
                                    {false &&
                                        <Button
                                            //disabled = {this.state.questionsSearchingAvailable || false}
                                            onClick={async () => {
                                                var result = await GetNewExplanationRockyAI(content.template.text)
                                                //console.log('extra check', result)
                                                this.setState({explanationAlternatives: result})
                                            }}>
                                            Generate Explanation
                                        </Button>
                                    }
                                    {this.state.explanationAlternatives?.length>1
                                        &&
                                        <>
                                            <span >Copy and Paste</span>
                                            <div key={'extrq_exapl'}>
                                                {this.state.explanationAlternatives}
                                                &nbsp; 
                                                <FileCopyIcon
                                                    onClick={ () => {
                                                        window.prompt("Copy to clipboard: Ctrl+C, Enter", this.state.explanationAlternatives)
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                    <br/>
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                    &&
                                        <Button
                                            disabled = {this.state.questionsSearchingAvailable || false}
                                            onClick={async () => {
                                                this.setState({questionsSearchingAvailable: true})
                                                var result = await GetNewSentencesRockyAI(content.template.text)
                                                this.setState({questionsAlternatives: result, questionsSearchingAvailable: false})
                                                console.log('extra check', result)
                                            }}>
                                            Generate Rewrites
                                        </Button>
                                    }
                                    {this.state.questionsAlternatives !== undefined && this.state.questionsAlternatives.length>1
                                        &&
                                        <>
                                        <span>Copy and Paste</span>
                                        {this.state.questionsAlternatives.map( (item, index) => (
                                            <div key={'extrq_'+index}>
                                                {item}
                                                &nbsp; 
                                                <FileCopyIcon
                                                    onClick={ () => {
                                                        window.prompt("Copy to clipboard: Ctrl+C, Enter", item)
                                                    }}
                                                />
                                            </div>
                                        ))}
                                        </>
                                    }
                                    {this.state.questionsAlternatives !== undefined && this.state.questionsAlternatives.length===1
                                        &&
                                        <>
                                            <span>Try to modify the text slightly and retry to generate new samples.</span>
                                        </>
                                    }
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                    &&
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                            title={"Follow-up to intent (Multiple ok)"} 
                                            content={content} 
                                            params={['intents']}
                                            noLabel={true}
                                            sorting={true}
                                            selectOptions={this.state.intentIds}
                                            selectOptionsText={this.state.intentIdsText}
                                            selectOptionsGroups={this.state.intentIdsDict}
                                            selectOptionsGroupsText={this.state.intentIdsDictText}
                                            groupFilter={
                                                this.context.adminIntents === undefined 
                                                    ?   ['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']
                                                    :   undefined
                                            }
                                            updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                            access={access}
                                        />
                                    }
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                        &&
                                        <SettingContentArraySelect
                                            dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                            title={"Agent(s)"} 
                                            content={content} 
                                            params={['agent']}
                                            selectOptions={['priming', 'reflection', 'routine']}
                                            selectOptionsText={['priming', 'reflection', 'routine']}
                                            />
                                    }
                                    <SettingContentSelect
                                        dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                        title={"User Sentiment Filter"} 
                                        content={content} 
                                        params={['sentiment']}
                                        selectAtLeastOne={true}
                                        noLabel={true}
                                        emptyStage={'Every sentiment'}
                                        infoText={["If user has a problem a negative sentiment related question should be asked..."]}
                                        showButtons={true}
                                        selectOptionsText={['negative - the mood is low', 'neutral - can be always asked', 'positive - the mood is good' ]}
                                        selectOptions={['negative', 'default', 'positive']}
                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                    />
                                    <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                                        <Grid item xs={6}>
                                            <SettingContentArrayCheckbox
                                                dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                                title={"Optional: Filter Day Time"} 
                                                content={content} 
                                                selectAtLeastOne={true}
                                                params={['day_time']}
                                                emptyStage={'Any time'}
                                                noLabel={true}
                                                selectOptionsText={['Any time', 'Morning', 'Noon', 'Afternoon', 'Evening', 'Night', 'Sleeptime']}
                                                selectOptions={['default', 'morning', 'noon', 'afternoon', 'evening', 'night', 'sleep']}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SettingContentArrayCheckbox
                                                dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                                title={"Optional: Filter Weekday"} 
                                                content={content} 
                                                selectAtLeastOne={true}
                                                params={['day']}
                                                emptyStage={'Every day'}
                                                noLabel={true}
                                                selectOptionsText={['Every day', 'During weekdays', 'Tue-Thu', 'Weekend only','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ]}
                                                selectOptions={['default', 'weekday', 'midweek', 'weekend', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday','saturday', 'sunday']}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                            />
                                            <div style={{textAlign: 'right'}}>
                                                {DayRequirements(content?.day || undefined)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                    &&
                                        <SettingContentArray
                                            dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                            title={"Leadership Add Text"} 
                                            content={content} 
                                            params={['leadership']}
                                        />
                                    }
                                    <SettingContentArrayCheckbox
                                        dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                        title={"Add multiple Quest"} 
                                        content={content} 
                                        params={['leadership']}
                                        noLabel={true}
                                        selectPreOpen={true}
                                        selectOptionsGroups={this.state.leadershipQualitiesGroups}
                                        selectOptionsGroupsText={this.state.leadershipQualitiesGroupsText}
                                        selectOptions={this.state.leadershipQualitiesValues}
                                        selectOptionsText={this.state.leadershipQualitiesValuesText}
                                    />
                                    <SettingContentArrayCheckbox
                                        dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                        title={"Add fallback Program"} 
                                        noLabel={true}
                                        content={content} 
                                        params={['category']}
                                        selectOptions={this.state.leadershipCategoriesValues}
                                        selectOptionsText={this.state.leadershipCategoriesValuesText}
                                    /> 
                                    <SettingContentArrayCheckbox
                                        dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                        title={"Optional: User Life-stage"} 
                                        content={content} 
                                        params={['requirements']}
                                        showLifeStageButtons={true}
                                        noLabel={true}
                                        emptyStage={"Every Life-Stage"}
                                        selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                        selectOptionsText={['Question for every life-stage', 'Executive/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'Teenager / K-12' ]}
                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                    />
                                    <SettingContentArraySelect
                                        dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                        title={"Session(s)"} 
                                        content={content} 
                                        params={['session']}
                                        emptyStage={"Every chat, from first to advanced"}
                                        selectOptions={['default', 'first', 'basic', 'intermediate', 'advanced']}
                                        selectOptionsText={['Any (from first to advanced chats)', 'First Chats', 'Beginner Chats', 'Intermediate Chats', 'Advanced Chats']}
                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                    />
                                    <div>
                                        {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                            &&
                                            <>
                                                &nbsp;
                                                <Button 
                                                    onClick={() => { 
                                                        this.addCategoryQualities()
                                                    }}>
                                                    Add cat & quality
                                                </Button>
                                                &nbsp;                 
                                                <Button 
                                                    onClick={() => { 
                                                        this.addCategoryQualities("exclusive")
                                                    }}>
                                                    Only this topic
                                                </Button>
                                                &nbsp;
                                                <Button onClick={ () => {UpdateAIQuestions(this, [this.state.cid], '', this.state.utterId, true)}} 
                                                    style={{float:"right", fontSize:'small', backgroundColor:'green'}}
                                                    //disabled={this.state['loading_AI_'+key+subKey] ? true : false}
                                                    >
                                                    Add Intent as Default<AccountTreeIcon/>
                                                </Button>
                                            </>
                                        }
                                        {false &&
                                            <Button 
                                                onClick={() => { 
                                                    this.addCategoryQualities("default")
                                                }}>
                                                Make default
                                            </Button>
                                        }
                                    </div>
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                    &&
                                    <SettingContentArraySelect
                                        dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                        title={"Question tense"} 
                                        content={content} 
                                        params={['tenses']}
                                        selectOptions={["default", "past", "future"]}
                                        selectOptionsText={["anytime", "past", "future"]}
                                        />
                                    }
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                    &&
                                        <SettingContentArrayCheckbox
                                            dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                            title={"persona"} 
                                            content={content} 
                                            params={['persona']}
                                            selectOptionsText={['neutral - can be used for everyone', 'direct - assertive and more pushy', 'caring - submissive and sweet', 'non-native english - more simple language']}
                                            selectOptions={['default', 'direct', 'caring', 'non-native']}
                                        />
                                    }
                                    {false &&
                                        <>
                                            <SettingContentArraySelect
                                                dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                                title={"mindset"} 
                                                content={content} 
                                                params={['mindset']}
                                                selectOptions={['default', 'grateful_3']}
                                                />
                                            <SettingContentArray
                                                dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                                title={"Habits"} 
                                                content={content} 
                                                params={['habit_class']}
                                            />
                                        </>
                                    }
                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                    &&
                                        <SettingContentArray
                                            dbUpdateLink={this.props.firebase.agentUtter(this.state.utterId, this.state.cid)}
                                            title={"Author(s)"} 
                                            content={content} 
                                            params={['author']}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                    }
                </DialogContent>
            </Dialog> 
        );
    }

    addIntent = (intent, tense) => {
        if (intent!== undefined && intent !== ""){
            var newValues = {
                intents :  this.props.firebase.fieldValue.arrayUnion(intent)
            }
            if(tense!==undefined && tense !==""){
                newValues["tenses"] = this.props.firebase.fieldValue.arrayUnion(tense)
            }
            this.props.firebase.agentUtter(this.state.utterId, this.state.cid).update(newValues)
        }
    }

    addCategoryQualities = (type="", quality) => {
        if( this.props.availableQualities!==undefined ){
            var newValues = {};
            if(type==="default"){
                newValues = {
                    leadership : ["default"],
                    category :["default"]
                }
                this.props.firebase.agentUtter(this.state.utterId, this.state.cid).update(newValues)
            }
            else if(type==="exclusive"){
                newValues = {
                    leadership : [this.props.leadershipQuality],
                    category : [this.props.leadershipCategory]
                }
                this.props.firebase.agentUtter(this.state.utterId, this.state.cid).update(newValues)
            }
            else{
                var programQualities = this.props.availableQualities.map(function (el) {return el.value});
                //console.log('mycat', this.state.utterId,  this.props, this.props.leadershipCategory, programQualities)
                newValues = {
                    leadership : this.props.firebase.fieldValue.arrayUnion(...programQualities),
                    category : this.props.firebase.fieldValue.arrayUnion(this.props.leadershipCategory)
                }
                this.props.firebase.agentUtter(this.state.utterId, this.state.cid).update(newValues)
            }
        }
        else{
            console.log('mycat', this.state.utterId,  this.props, this.props.leadershipCategory, programQualities)
        }
    }
}

export async function HandleDuplicateItem(that, utterClass, originalUtter, deactivateOriginal=false, resetOnly=false){
    if(!resetOnly && originalUtter!== undefined && utterClass!== undefined && utterClass !== ''){
        var newId = CreateObjectId();
        var templateReuse;
        //console.log('created id ', newId)
        await that.props.firebase.agentUtters(utterClass).where('template.text', '==', 'Empty Question Template').limit(1).get()
        .then(snapshot => {
            if (snapshot.empty) {
              return newId;
            }
            snapshot.forEach(doc => {
                newId = doc.id
            });
            console.log('foudn replacement', newId)
            return newId;
        });
        //console.log('using replacement', newId)
        var status = 'inactive';
        if(originalUtter.status!==undefined && originalUtter.status==='active' ) status = 'active';
        var agent = [''];
        if(originalUtter.agent!==undefined)  agent = originalUtter.agent;
        var template = {text:'new utter', attachment: {explanation: '', utterance_class : utterClass} };
        //console.log(template)
        templateReuse = {text:'reuse old utter', attachment: {explanation: ''}};
        if(originalUtter.template !== undefined ){
            //console.log(originalUtter.template)
            template['text'] = (originalUtter.template?.text || "");
            template['attachment']['explanation'] = (originalUtter?.template?.attachment?.explanation || that.props.firebase.fieldValue.delete());
            template['attachment']['utterance_class'] = utterClass;
            if(originalUtter.template?.buttons?.length > 0){
                template['buttons'] = originalUtter.template.buttons;
            }
        }
        var author = ['HN'];
        if(originalUtter.author !== undefined ) author = originalUtter.author;
        var leadership = ['default']
        if(originalUtter.leadership!== undefined)  leadership = originalUtter.leadership;
        else status = 'inactive';
        var category = ['default'];
        if(originalUtter.category!== undefined)  category = originalUtter.category;
        else status = 'inactive';
        var session =  ['default'];
        if(originalUtter.session!== undefined)  session = originalUtter.session;
        var persona =  ['default'];
        if(originalUtter.persona!== undefined)  persona = originalUtter.persona;
        var mindset_type =  ['default'];
        if(originalUtter.mindset_type!== undefined)  mindset_type = originalUtter.mindset_type;
        var input_channels =  ['rocky', 'firebase'];
        if(originalUtter.input_channels!== undefined)  input_channels = originalUtter.input_channels;
        var day = ['default'];
        if(originalUtter.day!== undefined)  day = originalUtter.day;
        var day_time =  ['default'];
        if(originalUtter.day_time!== undefined)  day_time = originalUtter.day_time;
        var sentiment =  'default';
        if(originalUtter.sentiment!== undefined)  sentiment = originalUtter.sentiment;
        const dict = {
            mongodb_id: newId,
            createdAt: that.props.firebase.fieldValue.serverTimestamp(),
            status: status,
            agent: agent,
            template: template,
            author: author,
            utterance_class: utterClass,
            leadership: leadership,
            category: category,
            session: session,
            persona: persona,
            mindset_type: mindset_type,
            input_channels: input_channels,
            day: day,
            day_time: day_time,
            sentiment: sentiment,
            voteCount: that.props.firebase.fieldValue.delete(),
            vote: that.props.firebase.fieldValue.delete()
        }
        if(originalUtter.intents!== undefined) {
            dict['intents'] = originalUtter.intents
        }
        if(originalUtter.requirements!== undefined) {
            dict['requirements'] = originalUtter.requirements
        }
        if(originalUtter.vote !== undefined){
            dict['vote'] = originalUtter.vote;
        }
        if(originalUtter.voteCount !== undefined){
            dict['voteCount'] = originalUtter.voteCount;
        }
        //console.log('duplicated: ', dict)
        that?.props?.firebase.agentUtter(utterClass, newId).set(dict, {merge:true } );
        that?.setState({errorMessage : 'Added item: ' + utterClass})
    }
    else if(!resetOnly){
        that?.setState({errorMessage : 'Source or class are missing'})
    }
    if(deactivateOriginal && originalUtter.mongodb_id!==undefined && originalUtter.utterance_class !==undefined){
        templateReuse = { text: 'Empty Question Template', attachment: {}};
        templateReuse['text'] = 'Empty Question Template';
        templateReuse['attachment']['utterance_class'] = originalUtter.utterance_class;          
        if(originalUtter?.template?.buttons !== undefined ){
            templateReuse['buttons'] = [];
        } 
        var reUpdateSet = {
            status: 'inactive', 
            template: templateReuse, 
            sentiment: 'default', 
            category: [], 
            leadership: [], 
            day: ['default'], 
            day_time: ['default'], 
            requirements: ['default'],
            input_channels: ['rocky', 'firebase'],
            persona: ['default'],
            session: ['default'],
            author: [],
            agent: [],
            intents: that.props.firebase.fieldValue.delete(),
            mindset_type: that.props.firebase.fieldValue.delete(),
            voteCount: that.props.firebase.fieldValue.delete(),
            vote: that.props.firebase.fieldValue.delete()
        }
        that.setState({})
        console.log('we deactivate', reUpdateSet)
        that?.props?.firebase.agentUtter(originalUtter.utterance_class, originalUtter.mongodb_id).update(reUpdateSet);
    }
}

function _createHex (value) {
    return Math.floor(value).toString(16)
}
export function CreateObjectId () {
    return _createHex(Date.now() / 1000) +
        ' '.repeat(16).replace(/./g, () => _createHex(Math.random() * 16))
}

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export const QuestionUtterancesItemDialog = compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(QuestionUtterancesItemBaseDialog); 

export const MongoUtterances = compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(MongoUtterancesListBase);

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true }),
    withAuthorization(condition),
)(AdminQuestionUtterancesPage);
