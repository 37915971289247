import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import { FindTextValueInDict, QualityID, ReturnFilteredDict } from '../Modules/LoadCategoriesAndQualities';
import { HandleDuplicateItem, CreateObjectId, UserStageRequirements, UserSessionLevel, DayRequirements} from '../Admin/QuestionUtterances';
import {QuestionIntentsItemDialog} from '../Admin/QuestionIntents';
import {UpdateAIQuestions, GetAdminLeadershipCategoriesArray, GetAdminLeadershipQualitiesArray} from '../Admin/QuestionUtterances';
import {MongoUtterances} from '../Admin/QuestionUtterances';
import {LoadIntentClasses, AddExampletoNLUTraining, DescriptionSentiment, UtterancesDescriptions, SentimentTopicIntentsPrefixes, SelectIntentsList, SentimentPrefixChanger, TopicIntentsPrefixes, UtterancesLeadQuestions, UtterancesResponseQuestions, UtterancesMainQuestions} from '../Admin/Training/TrainingDataManagement';
import SettingContentSelectCombi from '../Admin/Modules/SettingContentSelectCombi';


//// table
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import * as chatAgent from '../../chatAgent';
import _ from "lodash";
import logo from "../../images/rocky-logo-square.png";
// General Modules
import LoadingProgress from '../Modules/LoadingProgress';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import {AvatarCard} from '../Modules/AvatarImage';
import { Link } from "react-router-dom";

import SwipeStepper from '../Modules/SwipeStepper';
import Tooltip from '@material-ui/core/Tooltip';

import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Swipe
import VizSensor from 'react-visibility-sensor';
import SwipeableViews from 'react-swipeable-views';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
// Icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
//import DialogKeyWords from '../Admin/Content/DialogKeyWords';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import StarsIcon from '@material-ui/icons/Stars';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

import Masonry from 'react-masonry-css';
// Admin

//import SettingContentBase from '../Admin/Modules/SettingContentBase';
//import SettingContentActive from '../Admin/Modules/SettingContentActive';
//import SettingContentNumber from '../Admin/Modules/SettingContentNumber';
//import SettingContentArray from '../Admin/Modules/SettingContentArray';
//import SettingContentImage from '../Admin/Modules/SettingContentImage';
//import SettingContentUploadImage from '../Admin/Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Admin/Modules/SettingContentRichText';
import SettingContentArraySelect from '../Admin/Modules/SettingContentArraySelect';
import SettingContentArrayCheckbox from '../Admin/Modules/SettingContentArrayCheckbox';
import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
import SettingContentSpecialUtteranceText from '../Admin/Modules/SettingContentSpecialUtteranceText';

import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import Slide from '@material-ui/core/Slide';
import Switch from "react-switch";
import theme from '../../themes';
import { AccessCheckCoachCreator } from '../../constants/roles';
//import { template } from 'lodash';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
    iCircleActive: {
        background: "#39cccc",
        color: "#fff",
        padding: "3px 5px",
        borderRadius: "50%",
        fontSize: "12px",
    },
    iCircleInActive: {
        background: "lightgrey",
        color: "darkgrey",
        padding: "3px 5px",
        borderRadius: "50%",
        fontSize: "12px",
    },
    masonry:{
        width: "100%",
        display: "-webkit-box",
        display: '-ms-flexbox',
        display: "flex",
        paddingRight: 10
    },
    masonryColumn :{
        paddingLeft: 10,
        backgroundClip: "padding-box",
    },
})

const utterLeadQuestions = {};
UtterancesResponseQuestions.map((item => (utterLeadQuestions[item] = [])));

const utterQualitySets = {};
UtterancesLeadQuestions.map((item => (utterQualitySets[item] = [])));

const utterQualityDefaultSets = { 
    "utter_ask_assessment" : [], 
    "utter_ask_progress" : [], 
    "utter_inform_leadership_quality_phase" :[], 
    "utter_ask_awareness":[],
    "utter_confirm_content": [], 
    "utter_recommend_advice_ask_further":[],
    "utter_inform_priority_phase":[] , 
    "utter_ask_activity": [],
    "utter_inform_skill_assessment_phase": [], 
    "utter_ask_quiz":[], 
    "utter_confirm_assessment": [],
    "utter_ask_goal_habit":[]
}
const utterFollowUpSets = {
    "utter_ask_rapport_extended":[], 
    "utter_ask_habit_insight":[],
    "utter_ask_highlight": [], 
    "utter_ask_achievement" :[], 
    "utter_ask_improvement": [], 
    "utter_ask_expectation" :[],
    "utter_ask_challenges" :[],
    "utter_ask_preparation":[], 
    "utter_ask_priority": [],
    "utter_recommend_advice_ask_further":[],
    "utter_ask_mindset":[], 
    "utter_ask_goal_habit":[]
}
const utterFollowUpTemplates = {
    "utter_ask_rapport_extended": 'Please tell me more about your {"keyword"}?', 
    "utter_ask_habit_insight": 'How do you usually handle your {"keyword"}?',
    "utter_ask_improvement": 'What can you improve about your {"keyword"}?', 
    "utter_ask_achievement": 'What was a great achievement of your of {"keyword"}?', 
    "utter_ask_highlight": 'What was the best {"keyword"} of yours?', 
    "utter_ask_expectation" : 'What do you expect for your {"keyword"}?',
    "utter_ask_challenges" : 'What makes {"keyword"} diffucl for you?',
    "utter_ask_priority": 'What is your priority connected to {"keyword"}?',
    "utter_ask_preparation": 'How do you plan to put {"keyword"} in place?', 
    "utter_ask_assessment": 'How confident are you with your current ability in {"keyword"}?',
    "utter_ask_progress" : 'How easy was it for you to make progress in {"keyword"} over the last days?', 
    "utter_ask_goal_habit": 'From today onwards, what activity will you engage in {"keyword"}?'
}

class ManageQuestions extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loadingText: 'loading questions',
            questionDBCatLevel: null,
            searchTag : '',
            loading: true
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadQuestions();
        LoadIntentClasses(this);
        GetAdminLeadershipCategoriesArray(this);
        GetAdminLeadershipQualitiesArray(this);
    }

    componentDidUpdate(prevProps)  {
        if( this._isMounted ){
            if(this.props.searchByUtterIds !== undefined && prevProps.searchByUtterIds !== undefined
                && this.props.searchByUtterIds.length>0 && this.props.searchByUtterIds.length > prevProps.searchByUtterIds.length){
                this.loadQuestions();
            }
            //console.log('update selected Program Question', this.props, prevProps, this.state)
            if(this.props.launchCategory?.length > 2 && "showKey_"+this.props.launchCategory !== this.state.activeKey){
                //console.log('update new Program', this.props.launchCategory, this.state)
                //this.setState({activeKey: "showKey_"+this.props.launchCategory});
            }
            if(this.props.launchQuality?.length > 2 && (this.state.activeKey + this.props.launchQuality) !== this.state.activeQualityKey){
                //console.log('update new qual', this.props.launchQuality, this.state)

            }
        }
        return true
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined){this.unsubscribe();}
        if(this.unsubscribeCat!==undefined){this.unsubscribeCat();}
        this.setState({ loading: false, questionDBCatLevel: null });
    }

    loadQuestions() {
        var ownedGroups = [];
        var ownedGroupsLabels = [];
        let ownedGroupsData = {};
        let questionDBCatLevel = {};
        let questionDBCatLevelAdmin = {'communication': [], 'clarity': [], 'confidence': [], 'balance': [], 'people': [], 'priority': []};
        let questionsLoaded = {};
        let questionInitList = {};
        let qualitiesGroups = {};
        var qualitiesIds = [];
        var qualitiesIdsText = [];
        var adminGroups = [];
        let relatedCategories = [];
        let relatedCategoriesText = [];
        let relatedDefaultCategories = [];
        let relatedDefaultCategoriesText = [];
        this.setState({ loading: true });
        if(this.unsubscribe !== undefined) {
            this.unsubscribe();
        }
        if(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')){
            relatedCategories = ['default'];
            ownedGroups = ['default', 'asIntent'];
            ownedGroupsLabels = ['default for every context', 'default if INTENT is selected'];
            qualitiesIds = ['default', 'asIntent'];
            qualitiesIdsText = ['default for every context', 'default if INTENT is selected'];
        }
        if (this.props.searchByUtterIds !== undefined ){
            var searchByUtterIds = this.props.searchByUtterIds;
            if (searchByUtterIds.length > 9){
                searchByUtterIds = searchByUtterIds.slice(0,9);
            }
            var qualityId = "topic";
            //console.log('searchByUtterIds', searchByUtterIds)
            this.unsubscribe = this.props.firebase
            //.agentUttersList(relatedCategories)
            //.agentUttersByIntent(this.props.searchByIntent)
            .agentUttersCollection()
            .where('mongodb_id', 'in', searchByUtterIds)
            .onSnapshot(snapshot => {
            //.get().then(snapshot => {
                if(this._isMounted){
                    questionDBCatLevel = {};
                    if(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") ){
                        questionDBCatLevel = questionDBCatLevelAdmin;
                    }
                    qualitiesGroups = {};
                    var questionDBQualityLevel = {};
                    //console.log('recopy',questionDBCatLevel, questionInitList );
                    snapshot.forEach(doc => {
                        var utteranceData = doc.data();
                        var noFilter = this.checkFilter(utteranceData.requirements);
                        if(noFilter){
                            //console.log('foudn entry',utteranceData );
                            if(utteranceData.leadership !== undefined || utteranceData.category !== undefined || utteranceData.intents !== undefined ){
                                questionsLoaded[doc.id] = { ...utteranceData, cid: doc.id };
                            }
                            if(utteranceData.category !== undefined && Array.isArray(utteranceData.category) && utteranceData.category.length>0 ){
                                utteranceData.category.forEach((categoryId) => {                                
                                    var type = "other";
                                    if(utteranceData.utterance_class!== undefined){
                                        type = utteranceData.utterance_class;
                                        if(utteranceData.status === 'active'){
                                            if (type=== "utter_ask_second_leadership_quality_question") {
                                                type = "utter_ask_quiz";
                                            }
                                            else if (type=== "utter_ask_leadership_quality_question") {
                                                type = "utter_ask_awareness";
                                            }
                                            else if (type=== "utter_ask_task") {
                                                type = "utter_ask_activity";
                                            }
                                            else if (type=== "utter_inform_todays_leadership_quality_phase") {
                                                type = "utter_inform_todays_leadership_quality_phase";
                                            }
                                        }
                                    }
                                    utteranceData.leadership.map(item => {
                                        if(true){
                                            if(type in utterQualitySets || (type in utterLeadQuestions)){ 
                                            //if (type === "utter_ask_activity" || type === "utter_ask_awareness" || type === "utter_ask_quiz" || type === "utter_inform_leadership_quality_phase"){
                                                qualityId = item;
                                                //console.log('qualityId', qualityId, groupName, categoryId)
                                                if(! Object.keys(questionDBCatLevel).includes(categoryId)){
                                                    questionDBCatLevel[categoryId] = {};
                                                    questionDBQualityLevel[categoryId] = {};
                                                    ownedGroups.push(categoryId);
                                                    ownedGroupsLabels.push(categoryId);
                                                }
                                                if(! Object.keys(qualitiesGroups).includes(categoryId)){
                                                    qualitiesGroups[categoryId] = {ids : [qualityId]};
                                                }
                                                else if(! qualitiesGroups[categoryId]['ids'].includes(qualityId)) {
                                                    qualitiesGroups[categoryId]['ids'].push(qualityId);
                                                }

                                                if(! Object.keys(questionDBQualityLevel[categoryId]).includes(qualityId)){
                                                    //questionDBQualityLevel[categoryId][qualityId] = {};
                                                    questionDBQualityLevel[categoryId][qualityId] = JSON.parse(JSON.stringify(utterQualitySets));
                                                }

                                                if(!(qualitiesIds).includes(qualityId)){
                                                    qualitiesIds.push(qualityId);
                                                    qualitiesIdsText.push("(" + qualityId + ") " + qualityId);
                                                }

                                                if(! Object.keys(questionDBQualityLevel[categoryId][qualityId]).includes(type)){
                                                    questionDBQualityLevel[categoryId][qualityId][type] = [];
                                                }
                                            
                                                //questionDBQualityLevel[groupId][item][type].push({ ...utteranceData, cid: doc.id });
                                                if(utteranceData.status === 'active' && (true || !this.state.showInActive)) questionDBQualityLevel[categoryId][qualityId][type].unshift(doc.id);
                                                else if(utteranceData.status !== 'active' && (true || this.state.showInActive)) questionDBQualityLevel[categoryId][qualityId][type].push(doc.id);
                                            }
                                        }
                                        return true;
                                    })
                                    //console.log('updatesights', questionDBQualityLevel[categoryId], qualitiesGroups[qualityId] )
                                    
                                })
                            }
                        }
                    });
                }

                //activeKey === 'showKey_'+key

                //console.log('fs',questionDBCatLevel, questionDBQualityLevel, qualitiesGroups,  questionsLoaded)
                //console.log('props', this.props)
                if (this._isMounted) {
                    this.setState({
                        questionDBCatLevel,
                        questionDBQualityLevel,
                        questionsLoaded,
                        ownedGroups,
                        ownedGroupsLabels,
                        ownedGroupsData,
                        qualitiesGroups,
                        qualitiesValues: qualitiesIds,
                        qualitiesText: qualitiesIdsText,
                        loading: false,
                    });
                }
            });
        }
        else if(this.props.searchByIntent !== undefined && this.props.searchByIntent !== '' ){
            var searchKeys = [this.props.searchByIntent, 'default'];
            var parentIntent = this.props.searchByIntent.substring(0, this.props.searchByIntent.lastIndexOf("_"));
            if(parentIntent!=='' && parentIntent !== this.props.searchByIntent){
                searchKeys.push(parentIntent)
            }
            if(this.props.simpleView === true){
                searchKeys = [this.props.searchByIntent];
            }
            this.unsubscribe = this.props.firebase
            //.agentUttersList(relatedCategories)
            //.agentUttersByIntent(this.props.searchByIntent)
            .agentUttersByIntents(searchKeys)
            .onSnapshot(snapshot => {
            //.get().then(snapshot => {
                if(this._isMounted){
                    questionDBCatLevel = {};
                    if(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") ){
                        questionDBCatLevel = questionDBCatLevelAdmin;
                    }
                    //questionDBCatLevel = Object.assign({}, questionInitList);
                    if(this.props.searchUtterancesFilter && this.props.searchUtterancesFilter?.length > 0){
                        var searchUtterancesFilter = this.props.searchUtterancesFilter.reduce(function(obj, v) {
                            obj[v] = [];
                            return obj;
                          }, {})
                        questionDBCatLevel['Responses'] = searchUtterancesFilter;
                        questionDBCatLevel['Questions'] = JSON.parse(JSON.stringify(utterFollowUpSets));
                    }
                    else{
                        questionDBCatLevel[this.props.searchByIntent] = JSON.parse(JSON.stringify(utterFollowUpSets));
                    }
                    //console.log('recopy',questionDBCatLevel, questionInitList );
                    snapshot.forEach(doc => {
                        var utteranceData = doc.data();
                        var noFilter = this.checkFilter(utteranceData.requirements);
                        if(noFilter){
                            //console.log('foudn entry', utteranceData );
                            var groupId = "Undefined"
                            if(utteranceData.leadership !== undefined || utteranceData.category !== undefined || utteranceData.intents !== undefined ){
                                questionsLoaded[doc.id] = { ...utteranceData, cid: doc.id };
                            }
                            var groupName = this.props.searchByIntent;
                            groupId = groupName;
                            if(utteranceData.intents.includes(this.props.searchByIntent)){
                                groupId = this.props.searchByIntent;
                                //groupId = "Follow-Ups"
                                if(this.props.searchUtterancesFilter && this.props.searchUtterancesFilter.length > 0){
                                    if (utteranceData?.utterance_class && this.props.searchUtterancesFilter.includes(utteranceData.utterance_class)){
                                        groupId = 'Responses';
                                    }
                                    else{
                                        groupId = 'Questions';
                                    }
                                }
                            }
                            else if(utteranceData.intents.includes('default')){
                                groupId = 'default';
                            }
                            else if(utteranceData.intents.includes(parentIntent)){
                                groupId = 'parent';
                            }
                            //console.log('recopy',questionDBCatLevel, groupId );
                            if(! Object.keys(questionDBCatLevel).includes(groupId)){
                                if(this.props.searchUtterancesFilter && this.props.searchUtterancesFilter.length > 0){
                                    questionDBCatLevel[groupId] = {}
                                }
                                else{
                                    questionDBCatLevel[groupId] = Object.create(questionDBCatLevel[this.props.searchByIntent])
                                }
                            }
                            var type = "other";
                            if(utteranceData.utterance_class!== undefined){
                                type = utteranceData.utterance_class;
                                if(utteranceData.status === 'active'){
                                    if (type === "utter_ask_second_leadership_quality_question") {
                                        type = "utter_ask_quiz";
                                    }
                                    else if (type=== "utter_ask_leadership_quality_question") {
                                        type = "utter_ask_awareness";
                                    }
                                    else if (type=== "utter_ask_task") {
                                        type = "utter_ask_activity";
                                    }
                                }
                            }
                            //console.log('grou', groupId, type)
                            if(Object.keys(questionDBCatLevel[groupId]).includes(type)){
                                if(this.state.showInActive){
                                    if((utteranceData.status === 'active')) questionDBCatLevel[groupId][type].push(doc.id);
                                    else if(utteranceData.status !== 'active') questionDBCatLevel[groupId][type].unshift(doc.id);
                                }
                                else{
                                    if((utteranceData.status === 'active')) questionDBCatLevel[groupId][type].unshift(doc.id);
                                    else if(utteranceData.status !== 'active') questionDBCatLevel[groupId][type].push(doc.id);
                                }
                            }
                            else{
                                questionDBCatLevel[groupId][type] = [ doc.id ];
                            }
                        }
                    });
                }
                //console.log('fs',questionDBCatLevel, ownedGroupsData,  qualitiesIds)
                if (this._isMounted) {
                    this.setState({
                        parentIntent,
                        questionDBCatLevel,
                        questionsLoaded,
                        ownedGroups,
                        ownedGroupsLabels,
                        ownedGroupsData,
                        qualitiesValues: qualitiesIds,
                        qualitiesText: qualitiesIdsText,
                        loading: false,
                    });
                }
            });


        }
        else{
            if(this.context.adminPrograms!== undefined && this.context.adminPrograms.length> 0){
                adminGroups = this.context.adminPrograms;
                if(adminGroups.length > 10) {
                    adminGroups = adminGroups.slice(0,9);
                }
                //console.log("adminGroups", adminGroups)
                this.props.firebase
                .leadershipCategories()
                .where('program', 'in', adminGroups)
                .get().then((data) => {
                //.onSnapshot((data) => {
                    data.forEach(result => {
                        var myGroupsDoc = result.data();
                        if(myGroupsDoc.program !== 'default' || (myGroupsDoc.program === 'default' && (this.context.adminPrograms.includes(myGroupsDoc.value) || this.context.adminPrograms.includes('default_all')) )){
                            if(myGroupsDoc.active === true && myGroupsDoc.value !== 'default' || AccessCheckCoachCreator(this.context)){
                                if(myGroupsDoc.program !== 'default'){
                                    relatedCategories.unshift(myGroupsDoc.value);
                                    relatedCategoriesText.unshift(myGroupsDoc.text)
                                }
                                else{
                                    relatedDefaultCategories.push(myGroupsDoc.value);
                                    relatedDefaultCategoriesText.push(myGroupsDoc.text)
                                }
                                ownedGroups.push(myGroupsDoc.value);
                                ownedGroupsLabels.push(myGroupsDoc.text);
                                ownedGroupsData[myGroupsDoc.value] = myGroupsDoc;
                                questionInitList[myGroupsDoc.value] = {};
                            }
                        }
                    })
                    var searchCategories = [...relatedCategories];
                    if(searchCategories.length > 10) {
                        if(this.state.pageIndex === 2){
                            searchCategories = searchCategories.slice(9,19);
                        }
                        else{
                            searchCategories = searchCategories.slice(0,9);
                            this.setState({pageIndex : 1})
                        }
                    }
                    //console.log('original content', searchCategories , searchCategories);
                    this.setState({
                        searchCategories,
                        relatedCategories: relatedCategories.concat(relatedDefaultCategories),
                        relatedCategoriesText: relatedCategoriesText.concat(relatedDefaultCategoriesText),
                    });
                    if(searchCategories?.length > 0){
                        this.unsubscribe = this.props.firebase
                        //.agentUttersList(relatedCategories)
                        .agentUttersListByCat(searchCategories)
                        .onSnapshot(snapshot => {
                        //.get().then(snapshot => {
                            if(this._isMounted){
                                questionDBCatLevel = {};
                                if(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") ){
                                    questionDBCatLevel = questionDBCatLevelAdmin;
                                }
                                //questionDBCatLevel = Object.assign({}, questionInitList);
                                Object.keys(questionInitList).forEach(key => {
                                    if(searchCategories.includes(key)){
                                        questionDBCatLevel[key] = JSON.parse(JSON.stringify(utterLeadQuestions));
                                    }
                                })
                                //console.log('recopy',questionDBCatLevel, questionInitList );
                                snapshot.forEach(doc => {
                                    var utteranceData = doc.data();
                                    var noFilter = this.checkFilter(utteranceData.requirements);
                                    if(noFilter){
                                        if(utteranceData.leadership !== undefined || utteranceData.category !== undefined || utteranceData.intents !== undefined ){
                                            var responseRating = 0.5; 
                                            if (utteranceData?.voteCount > 0){
                                                responseRating = ((utteranceData.vote || 1)/utteranceData.voteCount).toFixed(2);
                                            }
                                            questionsLoaded[doc.id] = { ...utteranceData, cid: doc.id, responseRating };
                                        }
                                    }
                                });
                                let newQuestionsLoaded = _.sortBy(questionsLoaded, [q => q.responseRating]);
                                newQuestionsLoaded?.map(utteranceData => {
                                    var groupId = "Undefined"
                                    if(utteranceData.category !== undefined && Array.isArray(utteranceData.category) && utteranceData.category.length>0 ){
                                        utteranceData.category.forEach((groupName) => {
                                            groupId = groupName;
                                            if(! Object.keys(questionDBCatLevel).includes(groupId)){
                                                if(searchCategories.includes(groupId)){
                                                    questionDBCatLevel[groupId] = JSON.parse(JSON.stringify(utterLeadQuestions))
                                                }
                                            }
                                            var type = "other";
                                            if(utteranceData.utterance_class!== undefined){
                                                type = utteranceData.utterance_class;
                                            }
                                            /***
                                            if (type === "utter_ask_energy_level" || type === "utter_ask_satisfaction_level" 
                                                || type === "utter_welcome_to_program" || type === "utter_ask_mindset"
                                                || type === "utter_ask_feedback" || type === "utter_inform_mindset_phase"  ){
                                            */        
                                            if (type in utterLeadQuestions){
                                                if(searchCategories.includes(groupId)){
                                                    if(Object.keys(questionDBCatLevel[groupId]).includes(type)){
                                                        if(utteranceData.status === 'active' && (true || !this.state.showInActive)) questionDBCatLevel[groupId][type].unshift(utteranceData.cid);
                                                        else if(utteranceData.status !== 'active' && (true || this.state.showInActive)) questionDBCatLevel[groupId][type].push(utteranceData.cid);
                                                    }
                                                    else{
                                                        questionDBCatLevel[groupId][type] = [ utteranceData.cid ];
                                                    }
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                            //console.log('fs X',questionDBCatLevel, ownedGroups)
                            if (this._isMounted) {
                                this.setState({
                                    questionDBCatLevel,
                                    questionsLoaded,
                                    ownedGroups,
                                    ownedGroupsLabels,
                                    ownedGroupsData,
                                    loading: false,
                                });
                            }
                        });
                    }
                    else{
                        this.setState({
                            loading: false,
                            ownedGroups,
                            ownedGroupsLabels,
                            ownedGroupsData,
                            error: "No active Questions"
                        });
                    }
                })
            }
            else{
                this.setState({
                    loading: false,
                    error: "No assigned categories available."
                });
            }
            let qualitiesGroups = {};
            qualitiesIds = [];
            qualitiesIdsText = [];
            if(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')){
                qualitiesGroups['default'] = {ids : ['default', 'affirmations'], text : ["(default) Default", "(default) Affirmations"]};
                qualitiesIds = ['default', 'asIntent'];
                qualitiesIdsText = ['default for every context', 'default if INTENT is selected'];
            }
            if(this.context.adminPrograms!== undefined && this.context.adminPrograms.length> 0){
                adminGroups = this.context.adminPrograms;
                if(adminGroups.length > 10) {adminGroups = adminGroups.slice(0,9);}
                this.props.firebase
                .leadershipQualities()
                .where('program', 'in', adminGroups)
                .orderBy('category', 'asc')
                .get().then(snapshot => {
                    if(this._isMounted){
                        var qualitiesMotherQuality = [];
                        qualitiesGroups = {};
                        qualitiesIds = [];
                        qualitiesIdsText = [];
                        if(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM')){
                            qualitiesGroups['default'] = {ids : ['default', 'affirmations'], text : ["(default) Default", "(default) Affirmations"]};
                            qualitiesIds = ['default', 'asIntent'];
                            qualitiesIdsText = ['default for every context', 'default if INTENT is selected'];
                        }
                        snapshot.forEach(doc => {
                            var groupId = "undefined";
                            // *** HN adding filtering to users only allowed to be seen by coach who has same program
                            // any kind of program would work, but default program is filtered
                            var qualityData = doc.data();
                            if(qualityData.program !== 'default' || (qualityData.program === 'default' && (this.context.adminPrograms.includes(qualityData.category) || this.context.adminPrograms.includes('default_all')) )){
                                if (qualityData.category!== undefined && qualityData.category!== ""){
                                    groupId = qualityData.category
                                }
                                var groupName = groupId;
                                groupName = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, groupId)
                                var quality = QualityID(qualityData.valueID || qualityData.value);
                                if(Object.keys(qualitiesGroups).includes(groupId)){
                                    qualitiesGroups[groupId]['ids'].push(quality );
                                    qualitiesGroups[groupId]['text'].push( "(" + groupName + ") " + qualityData.text);
                                }
                                else{
                                    qualitiesGroups[groupId] = {ids : [quality], text : ["(" + groupName + ") " + qualityData.text]};
                                }
                                if(qualityData?.motherQuality?.length > 2){
                                    qualitiesMotherQuality[quality] = qualityData.motherQuality;
                                }
                                qualitiesIds.push(quality);
                                qualitiesIdsText.push("(" + groupName + ") " + qualityData.text);
                            }
                        });
                    }
                    //console.log(qualitiesGroups, qualitiesMotherQuality)
                    if (this._isMounted) {
                        this.setState({
                            qualitiesGroups,
                            qualitiesValues: qualitiesIds,
                            qualitiesText: qualitiesIdsText,
                            qualitiesMotherQuality
                        });
                    }
                });
            }
            else{
                this.setState({
                    error: "No assigned categories available."
                });
            }
        }
    }

    oldloadIntentClasses(){
        //var intentGroups = {};
        var intentIds = [];
        var intentIdsText = [];
        var intentIdsDict = {};
        var intentIdsDictText = {};
        this.props.firebase
        .agentIntentClasses()
        .where('active', '==', true)
        .orderBy('intent', 'asc')
        .orderBy('sentiment', 'asc')
        .get().then(snapshot => {
            if(this._isMounted){
                snapshot.forEach(doc => {
                    var groupId = "undefined";
                    var intentData = doc.data();
                    var checkFlag = false;
                    //console.log('intentData', intentData)
                    if(intentData.intentTags !== undefined && this.context.adminIntents !== undefined && this.context.adminIntents.length>0){
                        this.context.adminIntents?.map(tag => {
                            if(intentData.intentTags[tag]){
                                checkFlag = true;
                                //console.log('intentData', intentData.class)
                            }
                            return true;
                        })
                    }
                    else if(this.context.adminIntents===undefined){
                        checkFlag = true;
                    }
                    if(checkFlag){
                        if(intentData.sentiment.includes('_short') && !intentData.sentiment.includes('_past') && !intentData.sentiment.includes('_future') ){
                            groupId = intentData.sentiment
                            var response = "n/a";
                            if(intentData.responses!== undefined && intentData.responses.length > 0){
                                //console.log(intentData.responses)
                                response = intentData.responses[0]
                                if (response.length > 30){
                                    response = response.substring(0,30) + "..."
                                }
                            }
                            var text = intentData.intent + ' - ' + response
                            //var text = "(" + groupId.split('_')[1] + ") " + intentData.intent + ' - ' + intentData.tenses
                            intentIds.push(intentData.class);
                            intentIdsText.push(text);
                            if(!Object.keys(intentIdsDict).includes(groupId)){
                                intentIdsDict[groupId] = [];
                                intentIdsDictText[groupId] = [];
                            }
                            intentIdsDict[groupId].push(intentData.class);
                            intentIdsDictText[groupId].push(text);
                        }
                    }
                });
            }
            //console.log(intentIds, intentIdsText, intentIdsDict)
            if (this._isMounted) {
                this.setState({
                    //intentGroups,
                    intentIds,
                    intentIdsText,
                    intentIdsDict,
                    intentIdsDictText
                });
            }
        });
    }
    checkFilter(requirementsList){
        var validated = true
        if(this.state.filterRequirements !== undefined && this.state.filterRequirements !== 'All'){
            if(this.state.filterRequirements === 'Generic'){
                if (requirementsList === undefined || requirementsList.length===0 || requirementsList.includes('default')){
                    validated = true;
                }
                else{
                    validated = false;
                }
            }
            else if (requirementsList === undefined || !requirementsList.includes(this.state.filterRequirements )){
                validated = false;
            }
        }
        return validated;
    }

    handleSettingChange = (groupId, event, motherQuality=undefined) => {
        //console.log(event)
        //
        //console.log('selectedItem', groupId, event, motherQuality);
        /////// JUST ALWAYS LOAD THE NEW SHIT
        //console.log(groupId, event)
        //this.setState({ ['selection_'+groupId] : event.target.value }) ;
        if(this.state.questionDBQualityLevel === undefined || this.state.questionDBQualityLevel[groupId] === undefined
            /////// the two below ones do not work as they do not know what is already loaded
            // || this.state['selection_'+groupId] !== event.target.value
            // || this.state.questionDBQualityLevel[groupId][event.target.value] === undefined
            || this.props.searchByUtterIds === undefined
            ){
            if(this.state.ownedGroups.length > 0){
                //console.log('staretd refreshj', groupId, event)
                if(this.unsubscribeCat!==undefined){this.unsubscribeCat()}
                var questionDBQualityLevel = {[groupId] : {}};
                //console.log(groupId, questionDBQualityLevel)
                var list = [];
                if(groupId !== 'default' && this.state.qualitiesGroups?.[groupId] !== undefined && this.state.qualitiesGroups?.[groupId]?.['ids'] !== undefined){
                    list = this.state.qualitiesGroups[groupId]['ids'];
                    list.forEach(item => {
                        //console.log(groupId, questionDBQualityLevel)
                        //questionDBQualityLevel[groupId][item] =  {"utter_ask_activity":[], "utter_ask_awareness": [], "utter_ask_quiz" :[]}
                        questionDBQualityLevel[groupId][item] = JSON.parse(JSON.stringify(utterQualitySets));
                    })
                }
                else if(event?.target?.value === 'affirmations'){
                    console.log('afformations search')
                }
                else if(groupId === 'default' && this.state.qualitiesGroups[groupId] !== undefined ){
                    list = ['default']
                    questionDBQualityLevel[groupId]['default'] = JSON.parse(JSON.stringify(utterQualityDefaultSets));
                }
                //console.log(event)
                let questionsLoaded = this.state.questionsLoaded || {};
                if(event?.target?.value === 'affirmations'){
                    this.unsubscribeCat = this.props.firebase
                    .agentUttersCollection()
                    .where('utterance_class', '>=', 'utter_affirm')
                    .where('utterance_class', '<', 'utter_ag')
                    .onSnapshot(snapshot => {
                        if(this._isMounted){
                            questionDBQualityLevel = {default : {}};
                            questionDBQualityLevel['default']['affirmations'] = {};
                            //console.log(selectedItem)
                            snapshot.forEach(doc => {
                                var utteranceData = doc.data();
                                var noFilter = this.checkFilter(utteranceData.requirements);
                                if(noFilter){
                                    //console.log(utteranceData)
                                    if(utteranceData.status !== undefined){
                                        questionsLoaded[doc.id] = { ...utteranceData, cid: doc.id };
                                    }
                                    var item = "affirmations";
                                    var type = "other";
                                    if(utteranceData.utterance_class !== undefined){
                                        type = utteranceData.utterance_class;
                                    }
                                    if(! type.includes('habit')){
                                        if(questionDBQualityLevel[groupId][item][type] === undefined){
                                            questionDBQualityLevel[groupId][item][type] = [];
                                        }
                                        if(utteranceData.status === 'active' && (true || !this.state.showInActive)) questionDBQualityLevel[groupId][item][type].unshift(doc.id);
                                        else if(utteranceData.status !== 'active' && (true || this.state.showInActive)) questionDBQualityLevel[groupId][item][type].push(doc.id);
                                    }
                                }
                            });
                        }
                        //console.log('loaded qual',questionDBQualityLevel)
                        if (this._isMounted) {
                            this.setState({
                                questionDBQualityLevel,
                                questionsLoaded
                            });
                        }
                    })
                }
                else if(event?.target?.value?.length > 0){
                    var selectedQualities = undefined;
                    if(motherQuality?.length > 2){
                        selectedQualities = [event.target.value, motherQuality];
                        ///// ALSO: Add below the motherQuality section
                    }
                    else{
                        selectedQualities = [event.target.value];
                    }
                    this.unsubscribeCat = this.props.firebase
                    .agentUttersList(selectedQualities)
                    .onSnapshot(snapshot => {
                        if(this._isMounted){
                            questionDBQualityLevel = {[groupId] : {}};
                            //// ADD the mother quality
                            if(motherQuality?.length > 2){
                                questionDBQualityLevel[groupId]['motherQuality'] = JSON.parse(JSON.stringify(utterQualityDefaultSets));
                                console.log('motherquality', motherQuality, questionDBQualityLevel)
                            }
                            list.forEach(item => {
                                //questionDBQualityLevel[groupId][item] =  {"utter_ask_activity":[], "utter_ask_awareness": [], "utter_ask_quiz" :[], "utter_inform_leadership_quality_phase" : []}
                                if (item === 'default'){
                                    questionDBQualityLevel[groupId][item] = JSON.parse(JSON.stringify(utterQualityDefaultSets));
                                }
                                else{
                                    questionDBQualityLevel[groupId][item] = JSON.parse(JSON.stringify(utterQualitySets));
                                }
                            })
                            if(false && groupId === 'default'){
                                questionDBQualityLevel['default']['default'] = utterFollowUpSets;
                            }
                            //console.log(selectedQualities)
                            snapshot.forEach(doc => {
                                var utteranceData = doc.data();
                                var noFilter = this.checkFilter(utteranceData.requirements);
                                if(noFilter){
                                    //console.log(utteranceData)
                                    //console.log('question', utteranceData.template.text, utteranceData.leadership)
                                    if(utteranceData.leadership !== undefined || utteranceData.category !== undefined || utteranceData.intents !== undefined ){
                                        questionsLoaded[doc.id] = { ...utteranceData, cid: doc.id };
                                    }
                                    var type = "other";
                                    if(utteranceData.utterance_class !== undefined){
                                        type = utteranceData.utterance_class;
                                        if(utteranceData.status === 'active'){
                                            if (type === "utter_ask_second_leadership_quality_question") {
                                                type = "utter_ask_quiz";
                                            }
                                            else if (type=== "utter_ask_leadership_quality_question") {
                                                type = "utter_ask_awareness";
                                            }
                                            else if (type=== "utter_ask_task") {
                                                type = "utter_ask_activity";
                                            }
                                            else if (type=== "utter_inform_todays_leadership_quality_phase") {
                                                type = "utter_inform_todays_leadership_quality_phase";
                                            }
                                        }
                                    }
                                    else{
                                        console.log(questionsLoaded[doc.id] )
                                    }
                                    utteranceData?.leadership?.map(item => {
                                        if(list.includes(item)){
                                            if((item === 'default' && type in utterQualityDefaultSets) || (item !== 'default' && type in utterQualitySets) || (item !== 'default' && type in utterLeadQuestions)){ 
                                            //if (type === "utter_ask_activity" || type === "utter_ask_awareness" || type === "utter_ask_quiz" || type === "utter_inform_leadership_quality_phase"){
                                                //questionDBQualityLevel[groupId][item][type].push({ ...utteranceData, cid: doc.id });
                                                if(questionDBQualityLevel[groupId][item][type] === undefined){
                                                    questionDBQualityLevel[groupId][item][type] = [];
                                                }
                                                if(utteranceData.status === 'active' && (true || !this.state.showInActive)) {
                                                    questionDBQualityLevel[groupId][item][type].unshift(doc.id);
                                                }
                                                else if(utteranceData.status !== 'active' && (true || this.state.showInActive)) {
                                                    questionDBQualityLevel[groupId][item][type].push(doc.id);
                                                }
                                            }
                                        }
                                        return true;
                                    })
                                    if(motherQuality?.length > 2 && !utteranceData.leadership?.includes(event.target.value)){
                                        const newItem = 'motherQuality'
                                        if(questionDBQualityLevel[groupId][newItem][type] === undefined){
                                            questionDBQualityLevel[groupId][newItem][type] = [];
                                        }
                                        if(utteranceData.status === 'active' && (true || !this.state.showInActive)) {
                                            questionDBQualityLevel[groupId][newItem][type].unshift(doc.id);
                                        }
                                    }
                                }
                            });
                        }
                        //console.log('loaded qual',questionDBQualityLevel, questionsLoaded)
                        if (this._isMounted) {
                            this.setState({
                                questionDBQualityLevel,
                                questionsLoaded
                            });
                        }
                    })
                }
            }
        }
        this.setState({ ['selection_'+groupId] : event.target.value }) ;
        //console.log('selectedQualities', groupId, event, this.state)
    }

    setProgramModule(category = undefined, qualityvalue = undefined, tab = undefined){
        if(this._isMounted){
            //console.log('setprohra', this.state)
            if(this.props.clickAction!== undefined){
                this.props.clickAction(category, qualityvalue, tab)
            }
            this.setState({
                activeKey: (category?.length > 2 ? ('showKey_' + category) : undefined), 
                activeQualityKey: qualityvalue,
            })
        }
    }

    render(){
        const {questionDBCatLevel} = this.state;
        const requirementsList = [
            'All',
            'Generic',
            'executive',
            'professional',
            'seeker',
            'student',
        ]
        //console.log('MQ statee', this.state)
        return(
            <div>
                {this.state.pageIndex >=1 &&
                    <div style={{width: '100%', textAlign:'left'}}>
                        <span 
                            style={{color: theme.palette.secondary.main}}
                            onClick={() => {this.setState({pageIndex: 1, filterQLevel: 'default' }, this.loadQuestions())}}
                        >
                            Page 1
                        </span>
                        &nbsp; | &nbsp;
                        <span 
                            style={{color: theme.palette.secondary.main}}
                            onClick={() => {this.setState({pageIndex: 2, filterQLevel: 'default'}, this.loadQuestions())}}
                        >
                            Page 2
                        </span>
                    </div>
                }
                {this.state.selectedDefaultItem !== true
                &&
                    <div style={{marginBottom: 40}}>
                        <span 
                            onClick={() => this.setProgramModule("", "")}
                            style={{
                                textDecorationLine : this.state.activeKey?.length > 1 ? "underline" : "none",
                                color : this.state.activeKey?.length > 1 ? theme.palette.secondary.main : undefined
                            }}
                        >
                            My Programs
                        </span>
                        {this.state.activeKey?.length>0
                            &&
                            <>
                                {" > "}
                                <span 
                                    onClick={() => this.setState({showUtter: "", ["selection_"+this.state.activeKey?.replace('showKey_', "")] : ""})}
                                    style={{
                                        textDecorationLine : this.state["selection_"+this.state.activeKey?.replace('showKey_', "")]?.length>0 ? "underline" : undefined,
                                        color :this.state["selection_"+this.state.activeKey?.replace('showKey_', "")]?.length>0 ? theme.palette.secondary.main : undefined
                                    }}
                                >
                                    {(FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.state.activeKey?.replace('showKey_', "")) || this.state.activeKey) }
                                </span>
                            </>
                        }
                        {this.state.activeKey?.length>0 && this.state["selection_"+this.state.activeKey?.replace('showKey_', "")]?.length>0
                            &&
                            <>
                                {" > "}
                                <span 
                                    onClick={
                                        this.state.showUtter?.length>0
                                        ?   () => this.setState({showUtter: ""})
                                        :   () => this.setState({showUtter: "", ["selection_"+this.state.activeKey?.replace('showKey_', "")] : ""})
                                    }
                                    style={{
                                        textDecorationLine : this.state.showUtter?.length>0 ? "underline" : undefined,
                                        color : this.state.showUtter?.length>0 ? theme.palette.secondary.main : undefined
                                    }}
                                >
                                    {(FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.state["selection_"+this.state.activeKey?.replace('showKey_', "")]) || this.state["selection_"+this.state.activeKey?.replace('showKey_', "")]) }
                                </span>
                            </>
                        }
                        {this.state.showUtter?.length>0
                            &&
                            <>
                                {" > "}
                                <span 
                                    onClick={() => this.setState({showUtter: ""})}
                                    style={{
                                        textDecorationLine : false? "underline" : undefined,
                                        color : false ? theme.palette.secondary.main : undefined
                                    }}
                                >
                                    <strong style={{color: 'green'}}>{UtterancesDescriptions?.[this.state.showUtter]?.name || this.state.showUtter}</strong>
                                </span>
                            </>
                        }
                    </div>
                }
                <div style={{width: '100%', textAlign:'right'}}>
                    Filter User Groups: &nbsp;
                    <Select
                        value={this.state.filterRequirements || 'All'}
                        onChange={(e) => {
                            this.setState({filterRequirements: e.target.value}, () => this.loadQuestions());
                        }}
                        name='requirements'
                        >                  
                        {requirementsList?.map(entry => {
                            return (
                                <MenuItem key={"add_"+entry} value={entry} >
                                    {entry}
                                </MenuItem>
                            ) 
                        })}
                    </Select>
                </div>
                {this.state.loading
                &&
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <QuestionIntentsItemDialog
                    searchSentiment = {this.state.searchSentiment}
                    searchClass = {this.state.searchClass}
                    openDialogQIntents={this.state.dialogIntentsOpen || false}
                    closeDialog={(e) => {this.setState({dialogIntentsOpen : false })}}
                    leadershipCategories={this.props.leadershipCategories || []}
                    leadershipQualities={this.props.leadershipQualities || []}
                    leadershipCategoriesValues={this.state.leadershipCategoriesValues || []}
                    leadershipCategoriesValuesText={this.state.leadershipCategoriesValuesText || []}
                    leadershipQualitiesValues={this.state.leadershipQualitiesValues || []}
                    leadershipQualitiesValuesText={this.state.leadershipQualitiesValuesText || []}
                    leadershipQualitiesGroups= { this.state.leadershipQualitiesGroups}
                    leadershipQualitiesGroupsText= {this.state.leadershipQualitiesGroupsText}
                />
                {this.state.loading 
                    ?
                        <LoadingProgress 
                            type={'LinearProgress'} 
                            defaultSet={this.props.defaultSet}
                            loadingText={this.state.loadingText} 
                        />
                    :
                        questionDBCatLevel!==undefined && questionDBCatLevel!==null 
                        ?
                            Object.keys(questionDBCatLevel)?.map((key, index) => (
                                key.length>0
                                ?
                                    (!(this.state.activeKey?.length > 0) || this.state.activeKey === ("showKey_"+key) || (this.state['selection_'+key] === key))
                                    ?   this.renderGroups(key, index)
                                    :   ""
                                :
                                    ""
                            ))
                        :
                            <div> No questions assigned yet!</div>
                }
                {
                    this.dialogueQuestionSettings()
                }
                    <TextField
                        name="text"
                        type="text"
                        //label="Search by Text"
                        placeholder="Filter Selection by Text"
                        value={this.state.searchTag || ''}
                        onChange={ (event) => {this.setState({searchTag : event.target.value})}}
                        //fullWidth
                        style={ {
                            fontSize: '16px', 
                            backgroundColor:'white',
                            position: 'absolute',
                            top: 100,
                            right: 50,
                            width: 250,
                            paddingLeft: 15,
                            fontSize: 16,
                            borderRadius: 30,
                            background: 'linear-gradient(21deg, #ff3b3b, #ffffff)',
                            display: 'inline-block'
                        } }
                    />
                    
            </div>
        )
    }

    renderQualityAvatars (key) {
        const {qualitiesGroups , contentsByQuality} = this.state;
        const {theme} = this.props;
        return(
            <div>
                {qualitiesGroups?.[key]?.['ids']?.map( (item, index) => (
                    <span style={{marginRight: 20}}
                        key={index}
                        onClick={() => {
                            this.setState({["selection_"+key]: item})
                            this.setProgramModule(key, item)
                        }}
                    >
                        <AvatarCard key={index} dict={this.props.leadershipQualities} type='quality' item={item} squareSize='20' />
                    </span>
                ))}
            </div>
        )
    }
    renderQualityList (group) {
        const {qualitiesGroups} = this.state;
        const {theme} = this.props;
        return(
            <div>
                {qualitiesGroups?.[group]?.['ids']?.map( (subKey, index) => (
                    <span 
                        style={{
                            marginRight: 20,
                            textDecorationLine : this.state?.["selection_"+group] !== subKey ? "underline" : "none",
                            color : this.state?.["selection_"+group] !== subKey ? theme.palette.secondary.main : undefined
                                
                        }}
                        key={index}
                        onClick={() => {
                            this.handleSettingChange(group, {target: {value: subKey}}, (this.state.qualitiesMotherQuality?.[subKey] || undefined))
                            this.setProgramModule(group, subKey)
                            //this.setState({activeKey: 'showKey_'+subKey, ["selection_"+subKey]: "", filterQLevel: 'default'})
                        }}
                    >
                        {FindTextValueInDict(undefined, this.props.leadershipQualities, subKey, 'text')}
                    </span>
                ))}
            </div>
        )
    }

    renderGroups = (key, index) => {
        //console.log("Response Questions", key, index, this.state)
        //const {questionDBCatLevel } = this.state;
        return(
            <VizSensor 
                partialVisibility={true}
                key={key+'_'+index}
                onChange={(isVisible) => { 
                    if(isVisible && this.state['isVisible_'+key]===undefined) { 
                        this.setState({ ['isVisible_'+key] : true})
                    }}
                }
            >
                {(!(this.state.activeKey?.length > 2) || this.state.activeKey === 'showKey_'+key )
                ?
                    <Grid container spacing={2} direction='row' justify="space-between"
                        key={key+'_gr_'+index}
                        style={{height: 'calc(100% - 10px)', minHeight: 200, borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 10, marginBottom: 20}}
                    >
                        <Grid item xs={this.state.activeKey === 'showKey_'+key ? 8 : 12}
                            onClick={() => {
                                this.setState({activeQualityKey: ""})
                                this.setProgramModule(this.state.activeKey?.replace("showKey_", ""))
                            }}
                        >
                            {this.state.ownedGroupsData!== undefined && this.state.ownedGroupsData[key] !== undefined && this.state.ownedGroupsData[key].icon !== undefined
                                ?
                                    <AvatarCard key={key} type='imageIcon' 
                                        image={this.state.ownedGroupsData[key].icon} 
                                        textBlock={this.state.ownedGroupsData[key].text} 
                                        squareSize='20'  
                                        backgroundColor={this.state.ownedGroupsData[key].backgroundColor} />
                                :
                                    (key === 'default' || this.props.searchByIntent)
                                    ?
                                        <AvatarCard key={key} type='imageIcon' 
                                            image={logo} 
                                            textBlock={this.props.searchByIntent? ('Follow-Ups: '+key) : key} 
                                            squareSize='20'  
                                            backgroundColor={'white'} />
                                    :
                                        <AvatarCard 
                                            key={key} 
                                            dict={this.props.leadershipCategories} type='category'
                                            item={key} 
                                            squareSize='20' />
                            }
                        </Grid>
                        {this.state.activeKey === 'showKey_'+key 
                        &&
                            <Grid item xs={4} style={{textAlign: 'right'}}>
                            </Grid>
                        }
                        {(this.state.activeKey !== 'showKey_'+key || this.state["selection_"+key]?.length > 0 )
                        &&
                            <Grid item xs={12}>
                                {this.renderQualityList (key)}
                            </Grid>
                        }
                            
                        {this.state.activeKey === 'showKey_'+key
                            ?
                                <>
                                    {this.state.qualitiesGroups?.[key]?.['ids']?.map ( (item, index) => (
                                        <Grid item xs={this.state["selection_"+key]?.length > 0 ? 12 : 4}  key={key+item}>
                                            {(this.state["selection_"+key]?.length > 0 && this.state["selection_"+key] !== item)
                                                ?   ""
                                                :   
                                                    <div style={{borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 20}}>
                                                        {this.renderQualities(key, item, index)}
                                                    </div>
                                            }
                                        </Grid>
                                    ))}
                                    {true &&
                                        <Grid item xs={this.state["selection_"+key]?.length > 0 ? 12 : 4}  key={key+'default'}>
                                            {this.props.searchByUtterIds === undefined 
                                                ?   (this.state["selection_"+key]?.length > 0 && this.state["selection_"+key] !== "Response Questions")
                                                    ?   ""
                                                    :   
                                                        <div style={{borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 20}}>
                                                            {this.renderQualities(key, "Response Questions", 99)}
                                                        </div>
                                                :   ""
                                            }
                                        </Grid>
                                    }
                                </>
                            :
                                <Grid item xs={12} style={{textAlign: 'right',  marginBottom: 20}}>
                                    <Button 
                                        onClick={() => {
                                            this.setProgramModule(key, undefined)
                                            this.setState({activeKey: 'showKey_'+key, ["selection_"+key]: "", filterQLevel: 'default'})}}
                                        style={{ fontSize:'small'}}>
                                            Add/Manage Questions &nbsp;  <ImageSearchIcon/>
                                    </Button>
                                </Grid>
                        }
                        {this.state.activeKey === 'showKey_'+key 
                        &&
                            <Grid item xs={4} style={{textAlign: 'left'}}>
                                <Link 
                                    to={void(0)}
                                    style={{
                                        textDecorationLine : "underline" ,
                                        color : theme.palette.secondary.main 
                                    }}
                                    onClick={() => {
                                        this.setProgramModule(this.state.activeKey?.replace("showKey_", ""), undefined, "Programs & Quests")
                                    }}
                                >   
                                    Edit Program/Quests
                                </Link>
                            </Grid>
                        }
                    </Grid>
                :   <div>&nbsp;</div>
                }
            </VizSensor>
        )
    }

    renderQualities = (group, subKey, index) => {
        //console.log("swipe", subKey, group)
        return(
            <div>
                <Grid container spacing={0} direction='row' alignItems="center" style={{width:"100%"}}>
                    <Grid item xs={12}>
                        {(subKey === 'default' || this.props.searchByIntent)
                            ?
                                <AvatarCard key={subKey} type='imageIcon' 
                                    image={logo} 
                                    textBlock={subKey} 
                                    squareSize='20'  
                                    backgroundColor={'white'} />
                            :
                                <AvatarCard key={subKey} dict={this.props.leadershipQualities} type='quality' item={subKey} squareSize='20' />
                        }
                        &nbsp;
                        {subKey === "Response Questions" && group!=='default' &&
                            <AvatarCard key={group} dict={this.props.leadershipCategories} type='category' item={group} squareSize='20' />
                        }
                        {subKey === "Response Questions" && group==='default' &&
                            <AvatarCard key={group} type='imageIcon' 
                                image={logo} 
                                textBlock={group} 
                                squareSize='20'  
                                backgroundColor={'white'} />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <span 
                            onClick={
                                this.state.showUtter?.length>0
                                ?   () => this.setState({showUtter: ""})
                                :   () => this.setState({showUtter: "", ["selection_"+this.state.activeKey?.replace('showKey_', "")] : ""})
                            }
                            style={{
                                textDecorationLine : this.state.showUtter?.length>0 ? "underline" : undefined,
                                color : this.state.showUtter?.length>0 ? theme.palette.secondary.main : undefined
                            }}
                        >
                            {subKey === "Response Questions"? 'Show Program questions' : 'Show Quest questions' }: 
                        </span>
                        &nbsp;&nbsp;
                    </Grid>
                    <Grid item xs={12}>
                        {(this.state['selection_'+group] === subKey)
                            ?   subKey === "Response Questions"
                                ?
                                    Object.keys(this.state.questionDBCatLevel[group])?.map(item => (
                                        <div key={item+group}>
                                            {(this.state.showUtter?.length > 0 && this.state.showUtter !== item)
                                                ?   ""
                                                :   this.renderViews(this.state.questionDBCatLevel[group][item], group, item)
                                                    //this.renderTable(this.state.questionDBCatLevel[group][item], group, item)
                                            }
                                        </div>
                                    ))
                                :    
                                    this.state.questionDBQualityLevel?.[group]?.[this.state['selection_'+group]] !== undefined  
                                    ?
                                        Object.keys(this.state.questionDBQualityLevel[group][this.state['selection_'+group]])?.map((item, index) => (
                                            <div key={subKey+item+"_"+index}>
                                                {(this.state.showUtter?.length > 0 && this.state.showUtter !== item)
                                                    ?   ""
                                                    :   this.renderViews(this.state.questionDBQualityLevel[group][this.state['selection_'+group]][item],  group, item, this.state['selection_'+group])
                                                }
                                            </div>
                                        ))
                                    :
                                        <span style={{color:'red'}}>loading ... {this.state['selection_'+group]} </span>
                                
                            :
                                <div style={{width: '100%', textAlign: 'right', marginTop: 20}}> 
                                    <Button 
                                        style={{marginBottom:50}}
                                        onClick={(e) => this.handleSettingChange(group, {target: {value: subKey}}, (this.state.qualitiesMotherQuality?.[subKey] || undefined))}
                                    >
                                        Show Section &nbsp; <ImageSearchIcon/>
                                    </Button>
                                </div>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderViews = (questionDBCatLevel, key, subKey, quality="Response Questions") => {
        const {classes} = this.props;
        //console.log("renderViewsin", key, subKey, questionDBCatLevel, quality, this.state)
        //console.log('pool',this.state.questionDBQualityLevel)
        if(questionDBCatLevel !== undefined && this.state.questionsLoaded !== undefined ){
            return(
                <div>
                    <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                        <Grid item xs={8} onClick={() => this.setState({showUtter: subKey})}>
                            {true &&
                                <span>  
                                    {UtterancesDescriptions?.[subKey]?.type || "Questions"} 
                                </span>
                            }
                            {true &&
                                <span>
                                    &nbsp;&gt; <strong style={{color: 'green'}}>{UtterancesDescriptions?.[subKey]?.name || subKey}</strong>
                                </span>
                            }
                            <br/> <strong style={{color: theme.palette.secondary.light}}>{UtterancesDescriptions? UtterancesDescriptions?.[subKey]?.description : "" }</strong>
                            {true &&
                                <span>
                                    <br/> 
                                    Custom questions ({questionDBCatLevel.length || "0"}):
                                </span>
                            }
                            {false &&
                                <span>   
                                    &nbsp;
                                    {
                                    //(FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, key) || key) 
                                    }
                                    &nbsp;&gt; {(FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, quality) || quality) }
                                </span>
                            }&nbsp;
                        </Grid>
                        <Grid item xs={4} style={{textAlign: 'right'}}>
                            {this.state.showUtter === subKey
                            ? 
                                <>
                                    {(this.props.searchByUtterIds === undefined 
                                        && (key!== 'default' || (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") || (this.context.adminIntents !== undefined && key!== 'default'))))
                                        && (subKey in utterQualitySets || subKey in utterLeadQuestions || subKey in utterFollowUpSets 
                                            || (this.props.searchUtterancesFilter && this.props.searchUtterancesFilter.length > 0 && this.props.searchUtterancesFilter.includes(subKey))
                                            || (subKey in utterQualityDefaultSets && (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") )))
                                        && quality !== "Response Questions"
                                        &&
                                        <Button 
                                            onClick={ () => {
                                                this.addNewTemplateQuestion(key, quality, subKey);
                                                this.setState({showInActive : true, filterQLevel: 'default'});
                                            }} 
                                            style={{float:"right", fontSize:'small', backgroundColor:'red'}} >
                                            Create question + 
                                        </Button>
                                    }
                                    {this.context.roles?.includes('ADMIN') 
                                        &&
                                        <Button onClick={ () => {UpdateAIQuestions(this, questionDBCatLevel, key, subKey)}} 
                                            style={{float:"right", fontSize:'small', backgroundColor:'blue'}}
                                            disabled={this.state['loading_AI_'+key+subKey] ? true : false}>
                                            Update&nbsp;A.I.&nbsp;<AccountTreeIcon/>
                                        </Button>
                                    }
                                    {(this.props.searchByUtterIds === undefined && this.state.searchTag!== undefined && this.state.searchTag !== '')
                                        &&
                                        <Button onClick={ () => {this.findQuestions(key, quality, subKey)}} style={{float:"right", fontSize:'small', backgroundColor:'green'}} >
                                            Find questions +
                                        </Button>
                                    }
                                </>
                            :   
                                <Button 
                                    onClick={() => this.setState({showUtter: subKey})} 
                                    style={{fontSize:'small'}}>
                                        Show Items &nbsp;  <ImageSearchIcon/>
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state.showUtter === subKey
                    &&
                        <>
                            {false &&
                                <p>
                                    {((questionDBCatLevel!== undefined && questionDBCatLevel.length ) || 0) + " items"} 
                                </p>
                            }
                            {false &&
                                <SwipeStepper
                                    handlerSteps = {(step) => this.setState({['activeStep_'+key+subKey]:step}) }
                                    steps={(questionDBCatLevel!== undefined && questionDBCatLevel.length )|| 0}
                                    activeStep = {this.state['activeStep_'+key+subKey] || 0}
                                    showBackButton = {true}
                                />
                            }
                            {questionDBCatLevel?.length?.length > 1
                            ?
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="Type" name="type"
                                        value={ this.state.filterQLevel || "default"}
                                        onChange={(e4) => {this.setState({filterQLevel: e4.target.value, ['activeStep_'+key+subKey]: 0}) } }
                                    >
                                        <FormControlLabel value="default" control={<Radio color='secondary' />} label="All Items" />
                                        <FormControlLabel value="first" control={<Radio color='secondary' />} label="First Chats" />
                                        <FormControlLabel value="basic" control={<Radio color='secondary'/>} label="Beginner Chats" />
                                        <FormControlLabel value="intermediate" control={<Radio color='secondary'/>} label="Intermediate Chats" />
                                        <FormControlLabel value="advanced" control={<Radio color='secondary'/>} label="Advanced Chats" />   
                                        <FormControlLabel value="top6" control={<Radio color='secondary' />} label="Ranked Items" /> 
                                    </RadioGroup>
                                </FormControl>
                            :
                                <div 
                                    style={{height: 240, width: 200, margin: 10, borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 20}}
                                >
                                    <Button
                                        onClick={() => {
                                            this.addNewTemplateQuestion(key, quality, subKey);
                                            this.setState({showInActive : true, filterQLevel: 'default'});
                                        }}
                                    >
                                        Create Question +
                                    </Button>
                                </div>
                            }
                            {true && this.props.tableView !== true &&
                                <Masonry
                                    breakpointCols={{default: IsMobileDevice()? 2 : 6, 1600: IsMobileDevice()? 2 : 6}}
                                    className={classes.masonry}
                                    columnClassName={classes.masonryColumn}
                                >
                                    {questionDBCatLevel !== undefined && questionDBCatLevel.map((item, index) => {
                                        var text = undefined;
                                        if(this.state.questionsLoaded[item]!== undefined && this.state.questionsLoaded[item].template !== undefined){
                                            text = this.state.questionsLoaded[item].template.text;
                                        }
                                        var filterSession = ['default'];
                                        if(this.state.questionsLoaded[item]!== undefined && this.state.questionsLoaded[item].session !== undefined){
                                            filterSession = this.state.questionsLoaded[item].session;
                                        }
                                        //console.log('text', text, item, index, this.state.questionsLoaded[item], this.state)
                                        if(text !== undefined && this.state.questionsLoaded[item].cid !== undefined
                                            && (this.state.searchTag === undefined || this.state.searchTag === '' || text.includes(this.state.searchTag))
                                            && (((this.state.filterQLevel === undefined || this.state.filterQLevel === 'top6') && index<6) || this.state.filterQLevel === 'default' || filterSession.includes(this.state.filterQLevel))  
                                        ){
                                            return(
                                                <VizSensor 
                                                    partialVisibility={true}
                                                    key={key+subKey+'_'+index}
                                                    style={{width: 200, paddingRight: 10 }}
                                                    onChange={(isVisible) => { 
                                                        if(isVisible && this.state['isVisibleItem_'+key+subKey+'_'+index]===undefined) { 
                                                            this.setState({ ['isVisibleItem_'+key+subKey+'_'+index] : true})
                                                        }}
                                                    }
                                                >
                                                    {
                                                        this.state['isVisibleItem_'+key+subKey+'_'+index]
                                                        ?
                                                            this.renderContentCard(this.state.questionsLoaded[item], this.state.questionsLoaded[item].cid, key, subKey)
                                                        :
                                                            <Skeleton variant="rect" width={200 } height={200} />
                                                    }
                                                </VizSensor>
                                            )
                                        }
                                        else{
                                            return (
                                                <div key={key+subKey+'_'+index}
                                                    style={{marginLeft: -100, marginRight: -100, width:0}}> 
                                                </div>
                                            )
                                        }
                                    })}
                                </Masonry>
                            }
                            {this.props.tableView &&
                                this.renderTable(questionDBCatLevel, key, subKey)
                            }
                            {false && this.props.tableView !== true &&
                                <SwipeableViews
                                        key={key+subKey}
                                        axis={'x'}
                                        index={this.state['activeStep_'+key+subKey] || 0}
                                        onChangeIndex={(index) => {
                                            try{
                                                this.handleStepChange(index, key+subKey)
                                            }
                                            catch (err) {console.log('Swipe Error')}
                                        }}
                                        enableMouseEvents = {this.state.open === true? false : true}
                                        disabled = {this.state.open === true? true : false}
                                        style={{
                                            paddingLeft: 0,
                                            width:200,
                                            overflow: 'visible',
                                        }}
                                        slideStyle={{
                                            paddingBottom: 10,
                                            flex: 'none',
                                            margin: 0,
                                            maxWidth: '100%',
                                            width: 'auto'
                                        }}
                                    >
                                    {questionDBCatLevel !== undefined && questionDBCatLevel.map((item, index) => {
                                        var text = undefined;
                                        if(this.state.questionsLoaded[item]!== undefined && this.state.questionsLoaded[item].template !== undefined){
                                            text = this.state.questionsLoaded[item].template.text;
                                        }
                                        var filterSession = ['default'];
                                        if(this.state.questionsLoaded[item]!== undefined && this.state.questionsLoaded[item].session !== undefined){
                                            filterSession = this.state.questionsLoaded[item].session;
                                        }
                                        if(text !== undefined && this.state.questionsLoaded[item].cid !== undefined
                                            && (this.state.searchTag === undefined || this.state.searchTag === '' || text.includes(this.state.searchTag))
                                            && (this.state.filterQLevel === undefined || this.state.filterQLevel === 'default' || filterSession.includes(this.state.filterQLevel))  
                                        ){
                                            return(
                                                <VizSensor 
                                                    partialVisibility={true}
                                                    key={key+subKey+'_'+index}
                                                    style={{width: 200, paddingRight: 10 }}
                                                    onChange={(isVisible) => { 
                                                        if(isVisible && this.state['isVisibleItem_'+key+subKey+'_'+index]===undefined) { 
                                                            this.setState({ ['isVisibleItem_'+key+subKey+'_'+index] : true})
                                                        }}
                                                    }
                                                    >
                                                    {
                                                        this.state['isVisibleItem_'+key+subKey+'_'+index]
                                                        ?
                                                            this.renderContentCard(this.state.questionsLoaded[item], this.state.questionsLoaded[item].cid, key, subKey)
                                                        :
                                                            <Skeleton variant="rect" width={200 } height={200} />
                                                    }
                                                </VizSensor>
                                            )
                                        }
                                        else{
                                            return (
                                                <div key={key+subKey+'_'+index}
                                                    style={{marginLeft: -100, marginRight: -100, width:0}}> 
                                                    {this.state.questionsLoaded?.[item]?.cid}
                                                </div>
                                            )
                                        }
                                    })}
                                </SwipeableViews>
                            }
                            {false &&
                                <Button
                                    onClick={() => {
                                        this.setState({showInActive : !this.state.showInActive, ['activeStep_'+key+subKey] : 0});
                                        this.handleSettingChange(key, {target: {value: quality}});
                                        this.loadQuestions();
                                    }}
                                    style={{color:'green'}}
                                >
                                    Show {this.state.showInActive? 'Active Only' : 'In/Active'} 
                                </Button>
                            }
                        </>
                    }
                    <hr/>
                    {this.state.qualitiesMotherQuality?.[quality]?.length > 2
                        ?
                            <p>
                                Template questions ({this.state.questionDBQualityLevel?.[key]?.motherQuality?.[subKey]?.length || "n/a"}):
                                <br/>
                                {this.state.showUtter === subKey
                                && 
                                    <span style={theme.textSupport}>
                                        All templates can be removed in the "Preloaded Template" in the "Sorting and Access Settings" of the Quest.
                                    </span>
                                }
                            </p>
                        :
                            <span style={theme.textSupport}>
                                You can add templates in the "Preloaded Template" in the "Sorting and Access Settings" of the Quest.
                            </span>
                    }
                    {this.state.qualitiesMotherQuality?.[quality]?.length > 2 && this.state.showUtter === subKey && this.props.tableView !== true &&
                        <Masonry
                            breakpointCols={{default: IsMobileDevice()? 2 : 6, 1600: IsMobileDevice()? 2 : 6}}
                            className={classes.masonry}
                            columnClassName={classes.masonryColumn}
                        >
                            {this.state.questionDBQualityLevel?.[key]?.motherQuality?.[subKey]?.map((item, index) => {
                                var text = undefined;
                                if(this.state.questionsLoaded[item]!== undefined && this.state.questionsLoaded[item].template !== undefined){
                                    text = this.state.questionsLoaded[item].template.text;
                                }
                                var filterSession = ['default'];
                                if(this.state.questionsLoaded[item]!== undefined && this.state.questionsLoaded[item].session !== undefined){
                                    filterSession = this.state.questionsLoaded[item].session;
                                }
                                //console.log('text', text, item, index, this.state.questionsLoaded[item], this.state)
                                if(text !== undefined && this.state.questionsLoaded[item].cid !== undefined
                                    && (this.state.searchTag === undefined || this.state.searchTag === '' || text.includes(this.state.searchTag))
                                    && (((this.state.filterQLevel === undefined || this.state.filterQLevel === 'top6') && index<6) || this.state.filterQLevel === 'default' || filterSession.includes(this.state.filterQLevel))  
                                ){
                                    return(
                                        <VizSensor 
                                            partialVisibility={true}
                                            key={key+subKey+'_'+index}
                                            style={{width: 200, paddingRight: 10 }}
                                            onChange={(isVisible) => { 
                                                if(isVisible && this.state['isVisibleItem_'+key+subKey+'_'+index]===undefined) { 
                                                    this.setState({ ['isVisibleItem_'+key+subKey+'_'+index] : true})
                                                }}
                                            }
                                        >
                                            {
                                                this.state['isVisibleItem_'+key+subKey+'_'+index]
                                                ?
                                                    this.renderContentCard(this.state.questionsLoaded[item], this.state.questionsLoaded[item].cid, key, subKey, this.context.adminPrograms)
                                                :
                                                    <Skeleton variant="rect" width={200 } height={200} />
                                            }
                                        </VizSensor>
                                    )
                                }
                                else{
                                    return (
                                        <div key={key+subKey+'_'+index}
                                            style={{marginLeft: -100, marginRight: -100, width:0}}> 
                                        </div>
                                    )
                                }
                            })}
                        </Masonry>
                    }
                </div>
            )
        }
        else return ""
    }

    renderTable = (table, entry, index) => {
        let views = [];
        return (
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Active</TableCell>
                        <TableCell>Rating</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell>Mood</TableCell>
                        <TableCell>Profile</TableCell>
                        <TableCell>Session</TableCell>
                        <TableCell>Summary</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table?.map((id, indextab) => {  
                        var item = this.state.questionsLoaded[id];
                        if(item ){
                            return(
                            (this.state.filterAgent === undefined || (item.agent !== undefined && item.agent.includes( this.state.filterAgent)))
                            &&
                            (this.state.filterSession === undefined || (item.session !== undefined && item.session.includes( this.state.filterSession)))
                            &&
                            
                            (this.state.filterQuality === undefined || (item.leadership !== undefined && item.leadership.includes( this.state.filterQuality)))
                            &&
                            (this.state.filterDay === undefined || (item.day !== undefined && item.day.includes( this.state.filterDay)))
                            &&
                            (this.state.filterStatus === undefined || (item.status === this.state.filterStatus ))
                            &&
                            (this.state.filterCategory === undefined || (item.category !== undefined && item.category.includes( this.state.filterCategory)))
                            &&
                                <TableRow key={'tab_'+indextab}
                                    onClick={
                                        () => this.handleopenQuestionSettings(id, entry, index)
                                    //    () => {this.setState({openQuestionSettings : true, itemId: item.uid, content: item,utterId: entry })}
                                    }
                                    style={{backgroundColor: this.props.searchTextArray === item?.template?.text ? "orange" : "transparent"}}
                                >
                                    <TableCell>{item?.status}</TableCell>
                                    <TableCell>
                                        {item.voteCount!== undefined && item.voteCount> 0
                                            &&
                                            <strong>
                                                {item.voteCount > 10 
                                                    ?
                                                        Math.trunc((item.vote || 1)/item.voteCount*100) + '% ('+item.voteCount+')'
                                                    :
                                                        item.vote+'/'+item.voteCount
                                                }
                                                &nbsp;
                                            </strong>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <span style={{color: item?.status === 'inactive' ? "grey" : "black"}}>{item?.template?.text}</span>
                                    </TableCell>
                                    <TableCell>
                                        {item.sentiment === 'positive'
                                            ?   <EmojiEmotionsIcon fontSize="small" style={{color:"green"}}/>
                                            :   item.sentiment === 'negative'
                                                ?   <SentimentVeryDissatisfiedIcon fontSize="small" style={{color:"red"}}/>
                                                :
                                                    <>
                                                        <EmojiEmotionsIcon fontSize="small" style={{color:"green"}}/> / <SentimentVeryDissatisfiedIcon fontSize="small" style={{color:"red"}}/>
                                                    </>
                                        }
                                    </TableCell>
                                    <TableCell>{UserStageRequirements(this, item?.requirements || undefined)}</TableCell>
                                    <TableCell>{UserSessionLevel(this, item?.session || undefined)}</TableCell>
                                    <TableCell>
                                        Check ?
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        else return ""
                    })}
                </TableBody>
            </Table>
        )
    }
    
    handleStepChange = (step, key) => {
        //console.log(step, key, 'handler')
        if(step !== undefined && key !== undefined) {
            this.setState({['activeStep_'+key] : step});
        }
    };

    addNewTemplateQuestion = async (category, quality, utter) => {
        this.setState({loadingAdd: true});
        var qualities = ['undefined']
        if(quality === "Response Questions"){
            if( this.props.leadershipQualities !== undefined ){
                var relatedQualities = ReturnFilteredDict(this.props.firebase, this.state.leadershipQualities, category, 'category');
                if(relatedQualities!== undefined && relatedQualities.length > 0) {
                    qualities = QualityID(relatedQualities.map( x => x.value));
                    console.log("related", qualities)
                }
            }
        }
        else qualities = [quality];
        var newId = CreateObjectId();
        console.log('created id ', newId)
        await this.props.firebase.agentUtters(utter).where('template.text', '==', 'Empty Question Template').limit(1).get()
        .then(snapshot => {
            if (snapshot.empty) {
              return newId;
            }
            snapshot.forEach(doc => {
                newId = doc.id
            });
            console.log('foudn replacement', newId)
            return newId;
        });
        //console.log('using replacement', newId)
        var template = {text:'Empty Question Template', attachment: {explanation: 'EXPLANATION OF QUESTION', utterance_class: utter} };
        let dictNew = {
            status: 'inactive',
            mongodb_id : newId,
            utterance_class: utter,
            agent: ['priming', 'reflection'],
            author: [this.context.email],
            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            template: template,
            leadership: qualities,
            session: ['default'],
            persona:  ['default'],
            mindset_type: ['default'],
            input_channels: ['rocky', 'firebase'],
            day: ['default'],
            day_time: ['default'],
            sentiment: 'default'
        }
        if(quality === "Response Questions"){
            dictNew['category'] = [category];
        }
        if(this.state.filterRequirements !== undefined && this.state.filterRequirements !== 'All' && this.state.filterRequirements !== 'Generic'){
            dictNew['requirements'] = [this.state.filterRequirements]
        }
        if(this.props.searchByIntent !== undefined && this.props.searchByIntent !== ''){
            dictNew['intents'] = [this.props.searchByIntent];
            dictNew['leadership'] = ['asIntent']
            dictNew['category'] = ['asIntent']
            //dictNew['leadership'] = (this.props.leadership || ['default'])
            //dictNew['category'] = (this.props.category!== undefined ? [this.props.category] : ['default'])
            //console.log('dictNew', dictNew)
        }
        var templateText = 'Empty Question Template';
        this.props.firebase.agentUtter(utter, newId).set(dictNew, {merge:true } )
        .then( () => {
            this.setState({loadingAdd: false});
        });
        //console.log("new created first", dictNew)
        if(utter in utterFollowUpTemplates){
            var key = "";
            if (this.props.intentSamples !== undefined && this.props.intentSamples.length>0){
                key = this.props.intentSamples[Math.floor(Math.random()*this.props.intentSamples.length)];
            }
            if(key === ""){
                if (qualities?.length>0){
                    var key_value = qualities[Math.floor(Math.random()*qualities.length)];
                    key = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, key_value)
                    //console.log('replace', key, key_value)
                }
            }
            if (key !== ""){
                templateText = utterFollowUpTemplates[utter].replace('{"keyword"}', key)
                //console.log('question input',utterFollowUpTemplates[utter], templateText)
                var prompt = 'Give me 2 accountability question alternatives that a success coach would ask a person about: "' + templateText + '". Keep close the initial question design.'
                var results = await GetNewSentencesRockyAI(templateText, prompt)
                //console.log('results', results)
                var idLists = [newId];
                results?.map(async (result, indexResult) => {
                    var newExtraId = ""+newId;
                    if(indexResult >= 0){
                        await this.props.firebase.agentUtters(utter).where('template.text', '==', 'Empty Question Template').where('mongodb_id', "!=", newId).limit(1).get()
                        .then(snapshot => {
                            if (snapshot.empty) {
                                newExtraId = CreateObjectId();
                                //console.log('created extra id ', newExtraId)
                            }
                            snapshot.forEach(doc => {
                                newExtraId = doc.id
                                //console.log('foudn exatr replacement', newExtraId, idLists)
                                if(idLists.includes(newExtraId)){
                                    newExtraId = CreateObjectId();
                                    //console.log('foudn double replacement', newExtraId)
                                }
                                idLists.push(newExtraId)
                            });
                            if(newExtraId!=newId){
                                var newExtraDict = dictNew;
                                var templateExtra = {text: result, attachment: {explanation: '', utterance_class: utter} };
                                newExtraDict['template'] = templateExtra;
                                newExtraDict['mongodb_id'] = newExtraId;
                                console.log("new created question", newExtraDict, result)
                                this.props.firebase.agentUtter(utter, newExtraId).set(newExtraDict, {merge:true } );
                            }
                            return newExtraId;
                        });
                        console.log('using extra replacement', newExtraId)
                    }
                    return true;
                })      
            }          
        }
        this.setState({errorMessage : 'Added item: ' + utter})
    }

    findQuestions = (category, quality, utter) => {
        //console.log('find qu', category, quality, utter, this.state.searchTag);
        this.props.firebase
        .agentUtters(utter)
        .get().then(snapshot => {
            if(this._isMounted){
                var questionsLoaded = this.state.questionsLoaded;
                var questionDBCatLevel = this.state.questionDBCatLevel;
                //console.log('existing set', questionsLoaded , questionDBCatLevel)
                var newItems = 0;
                snapshot.forEach(doc => {
                    var utteranceData = doc.data();
                    //console.log('utteranceData', utteranceData.template.text)
                    if(utteranceData.template !== undefined && utteranceData.template.text!== undefined){
                        var questionText = utteranceData.template.text;
                        questionText = questionText.toLowerCase();
                        var searchSubText =  this.state.searchTag.toLowerCase();
                        if(questionText.includes(searchSubText)){
                            questionsLoaded[doc.id] = { ...utteranceData, cid: doc.id };
                            if(!questionDBCatLevel[category][utter].includes(doc.id)){
                                newItems += 1;
                                questionDBCatLevel[category][utter].push(doc.id);
                            }
                            //console.log('utteranceData', utteranceData.template.text)
                        }
                    }
                })
                if(newItems > 0 && this._isMounted){
                    this.setState({questionsLoaded, questionDBCatLevel})
                }
            }
        })
    }

    handleAddFollowUpIntent = (utterClass, utterId, intentClass) => {
        if(utterId.mongodb_id !== undefined && utterId.mongodb_id !== ''){
            this.props.firebase.agentUtter(utterClass, utterId.mongodb_id).update({intents : this.props.firebase.fieldValue.arrayUnion(intentClass) });
        }
    }
    handleRemoveFollowUpIntent = (utterClass, utterId, intentClass) => {
        if(utterId.mongodb_id !== undefined && utterId.mongodb_id !== ''){
            this.props.firebase.agentUtter(utterClass, utterId.mongodb_id).update({intents : this.props.firebase.fieldValue.arrayRemove(intentClass) });
        }
    }

    renderContentCard = (item, itemId, categoryKey, subKey, childPrograms=undefined) => {
        //console.log('categoryKey, subKey, childPrograms', categoryKey, subKey, childPrograms, this.state["selection_"+this.state.activeKey?.replace('showKey_', "")])
        const {theme} = this.props;
        var secondColor = 'lightgrey';
        if(item.status === 'active' ){
            secondColor = "green";
            if(item.session !== undefined &&  item.session.includes('first')  ){
                secondColor = "gold";
            }
            else if(item.session !== undefined &&  item.session.includes('basic')  ){
                secondColor = "sandybrown";
            }
            else if(item.session !== undefined &&  item.session.includes('intermediate')  ){
                secondColor = "coral";
            }
            else if(this.props.searchByIntent !== undefined && item.intents !== undefined && item.intents.length > 0 && item.intents.includes(this.props.searchByIntent ) ){
                secondColor = "pink";
            }
            else if((item.category !== undefined &&  item.category.includes('default') ) ||  (item.intents !== undefined && item.intents.includes('default')) ){
                secondColor = "lightblue";
            }
            else if(item.category === undefined || (item.category !== undefined &&  item.category.length === 0)  ){
                secondColor = "orange";
            }
        }
        else if(item?.template?.text === "Empty Question Template"){
            secondColor = "red";
        }
        var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 30%)';
        
        return(
            <Card key={itemId}  style={{...theme.cardJournalDayHighlight, background: background }}   >
                <CardContent>
                    <div>
                        {item.voteCount!== undefined && item.voteCount> 0
                            && 
                            <Tooltip title={'Positive votes vs total votes'}>
                                <strong>
                                    {item.voteCount > 10 
                                        ?
                                            Math.trunc((item.vote || 1)/item.voteCount*100) + '% ('+item.voteCount+')'
                                        :
                                            item.vote+'/'+item.voteCount
                                    }
                                    &nbsp;
                                </strong>
                            </Tooltip>
                        }
                        {item.agent !== undefined && item.agent.includes("reflection")
                            && 
                            <Tooltip title={'Used for evening reflection'}>
                                <NightsStayIcon fontSize="small" style={{color:"blue"}}/>
                            </Tooltip>
                        }
                        {item.agent !== undefined && item.agent.includes("priming")
                            && 
                            <Tooltip title={'Used for morning mindset'}>
                                <WbSunnyIcon fontSize="small" style={{color:"yellow"}}/>
                            </Tooltip>
                        }
                        {((item.template !== undefined && item.template.attachment === undefined )|| 
                         ( (item.template !== undefined && item.template.attachment!== undefined && 
                          (item.template.attachment.explanation === undefined || item.template.attachment.explanation === ""))))
                            && 
                            <Tooltip title={'Explanation is missing'}>
                                <SmsFailedIcon fontSize="small" style={{color:"pink"}}/>
                            </Tooltip>
                        }
                        {item.sentiment === 'positive'
                            && 
                            <Tooltip title={'Only for positive sentiment'}>
                                <EmojiEmotionsIcon fontSize="small" style={{color:"green"}}/>
                            </Tooltip>
                        }
                        {item.sentiment === 'negative'
                            &&
                            <Tooltip title={'Only for negative sentiment'}>
                                <SentimentVeryDissatisfiedIcon fontSize="small" style={{color:"red"}}/>
                            </Tooltip>
                        }
                        {item.session !== undefined && item.session.includes("basic")
                            && 
                            <Tooltip title={'Simple question'}>
                                <ChildFriendlyIcon fontSize="small" style={{color:"black"}}/>
                            </Tooltip>
                        }
                        {item.session !== undefined && item.session.includes("intermediate")
                            && 
                            <Tooltip title={'Intermediate question'}>
                                <MotorcycleIcon fontSize="small" style={{color:"black"}}/>
                            </Tooltip>
                        }
                        {item.session !== undefined && item.session.includes("advanced")
                            && 
                            <Tooltip title={'Difficult question'}>
                                <AirportShuttleIcon fontSize="small" style={{color:"black"}}/>
                            </Tooltip>
                        }
                        {((item.leadership !== undefined && item.leadership.includes("default") )
                            ||
                            (item.category !== undefined && item.category.includes("default") ))
                            ? 
                                <Tooltip title={'Part of the default / fall back set'}>
                                    < StarsIcon fontSize="small" style={{color:"black"}} />
                                </Tooltip> 
                            : ((item.leadership !== undefined && item.leadership.length > 1 )
                                    ||
                                    (item.category !== undefined && item.category.length > 1 ))
                                ? 
                                    <Tooltip title={'Multiple Quests assigned'}>
                                        < DoneAllIcon fontSize="small" style={{color:"orange"}} />
                                    </Tooltip> 
                                :
                                    ""
                        }
                        {item.intents !== undefined && item.intents.length > 0
                            &&
                            <Tooltip title={'Follow-up Intents are assigned'}>
                                <SettingsEthernetIcon fontSize="small" style={{ color: item?.nlu?.topicAcc < 0.8 ? 'red' : 'blue'}} /> 
                            </Tooltip>
                        }
                        &nbsp;
                        <span>{item.template !== undefined? item.template.text : 'CREATE QUESTION'}</span>
                        &nbsp;
                        {item.template?.buttons?.length>0
                            &&
                            <Tooltip title={'Response options available'}>
                                <RadioButtonCheckedIcon fontSize="small" style={{ color: 'blue'}} /> 
                            </Tooltip>
                        }
                    </div>
                    <div>
                        {(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM"))
                            &&
                            <Tooltip title={'Duplicate Item'}>
                                <IconButton style={{padding:2}}  onClick={() => HandleDuplicateItem(this, subKey, item)}>
                                    <FileCopyIcon fontSize="small" style={{ color: 'red'}}  />
                                </IconButton>
                            </Tooltip>
                        }
                        {DayRequirements(item?.day || undefined)}
                        <br/>
                        {UserStageRequirements(this, item?.requirements || undefined)}
                    </div>
                    <hr/>
                    {!(childPrograms?.length > 0) && (categoryKey !== 'default' || ( this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") ))
                        ?
                            <Button onClick={() => this.handleopenQuestionSettings(itemId, categoryKey, subKey)}>
                                Edit<AssignmentIcon fontSize="small"/>
                            </Button>
                        :
                            <Button 
                                onClick={() => {
                                    var newItem = Object.assign({}, item)
                                    newItem['leadership'] = [this.state["selection_"+this.state.activeKey?.replace('showKey_', "")]]
                                    newItem['category'] = []
                                    newItem['author'] = []
                                    HandleDuplicateItem(
                                        this, 
                                        subKey, 
                                        newItem,
                                        false
                                    )
                                }}
                            >
                                Make Copy<AssignmentIcon fontSize="small"/>
                            </Button>
                    }
                    {this.props.searchByIntent !== undefined && (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM")) 
                        && (item.intents === undefined || (item.intents !== undefined && !item.intents.includes(this.props.searchByIntent )) )
                        &&
                        <Tooltip title={'Add this intent from follow-up list'}>
                            <IconButton style={{padding:2}} onClick={() => this.handleAddFollowUpIntent(subKey, item, this.props.searchByIntent)}>
                                <PlaylistPlayIcon fontSize="small" style={{ color: 'green'}} /> 
                            </IconButton>
                        </Tooltip>
                    }
                    {this.props.searchByIntent !== undefined && (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") )
                        && item.intents !== undefined && item.intents.includes(this.props.searchByIntent )
                        &&
                        <Tooltip title={'Remove this intent from follow-up list'}>
                            <IconButton style={{padding:2}}  onClick={() => this.handleRemoveFollowUpIntent(subKey, item, this.props.searchByIntent)}>
                                <SpeakerNotesOffIcon fontSize="small" style={{ color: 'orange'}} /> 
                            </IconButton>
                        </Tooltip>
                    }
                </CardContent>
            </Card>
        )
    } 

    handleClose = () => {
        this.setState({openQuestionSettings: false, openCategorySettings: false, openNotificationSettings: false, open:false} )
    }
    handleopenQuestionSettings = (cid, categoryKey, subKey) => {
        this.setState({selectedItemId : cid, selectedCategoryKey:categoryKey, subKey, openQuestionSettings: true, openCategorySettings: false, openNotificationSettings: false, open:true} )
    } 

    dialogueQuestionSettings = () => {
        const {theme} = this.props;
        const {selectedItemId, selectedCategoryKey, subKey} = this.state;
        var content = undefined;
        var itemId = undefined;
        //console.log('ópen', selectedCategoryKey, selectedItemId, this.state['selection_'+selectedCategoryKey])
        if(this.state.questionsLoaded!==undefined && (this.state['selection_'+selectedCategoryKey] === "Response Questions" || this.state['selection_'+selectedCategoryKey] === undefined || this.state['selection_'+selectedCategoryKey] === "")){
            if(this.state.openQuestionSettings && selectedItemId !== undefined && selectedItemId !== "" && selectedCategoryKey!==undefined
                && this.state.questionDBCatLevel !== undefined && this.state.questionDBCatLevel[selectedCategoryKey] !== undefined && subKey !== undefined && this.state.questionDBCatLevel[selectedCategoryKey][subKey]!== undefined
                ){
                //content =  this.state.questionDBCatLevel[selectedCategoryKey][subKey].find(q => q.cid === selectedItemId );
                content =  this.state.questionsLoaded[selectedItemId];
                itemId = selectedItemId
            }
        }
        else if(this.state.questionsLoaded!==undefined && this.state.openQuestionSettings && selectedItemId !== undefined && selectedItemId !== "" && selectedCategoryKey!==undefined
            && this.state.questionDBQualityLevel !== undefined && this.state.questionDBQualityLevel[selectedCategoryKey] !== undefined && subKey !== undefined 
            ){
            //content =  this.state.questionDBQualityLevel[selectedCategoryKey][this.state['selection_'+selectedCategoryKey]][subKey].find(q => q.cid === selectedItemId );
            content =  this.state.questionsLoaded[selectedItemId];
            itemId = selectedItemId
        }

        var access = false;
        if(this.context.roles !== undefined && ( this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") )){
            access = true;
        }
        else if(this.props.searchByIntent !== undefined && this.props.searchByIntent !== ''){
            access = true;
        }
        else if(this.state.ownedGroups!== undefined &&  this.state.qualitiesValues!== undefined && content!==undefined && content.leadership !== undefined){
            //&& this.state.qualitiesValues.some(item => content.leadership.includes(item) )
            let notAvailabeCat = [... (content.category || [])].filter(x => !this.state.ownedGroups.includes(x));
            let notAvailabeQual = [...content.leadership].filter(x => !this.state.qualitiesValues.includes(x));
            //console.log('access group', this.state.ownedGroups, content.category , 'leng', notAvailabeCat)
            //console.log('access quality', this.state.qualitiesValues, content.leadership , ';eng', notAvailabeQual)
            if(notAvailabeCat.length === 0 && notAvailabeQual.length === 0){
                //console.log('we have access')
                access = true;
            }
        }

        //console.log(content, selectedItemId)
        return(
            <RemoveScroll enabled={this.state.openQuestionSettings || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openQuestionSettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth={"sm"}
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Manage Question
                            {false && content?.template?.text}
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {itemId!==undefined && content !== undefined && content.utterance_class !== undefined
                            ? 
                                <div>
                                    {false 
                                        && <h4> {content.utterance_class} </h4>  
                                    }
                                    {this.context?.roles?.includes('ADMIN') && content?.nlu?.topic?.length > 1
                                        &&
                                        <div style={{color :'orange'}}>
                                            {content.nlu.intent} / {content.nlu.topic} ({content.nlu.topicAcc}) 
                                            <IconButton onClick={() => {this.setState({searchSentiment: content.nlu.intent, searchClass : content.nlu.topic , dialogIntentsOpen : true })}}>
                                                <SettingsApplicationsIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    }
                                    {false && this.context?.roles?.includes('ADMIN')  //////// SOMETHING WRONG WITH EMPTY ITEMS
                                    && 
                                        <div style={{color :'orange'}}>Editors: 
                                            <AvatarGroup>
                                                {content.author?.length > 0 && content.author?.map(authorName => (
                                                    <Avatar key={authorName} alt="author">{authorName.substring(0, 2)}</Avatar>
                                                ))}
                                            </AvatarGroup>
                                        </div> 
                                    }
                                    {this.state.searchTag
                                    && 
                                        <p>Filter: {this.state.searchTag || "n/a"}</p>
                                    }
                                    <>
                                        <SettingContentSelect 
                                            dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                            title={"Published in the app"} 
                                            content={content} 
                                            params={['status']}
                                            showButtons={true}
                                            noLabel={true}
                                            selectOptions={['inactive', 'active']}
                                            selectOptionsText={['unpublished', 'published']}
                                            access={ content.template?.text === "Empty Question Template" || content.template?.explanation === "EXPLANATION OF QUESTION" ? false : access }
                                            updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                        />
                                        {content && content.voteCount!== undefined
                                            &&
                                            <div style={{width: '100%'}}>
                                                <Tooltip title={'Positive votes vs total occasions:'}>
                                                    <strong>
                                                        Scores: {content.vote} / Occasions {content.voteCount}&nbsp;
                                                        {content.voteCount > 10 &&
                                                            Math.trunc((content.vote || 1)/content.voteCount*100) + '%'
                                                        }
                                                        &nbsp;
                                                    </strong>
                                                </Tooltip>
                                                &nbsp;
                                                {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                                    &&
                                                    <Button onClick={() => {this.props.firebase.agentUtter(content.utterance_class, itemId).update({vote: 0, voteCount: 0})}}>
                                                        Reset Counter
                                                    </Button>
                                                }
                                            </div>
                                        }
                                        {content.status === 'inactive' 
                                            ?
                                                <div style={{width: '100%', textAlign: 'right'}}>
                                                    <Button onClick={() => HandleDuplicateItem(this, undefined, content, true, true)}>
                                                        Remove item
                                                    </Button>
                                                </div>
                                            :
                                                <div style={{width: '100%', textAlign: 'right'}}>
                                                    Unpublish content to remove it
                                                </div>

                                        }
                                        <SettingContentSpecialUtteranceText 
                                            dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                            showAnswerFeature={true}
                                            title={"Question/Text and Explanation/Inspiration"} 
                                            content={content} 
                                            utterance_class={content.utterance_class}
                                            showButtonFeature={content.utterance_class?.includes('_level')? true : false}
                                            access={ access  }
                                            updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                            infoText={[(UtterancesDescriptions && content.utterance_class?.length > 2) ? UtterancesDescriptions?.[content.utterance_class]?.description : "" ]}
                                        />                                  
                                        {content.template?.buttons?.length>0
                                            &&
                                            <div style={{width: '100%', textAlign: 'right'}}>
                                                <Tooltip title={'Response options available'}>
                                                    <RadioButtonCheckedIcon fontSize="small" style={{ color: 'blue'}} /> 
                                                </Tooltip>
                                            </div>
                                        }
                                        {( this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") )
                                        &&
                                            <Button
                                                //disabled = {this.state.questionsSearchingAvailable || false}
                                                onClick={async () => {
                                                    var result = await GetNewExplanationRockyAI(content.template.text)
                                                    //console.log('extra check', result)
                                                    this.setState({explanationAlternatives: result})
                                                }}>
                                                Generate Explanation
                                            </Button>
                                        }
                                        {content.status === 'active'
                                        &&
                                        <>
                                            {this.state.explanationAlternatives?.length>1
                                                &&
                                                <>
                                                    <span style={theme.textSupportCenter}>You can copy an example and paste it as a new explanation.</span>
                                                    <div key={'extrq_exapl'}>
                                                        {this.state.explanationAlternatives}
                                                        &nbsp; 
                                                        <FileCopyIcon
                                                            onClick={ () => {
                                                                window.prompt("Copy to clipboard: Ctrl+C, Enter", this.state.explanationAlternatives)
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            <br/>
                                            {( this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") )
                                            &&
                                                <Button
                                                    disabled = {this.state.questionsSearchingAvailable || false}
                                                    onClick={async () => {
                                                        this.setState({questionsSearchingAvailable: true})
                                                        var result = await GetNewSentencesRockyAI(content.template.text)
                                                        this.setState({questionsAlternatives: result, questionsSearchingAvailable: false})
                                                        console.log('extra check', result)
                                                    }}>
                                                    Generate Rewrites
                                                </Button>
                                            }
                                            {this.state.questionsAlternatives !== undefined && this.state.questionsAlternatives.length>1
                                                &&
                                                <>
                                                <span style={theme.textSupportCenter}>You can copy an example and paste it as a new text/question.</span>
                                                {this.state.questionsAlternatives?.map( (item, index) => (
                                                    <div key={'extrq_'+index}>
                                                        {item}
                                                        &nbsp; 
                                                        <FileCopyIcon
                                                            onClick={ () => {
                                                                window.prompt("Copy to clipboard: Ctrl+C, Enter", item)
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                                </>
                                            }
                                            {this.state.questionsAlternatives !== undefined && this.state.questionsAlternatives.length===1
                                                &&
                                                <>
                                                    <span style={theme.textSupportCenter}>Try to modify the text slightly and retry to generate new samples.</span>
                                                </>
                                            }
                                            <h4>Time Filters</h4>
                                            <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                                                <Grid item xs>
                                                    <SettingContentArrayCheckbox
                                                        dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                        title={"AI Routine (Mindset vs Reflection)"} 
                                                        content={content} 
                                                        params={['agent']}
                                                        selectAtLeastOne={true}
                                                        access={ access }
                                                        noLabel={true}
                                                        emptyStage={'No chatbot option'}
                                                        selectOptions={['priming', 'reflection']}
                                                        selectOptionsText={['Morning Chat', 'Reflective Chat']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                        />
                                                </Grid>
                                                <Grid item xs>
                                                    <SettingContentArraySelect
                                                        dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                        title={"Optional: Filter Day Time"} 
                                                        content={content} 
                                                        access={access }
                                                        selectAtLeastOne={true}
                                                        params={['day_time']}
                                                        emptyStage={'Any time'}
                                                        noLabel={true}
                                                        selectOptionsText={['Any time', 'Morning', 'Noon', 'Afternoon', 'Evening', 'Night', 'Sleeptime']}
                                                        selectOptions={['default', 'morning', 'noon', 'afternoon', 'evening', 'night', 'sleep']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <SettingContentArrayCheckbox
                                                        dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                        title={"Optional: Filter Weekday"} 
                                                        content={content} 
                                                        access={ access }
                                                        selectAtLeastOne={true}
                                                        params={['day']}
                                                        emptyStage={'Every day'}
                                                        noLabel={true}
                                                        selectOptionsText={['Every day', 'During weekdays', 'Tue-Thu', 'Weekend only','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ]}
                                                        selectOptions={['default', 'weekday', 'midweek', 'weekend', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday','saturday', 'sunday']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                        />
                                                    <div style={{textAlign: 'right'}}>
                                                        {DayRequirements(content?.day || undefined)}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <h4>Situation Filters</h4>
                                            <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                                                <Grid item xs>
                                                    <SettingContentArraySelect
                                                        dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                        title={"Chat Progress Level"} 
                                                        content={content} 
                                                        params={['session']}
                                                        noLabel={true}
                                                        showAdvanceLevelButtons={true}
                                                        emptyStage={"Every chat, from first to advanced"}
                                                        selectOptions={['default', 'first', 'basic', 'intermediate', 'advanced']}
                                                        selectOptionsText={['Any (from first to advanced chats)', 'First Chats', 'Beginner Chats', 'Intermediate Chats', 'Advanced Chats']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <SettingContentArraySelect
                                                        dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                        title={"Optional: Question Tense"} 
                                                        content={content} 
                                                        params={['tenses']}
                                                        noLabel={true}
                                                        emptyStage={'Every tense'}
                                                        selectOptions={["default", "past", "future"]}
                                                        selectOptionsText={["anytime", "past", "future"]}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                        />
                                                </Grid>
                                                <Grid item xs>
                                                    <SettingContentSelect
                                                        dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                        title={"User Sentiment (negative, neutral, positive)"} 
                                                        content={content} 
                                                        access={ access }
                                                        params={['sentiment']}
                                                        noLabel={true}
                                                        emptyStage={'Every sentiment'}
                                                        showButtons={true}
                                                        selectOptionsText={['negative - the mood is low', 'neutral - can be always asked', 'positive - the mood is good' ]}
                                                        selectOptions={['negative', 'default', 'positive']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <h4>Persona Filters</h4>
                                            <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                                                <Grid item xs={6}>
                                                    <SettingContentArrayCheckbox
                                                        dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                        title={"Optional: Personality Preference"} 
                                                        content={content} 
                                                        access={ access }
                                                        noLabel={true}
                                                        params={['persona']}
                                                        emptyStage={'Every persona'}
                                                        selectOptionsText={['Neutral - Can be used for everyone', 'Direct - Assertive and more pushy', 'Caring - Submissive and sweet', 'Non-native english - Very simple language']}
                                                        selectOptions={['default', 'direct', 'caring', 'non-native']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                        />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SettingContentArrayCheckbox
                                                        dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                        title={"Optional: User Life-stage"} 
                                                        content={content} 
                                                        params={['requirements']}
                                                        showLifeStageButtons={true}
                                                        noLabel={true}
                                                        emptyStage={"Every Life-Stage"}
                                                        selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                                        selectOptionsText={['Question for every life-stage', 'Executive/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'Teenager / K-12' ]}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                    />
                                                    {false &&  <div style={{textAlign: 'right'}}>
                                                        {UserStageRequirements(this, content?.requirements || undefined)}
                                                    </div>}
                                                </Grid>
                                            </Grid>
                                            <h4>Program Filters</h4>
                                            <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                                                <Grid item xs={6}>
                                                    {((this.props.searchByIntent !== undefined && this.props.searchByIntent !== '') 
                                                        || (this.context.adminIntents !== undefined && this.context.adminIntents.length>0 && this.state.intentIds!==undefined && this.state.intentIds.length>0)
                                                        || ( this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM") )
                                                        )
                                                        && ((content.utterance_class in utterFollowUpSets)
                                                            || (this.props.searchUtterancesFilter && this.props.searchUtterancesFilter.length > 0 && this.props.searchUtterancesFilter.includes(content.utterance_class))
                                                        )
                                                        && 
                                                        <>
                                                            <SettingContentArrayCheckbox
                                                                dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                                title={"Follow-up AI Intents"} 
                                                                content={content} 
                                                                params={['intents']}
                                                                noLabel={true}
                                                                sorting={true}
                                                                showIntentDetails={true}
                                                                searchTag={this.state.searchTag || ""}
                                                                selectOptions={this.state.intentIds}
                                                                selectOptionsText={this.state.intentIdsText}
                                                                selectOptionsGroups={this.state.intentIdsDict}
                                                                selectOptionsGroupsText={this.state.intentIdsDictText}
                                                                groupFilter={
                                                                    this.context.adminIntents === undefined 
                                                                        ?   ['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']
                                                                        :   undefined
                                                                }
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                            />                                       
                                                        </>
                                                    }
                                                </Grid>
                                                <Grid item xs={6}>
                                                { (true || (this.props.searchByIntent === undefined || this.props.searchByIntent === '' )
                                                    || ["utter_ask_awareness", "utter_ask_activity", "utter_ask_quiz", "utter_ask_mindset"].includes(content.utterance_class))
                                                    &&
                                                    <>
                                                        <SettingContentArrayCheckbox
                                                            dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                            title={"Related Quests"} 
                                                            content={content} 
                                                            labels={['Quests']}
                                                            noLabel={true}
                                                            selectAtLeastOne={true}
                                                            selectPreOpen={true}
                                                            params={['leadership']}
                                                            selectOptions={this.state.qualitiesValues}
                                                            selectOptionsText={this.state.qualitiesText}
                                                            selectOptionsGroups= { this.state.leadershipQualitiesGroups}
                                                            selectOptionsGroupsText= {this.state.leadershipQualitiesGroupsText}
                                                            updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                            />
                                                        <SettingContentArrayCheckbox
                                                            dbUpdateLink={this.props.firebase.agentUtter(content.utterance_class, itemId)}
                                                            title={"Fallback Programs"} 
                                                            content={content} 
                                                            labels={['Programs']}
                                                            noLabel={true}
                                                            selectAtLeastOne={true}
                                                            params={['category']}
                                                            selectOptions={this.state.ownedGroups}
                                                            selectOptionsText={this.state.ownedGroupsLabels}
                                                            updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                            />
                                                    </>
                                                }
                                                </Grid>
                                            </Grid>
                                            {(this.context?.roles?.includes("ADMIN") || this.context?.roles?.includes("ADMIN") )
                                            &&
                                                <p style={{color :'blue'}}>Unique ID value: {content.cid} </p>  
                                            }
                                            {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                            &&
                                                <>
                                                    Move to ... 
                                                    &nbsp;
                                                    <Select
                                                        value={this.state.moveItem!==undefined && this.state.moveItem!==""? this.state.moveItem : this.state.subKey}
                                                        onChange={(event) => {this.setState({moveItem : event.target.value})}}
                                                        name={'preselctor'}
                                                        >
                                                        {
                                                            (Object.keys(utterFollowUpSets))?.map((selector) => (
                                                                <MenuItem key={"selector_"+selector} value={selector}  
                                                                    selected={this.state.moveItem === selector}  >
                                                                    {selector}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                    &nbsp;
                                                    <Button onClick={() => HandleDuplicateItem(
                                                            this, 
                                                            this.state.moveItem!==undefined && this.state.moveItem!==""? this.state.moveItem : this.state.subKey, 
                                                            content,
                                                            true
                                                        )}>
                                                        Move to
                                                    </Button>
                                                </>
                                            }
                                            {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                            &&
                                                <>
                                                    &nbsp;
                                                    <Button onClick={ () => {UpdateAIQuestions(this, [content.cid], '',  this.state.subKey, true)}} 
                                                        style={{float:"right", fontSize:'small', backgroundColor:'green'}}
                                                        //disabled={this.state['loading_AI_'+key+subKey] ? true : false}
                                                        >
                                                        Add Intent as Default<AccountTreeIcon/>
                                                    </Button>
                                                </>
                                            }
                                            
                                        </>
                                        }
                                    </>
                                    
                                </div>
                            :   <div>Loading <strong>{content!== undefined && content.cid}</strong> </div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

}//end class

const WizardQuestionClass = class extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        var searchText = "";
        if(Array.isArray(this.props.searchTextArray)){
            searchText = this.props.searchTextArray[this.props.searchTextArray.length - 1];
            searchText = searchText.trim();
        }
        else{
            searchText = (this.props.searchTextArray?.trim() || "");
        }
        this.state = {
            loading: true,
            loadingText: 'loading questions',
            questionDBCatLevel: null,
            searchTag : "",
            newQuestionText: searchText,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        var filterPrograms = this.context.adminPrograms || undefined;
        if(false && filterPrograms?.length > 0){
            this.filterQualities(filterPrograms);
        }
        GetAdminLeadershipCategoriesArray(this);
        GetAdminLeadershipQualitiesArray(this);
        LoadIntentClasses(this);
        this.loadPrograms();
    }

    loadPrograms(){
        var programList = [];
        var programListNames = [];
        if(this.context.adminPrograms!== undefined && this.context.adminPrograms.length> 0){
            var adminGroups = this.context.adminPrograms;
            if(adminGroups.length > 10) {
                adminGroups = adminGroups.slice(0,9);
            }
            this.unsubscribeCat = this.props.firebase
            .leadershipCategories()
            .where('program', 'in', adminGroups)
            .get().then((data) => {
                data.forEach(result => {
                    var myGroupsDoc = result.data();
                    //console.log('myGroupsDoc', myGroupsDoc)
                    if(myGroupsDoc.program !== 'default' || (myGroupsDoc.program === 'default' && (this.context.adminPrograms.includes(myGroupsDoc.value) || this.context.adminPrograms.includes('default_all')) )){
                        if(!programList.includes(myGroupsDoc.value)){
                            programList.unshift(myGroupsDoc.value);
                        }
                        if(!programListNames.includes(myGroupsDoc.text)){
                            programListNames.unshift(myGroupsDoc.text);
                        }
                    }
                })
                if (this._isMounted) {
                    this.setState({
                        programList,
                        programListNames
                    });
                }
            })
        }
        else{
            programList= ['confidence', 'priority', 'people', 'clarity', 'communication', 'balance']
            programListNames= ['Positivity', 'Engagement', 'Relationships', 'Meaning', 'Accomplishment', 'Life-Balance']
            if (this._isMounted) {
                this.setState({
                    programList,
                    programListNames
                });
            }
        }
    }

    filterQualities(userAdminPrograms){
        //console.log('filterQualities', userAdminPrograms)
        if(userAdminPrograms?.includes('default_all') && !userAdminPrograms?.includes('default')){
            userAdminPrograms.push('default');
        }
        var myArray = [];
        var myArrayText = [];
        this.props.leadershipQualities?.map((item, index) => {
            var myPrograms = 'default'
            if(item.program !== undefined) {
                myPrograms= item.program
            }
            if(userAdminPrograms?.includes(myPrograms) && item.active){
                myArray.push(QualityID(item.valueID || item.value));
                myArrayText.push(item.text);
            };
            this.setState({
                leadershipQualitiesItems: myArray,
                leadershipQualitiesItemsText: myArrayText
            });
            return true
        })
    }

    oldloadIntents = () => {
        const sentiment = 'inform_neutral_short';
        console.log('finding error 1', sentiment)
        if(this._isMounted && sentiment!==undefined && sentiment!==""){
            this.setState({errorMessage : 'Loading intents list!'});
            //console.log('added: ', sentiment)
            if(this.state.intentList===undefined){
                //console.log('finding error 2')
                var intentList = {};
                var intentTree = {};
                this.props.firebase
                .agentIntentClasses()
                .where('active', '==', true)
                .where('sentiment', '==', sentiment)
                .where('tenses', 'array-contains', 'default')
                .orderBy('description', 'asc')
                .get().then(snapshot => {
                    //console.log('finding error 3')
                    snapshot.forEach(doc =>{
                        //console.log('finding error 4')
                        var dataRead = doc.data();
                        if(dataRead.intent !== undefined && dataRead.intent !== "" && dataRead.description !== undefined && dataRead.description !== ""){
                            //intentList.push({...doc.data(), id: doc.id})
                            if(dataRead.intent?.includes("inform_indicator_") && !dataRead.intent?.includes("allothers")){
                                var exampleResponse = dataRead.intent;
                                if(dataRead.responses !== undefined && dataRead.responses.length>0){
                                    exampleResponse = dataRead.responses[0];
                                }
                                //intentList.push({intent: dataRead.intent, description: dataRead.description, exampleResponse : exampleResponse, id: doc.id})
                                intentList[dataRead.intent] = ({
                                    intent: dataRead.intent, 
                                    description: dataRead.description, 
                                    exampleResponse : exampleResponse, 
                                    id: doc.id,
                                    qualities: dataRead.qualities
                                })
                                var intentBase = dataRead.intent.replace("inform_indicator_", "");
                                var intentItems = intentBase.split("_");
                                for (let i = 0; i < intentItems.length; i++) {
                                    if(i === 0 && intentTree[intentItems[0]] === undefined){
                                        intentTree[intentItems[0]] = {}
                                    }
                                    else if(i === 1 && intentTree[intentItems[0]][intentItems[1]] === undefined){
                                        intentTree[intentItems[0]][intentItems[1]] = {}
                                    }
                                    else if(i === 2 && intentTree[intentItems[0]][intentItems[1]][intentItems[2]] === undefined){
                                        intentTree[intentItems[0]][intentItems[1]][intentItems[2]] = {}
                                    }
                                    else if(i === 3 && intentTree[intentItems[0]][intentItems[1]][intentItems[2]][intentItems[3]] === undefined){
                                        intentTree[intentItems[0]][intentItems[1]][intentItems[2]][intentItems[3]] = {}
                                    }
                                }
                            }
                        }
                    })
                    if(this._isMounted && Object.keys(intentList)?.length>0){
                        //console.log('finding error 5')
                        console.log('added loadIntent: ', sentiment, intentList, intentTree)
                        this.setState({intentList : intentList, intentTree})
                    }
                })
            }
        }
    }

    addNewQuestionSet = async (newUtterance) => {
        //console.log('added new utterance', newUtterance)
        var newId = CreateObjectId();
        var utter = newUtterance?.utterance_class || undefined;
        var preIntent = newUtterance?.questionIntent || undefined;
        var baseQuality = newUtterance?.motherQuality || undefined;
        var program = undefined; 
        if(baseQuality) {
            program = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, baseQuality, 'program')
        }
        var mood = newUtterance?.sentiment || 'default';
        var intents = [];
        if(preIntent){
            intents = SelectIntentsList(preIntent, utter, mood);
        }
        var text = this.state.selectedText?.trim() || undefined;
        var template = undefined;
        if(utter && text && baseQuality){
            template = {
                text: text || 'Empty Question Template', 
                attachment: {
                    explanation: (text?.length > 0 ? '' : 'EXPLANATION OF QUESTION'), 
                    utterance_class: utter
                }
            };
        }
        if(utter && template && intents?.length > 0){
            let dictNew = {
                status: 'active',
                mongodb_id: newId,
                utterance_class: utter,
                intents: intents,
                agent: ['priming', 'reflection'],
                author: [this.context.email],
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                template: template,
                leadership: [],
                category: [],
                session: ['default'],
                persona:  ['default'],
                mindset_type: ['default'],
                input_channels: ['rocky', 'firebase'],
                day: ['default'],
                day_time: ['default'],
                sentiment: mood
            }
            //// add the requirements type
            if(newUtterance?.requirements?.length > 0){
                dictNew['requirements'] = newUtterance.requirements;
            }
            //// add the requirements type
            if(newUtterance?.session?.length > 0){
                dictNew['session'] = newUtterance.session;
            }
            //// add the leadership types
            if(newUtterance?.newQuestionTypeSpecific === true && UtterancesLeadQuestions.includes(utter) || newUtterance?.newQuestionTypeLead || newUtterance?.newQuestionTypeSpecific){
                dictNew['leadership'].push(baseQuality)
            }
            else if(newUtterance?.newQuestionTypeSpecific !== true){
                if (program === 'default' && (this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))){
                    dictNew['leadership'].push('asIntent')
                    dictNew['category'].push('asIntent')
                }
                else{
                    dictNew['leadership'] = [baseQuality];
                    if(newUtterance?.category?.length > 1){
                        dictNew['category'] = [newUtterance?.category];
                    }
                    //console.log('udpate cat', dictNew)
                }
            }
            else{
                var altPrograms = this.state.programList || [];
                altPrograms = altPrograms.filter((i) => !('default', 'default_all').includes(i));
                if(altPrograms?.length > 1){
                    dictNew['category'] = [altPrograms];
                }
                //console.log('udpate all cat', dictNew, altPrograms)
            }
            await this.props.firebase.agentUtters(utter).where('template.text', '==', 'Empty Question Template').where('mongodb_id', "!=", newId).limit(1).get()
                .then(snapshot => {
                    if (snapshot.empty) {
                        console.log('created extra id new')
                    }
                    snapshot.forEach(doc => {
                        newId = doc.id
                        dictNew['mongodb_id'] = newId
                        console.log('overwrite old ID extra id new', newId)
                    });
                    this.props.firebase.agentUtter(utter, newId).set(dictNew, { merge:true } );
                })
            //console.log('added statee dict new', dictNew)   
        }
    }

    addUpdateQuestionSet = async (newUtterance) => {
        //console.log('added update utterance', newUtterance)
        var oldId = this.state.questionUpdateId || undefined;
        var utter = newUtterance?.utterance_class || undefined;
        var oldUtter = this.state.questionUpdateUtter || undefined;
        if(oldId?.length > 4 && utter){
            var mood = newUtterance?.sentiment || 'default';
            var baseQuality = newUtterance?.motherQuality || undefined;
            var program = undefined; 
            if(baseQuality) {
                program = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, baseQuality, 'program')
            }
            var preIntent = newUtterance?.questionIntent || undefined;
            var session = newUtterance?.session || undefined;
            var intents = [];
            if(preIntent){
                intents = SelectIntentsList(preIntent, utter, mood);
            }
            let dictUpdate = {
                sentiment: mood,
                status: 'active'
            };
            if(intents?.length > 0){
                dictUpdate['intents'] = this.props.firebase.fieldValue.arrayUnion(...intents);
            }
            if(newUtterance?.requirements?.length > 0){
                dictUpdate['requirements'] = newUtterance.requirements;
            }
            if(session !== undefined){
                dictUpdate['session'] = session;
            }
            if(newUtterance?.newQuestionTypeSpecific === true && baseQuality && (UtterancesLeadQuestions.includes(utter) || newUtterance?.newQuestionTypeLead || newUtterance?.newQuestionTypeSpecific)){
                dictUpdate['leadership'] = this.props.firebase.fieldValue.arrayUnion(baseQuality);
                console.log('udpate baseQuality', baseQuality, dictUpdate)
            }
            else if(newUtterance?.newQuestionTypeSpecific !== true){
                if (program === 'default' && (this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))){
                    dictUpdate['leadership'] = this.props.firebase.fieldValue.arrayUnion('asIntent');
                    dictUpdate['category'] = this.props.firebase.fieldValue.arrayUnion('asIntent');
                    console.log('udpate default', dictUpdate)
                }
                else {
                    dictUpdate['leadership'] = this.props.firebase.fieldValue.arrayUnion(baseQuality);
                    if(newUtterance?.category?.length > 1){
                        dictUpdate['category'] = this.props.firebase.fieldValue.arrayUnion(newUtterance?.category);
                    }
                    console.log('udpate cat', dictUpdate)
                }
            }
            else{
                var altPrograms = this.state.programList || [];
                altPrograms = altPrograms.filter((i) => !('default', 'default_all').includes(i));
                if(altPrograms?.length > 1){
                    dictUpdate['category'] = this.props.firebase.fieldValue.arrayUnion(...altPrograms);
                }
                //console.log('udpate all cat', dictUpdate, altPrograms)
            }
            if(utter){
                if(oldUtter !== utter){
                    //console.log('change the system', dictUpdate)
                    if(oldUtter?.length > 7){
                        this.props.firebase.agentUtter(oldUtter, oldId).update(dictUpdate)
                        .then(() => {
                            this.props.firebase.agentUtter(oldUtter, oldId).get()
                            .then((doc) => {
                                var updateSet = doc.data();
                                //console.log('moving Update Set', updateSet)
                                if (updateSet) {
                                    HandleDuplicateItem(
                                        this, 
                                        utter, 
                                        updateSet,
                                        true
                                    )
                                    this.setState({openOverlapMatch: false}, () => {
                                        setTimeout(() => {
                                            if(this._isMounted){
                                                this.setState({openOverlapMatch: true});
                                            }
                                        }, 1400)
                                    });
                                }
                            })
                        })
                    }
                }
                else{
                    //console.log('update Set', dictUpdate)
                    this.props.firebase.agentUtter(utter, oldId).update(dictUpdate)
                    .then(() => {
                        this.setState({openOverlapMatch: true});
                    })
                }
            }
        }
    }

    render(){
        //console.log('render Wizard', this.props, this.state)
        var stage = "";
        if(this.state.newUtterance?.utterance_class !== undefined && this.state.questionAvailable === true && this.state.newUtterance?.selectedIntentPackage !== undefined){
            stage = "modify";
        }
        else if(this.state.newUtterance?.utterance_class !== undefined && this.state.newUtterance?.selectedIntentPackage !== undefined){
            stage = "utterance";
        }
        else if(this.state.newUtterance?.selectedIntentPackage 
            && this.state.availableUtterances?.length > 0
            && (this.state.newUtterance?.questionSentiment?.includes("question") || this.state.newUtterance?.questionSentiment?.includes("chat"))
        ){
            stage = "intent";
        }
        else if(this.state.timeoutSearch === false
            && this.state.userIntentResult !== undefined ){
            stage = "topic";
        }
        //console.log('stage', stage)
        return(
            <div style={{width: '100%'}}>
                <QuestionIntentsItemDialog
                    searchSentiment = {this.state.searchSentiment}
                    searchClass = {this.state.searchClass}
                    openDialogQIntents={this.state.dialogIntentsOpen || false}
                    closeDialog={(e) => {this.setState({dialogIntentsOpen : false })}}
                    leadershipCategories={this.props.leadershipCategories || []}
                    leadershipQualities={this.props.leadershipQualities || []}
                    leadershipCategoriesValues={this.state.leadershipCategoriesValues || []}
                    leadershipQualitiesValues={this.state.leadershipQualitiesValues || []}
                    leadershipCategoriesValuesText={this.state.leadershipCategoriesValuesText || []}
                    leadershipQualitiesValuesText={this.state.leadershipQualitiesValuesText || []}
                    leadershipQualitiesGroups= { this.state.leadershipQualitiesGroups}
                    leadershipQualitiesGroupsText= {this.state.leadershipQualitiesGroupsText}
                />
                {this.dialogueIntents()}
                {this.state.leadershipQualitiesValues?.length > 0
                    ?
                        <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                            <Grid item xs={12} style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginTop:20, padding: 20, backgroundColor: stage=== "" ? theme.palette.white : "transparent"}}>
                                <p style={{color: theme.palette.secondary.light}}>1. Add your coaching question:</p>
                                <TextField
                                    name="question"
                                    type="text"
                                    fullWidth
                                    placeholder="New coaching question"
                                    value={this.state.newQuestionText || ''}
                                    onChange={ (event) => {
                                        this.setState({newQuestionText : event.target.value});
                                        this.checkExistingQuestions(event.target.value);
                                    }}
                                    //fullWidth
                                    style={ {
                                        fontSize: '16px', 
                                        backgroundColor: 'white',
                                        fontSize: 16,
                                    } }
                                />
                                <Button
                                    onClick={() => this.checkExistingQuestions(this.state.newQuestionText || "")}
                                >
                                    Go
                                </Button>
                            </Grid>
                            {this.state.selectedText?.length > 5 &&
                                <>
                                    <Grid item xs={12} style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginTop:20, padding: 20}}>
                                        
                                        <p>Search: {this.state.selectedText || "n/a"}</p>
                                        {
                                            //<br/><br/>Intent: {this.state.userIntentResult?.intent?.name || "n/a"}<br/><br/>
                                        }
                                        {this.state.userIntentResult
                                        ?
                                            <>
                                                <p style={{color: theme.palette.secondary.light}}>2. Select the top level Sentiment and Intention Type:</p>
                                                <Grid container spacing={0} direction='row' alignItems="flex-start" style={{width: '100%'}}>
                                                    {[0,1,2].map(keyIndex => {
                                                        var sentimentName = this.state.userIntentResult?.intent_ranking?.[keyIndex]?.name;
                                                        var sentimentNameString = "" + sentimentName;
                                                        if(sentimentNameString === 'question'){
                                                            sentimentNameString = "Question"
                                                        }
                                                        else if(sentimentNameString === 'chat'){
                                                            sentimentNameString = "Request"
                                                        }
                                                        else if(sentimentNameString === 'inform_negative_long'){
                                                            sentimentNameString = "Negative Statement"
                                                        }
                                                        else if(sentimentNameString === 'inform_positive_long'){
                                                            sentimentNameString = "Accomplishment"
                                                        }
                                                        else if(sentimentNameString === 'inform_neutral_long'){
                                                            sentimentNameString = "General Conclusion or Statement"
                                                        }
                                                        else if(sentimentNameString === 'inform_intention_long'){
                                                            sentimentNameString = "Goal or Commitment"
                                                        }
                                                        else if(sentimentNameString === 'inform_negative_short'){
                                                            sentimentNameString = "Open Challenge or Issue"
                                                        }
                                                        else if(sentimentNameString === 'inform_positive_short'){
                                                            sentimentNameString = "Positive Statement"
                                                        }
                                                        else if(sentimentNameString === 'inform_neutral_short'){
                                                            sentimentNameString = "A Keyword"
                                                        }
                                                        else if(sentimentNameString === 'inform_intention_short'){
                                                            sentimentNameString = "Open Activity or Action"
                                                        }
                                                        if(keyIndex === 0){
                                                            return(
                                                                <Grid item xs={4} key={'index_0'} 
                                                                    style={{width: '100%', borderRadius: theme.borderRadius, padding: 20, backgroundColor: (this.state.newUtterance?.questionSentiment === this.state.userIntentResult?.intent?.name ? theme.palette.themeAlertGreen+"55" : 'transparent') }}
                                                                >
                                                                    1. <strong>{sentimentNameString}</strong>
                                                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                                                    &&
                                                                        <span> 
                                                                            &nbsp;- {this.renderPercentageValue(this.state.userIntentResult?.intent_ranking?.[0]?.confidence)} 
                                                                        </span>
                                                                    }
                                                                    <Checkbox
                                                                        //value={this.state.userIntentResult?.response_selector?.[item]}
                                                                        onClick={(event) => this.setState({newUtterance: {...this.state.newUtterance, questionSentiment: this.state.userIntentResult?.intent?.name }}) }
                                                                        checked={(this.state.newUtterance?.questionSentiment === (this.state.userIntentResult?.intent?.name || "xcvdbfe")) || false}
                                                                    />
                                                                    <br/>{DescriptionSentiment?.[this.state.userIntentResult?.intent?.name]}
                                                                </Grid>
                                                            )
                                                        }
                                                        else if(sentimentNameString !== "greet" && sentimentNameString !== "Greet"){
                                                            return(
                                                                <Grid item xs={4} key={'index_'+keyIndex} 
                                                                    style={{width: '100%', borderRadius: theme.borderRadius, padding: 20, backgroundColor: (this.state.newUtterance?.questionSentiment === this.state.userIntentResult?.intent_ranking?.[keyIndex]?.name ? theme.palette.themeAlertGreen+"55" : 'transparent') }}
                                                                >
                                                                    {(keyIndex+1)}. <strong>{sentimentNameString}</strong> 
                                                                    {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                                                    &&
                                                                        <span> 
                                                                            &nbsp;- {this.renderPercentageValue(this.state.userIntentResult?.intent_ranking?.[keyIndex]?.confidence)}
                                                                        </span>
                                                                    }
                                                                    <Checkbox
                                                                        onClick={(event) => this.setState({newUtterance: {...this.state.newUtterance, questionSentiment: this.state.userIntentResult?.intent_ranking?.[keyIndex]?.name }}) }
                                                                        checked={(this.state.newUtterance?.questionSentiment === (this.state.userIntentResult?.intent_ranking?.[keyIndex]?.name || "xcvdbfe")) || false}
                                                                    />
                                                                    <br/> {DescriptionSentiment?.[this.state.userIntentResult?.intent_ranking?.[keyIndex]?.name]}
                                                                </Grid>
                                                            )
                                                        }
                                                    })}
                                                </Grid>
                                            </>
                                        :   
                                            <LoadingProgress 
                                                defaultSet={this.props.defaultSet}
                                                type={'LoadingPageAnimated'} 
                                                loadingText={"checking machine learning"}
                                                allowRefresh={true}
                                            />
                                        }
                                    </Grid>
                                    {this.state.newUtterance?.questionSentiment?.length > 0 &&
                                        <Grid item xs={12} style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginTop:20, padding: 20, backgroundColor: stage=== "topic" ? theme.palette.white : "transparent"}}>
                                            <>
                                                <p style={{color: theme.palette.secondary.light}}>3. Select the most relevant Topic:</p>
                                                <Grid container spacing={0} direction='row' alignItems="flex-start" style={{width: '100%'}}>
                                                {[this.state.userIntentResult?.intent?.name , 
                                                    this.state.userIntentResult?.intent_ranking?.[1]?.confidence > 0.1 ? this.state.userIntentResult.intent_ranking[1].name : this.state.userIntentResult?.intent?.name === 'inform_intention_long' ? 'chat' : 'inform_intention_long', 
                                                    this.state.userIntentResult?.intent_ranking?.[2]?.confidence > 0.1 ? this.state.userIntentResult.intent_ranking[2].name : this.state.userIntentResult?.intent?.name === 'inform_neutral_long' ? 'question' : 'inform_neutral_long' 
                                                    ].map((item, indexItem) => (
                                                    <Grid item xs={4} key={'index_'+indexItem} style={{width: '100%'}}>
                                                        <Grid container spacing={0} direction='column' alignItems="flex-start" style={{width: '100%'}}>
                                                            {[0,1,2,3].map((round, indexRound) => {
                                                                var refIntent = this.state.userIntentResult?.response_selector?.[item]?.ranking?.[round] || undefined;
                                                                var refIntentName = refIntent?.intent_response_key || undefined;
                                                                if (refIntent ){
                                                                    var refIntentsKeys = refIntentName?.split("_");
                                                                    for (let i = 0; i < refIntentsKeys.length; i++) {
                                                                        refIntentsKeys[i] = refIntentsKeys[i][0].toUpperCase() + refIntentsKeys[i].substr(1);
                                                                    }
                                                                    return (
                                                                        <Grid item xs={3} key={indexItem+'_'+indexRound} 
                                                                            style={{maxWidth: '100%', width: '100%', borderRadius: theme.borderRadius, padding: 20, backgroundColor: (this.state.newUtterance?.selectedIntentPackage === refIntentName ? theme.palette.themeAlertGreen+"55" : 'transparent') }}
                                                                        >
                                                                            {TopicIntentsPrefixes.some( r => refIntentName?.includes(r)) 
                                                                                ?   refIntentsKeys?.slice(-2).join(" > ")
                                                                                :   "Other: " + refIntentsKeys?.slice(-1)
                                                                            } 
                                                                            {(this.context.roles?.includes('ADMIN') || this.context.roles?.includes('TEAM'))
                                                                            &&
                                                                                <span> 
                                                                                    &nbsp;- {this.renderPercentageValue(refIntent?.confidence)}
                                                                                </span>
                                                                            }
                                                                            <Checkbox
                                                                                onClick={ TopicIntentsPrefixes.some( r => refIntentName?.includes(r)) 
                                                                                    ?   (event) => this.updateSelectedFullIntent(refIntentName) 
                                                                                    :   (event) => this.updateSelectedFullIntent(refIntentName, false) 
                                                                                }
                                                                                checked={(this.state.newUtterance?.selectedIntentPackage === (refIntentName || "xcvdbfe")) || false}
                                                                            />
                                                                        </Grid>
                                                                    )
                                                                }
                                                                else return ""
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                </Grid>
                                                {this.state.newUtterance?.questionSentiment?.length > 0 &&
                                                    <Grid container spacing={0} direction='row' alignItems="flex-start" style={{width: '100%'}}>
                                                        <Grid item xs={4}>

                                                        </Grid>
                                                        <Grid item xs={4}
                                                            style={{maxWidth: '100%', width: '100%', borderRadius: theme.borderRadius, padding: 20, backgroundColor: (this.state.newUtterance?.selectedIntentPackage?.includes("inform_extra") ? theme.palette.themeAlertGreen+"55" : 'transparent') }}
                                                        >
                                                            {this.state.newUtterance?.selectedIntentPackage?.includes("inform_extra")
                                                                &&
                                                                <>
                                                                    {this.state.newUtterance?.selectedIntentPackage?.split("_")?.slice(-2).join(" > ")}
                                                                    <Checkbox
                                                                        checked = {true}
                                                                    />
                                                                </>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={4} style={{textAlign: 'right'}}>
                                                            <Button onClick={() => this.setState({openDialogueIntents: true})}>
                                                                Show Other Options
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </>
                                        </Grid>
                                    }
                                    <Grid item xs={this.state.newUtterance?.utterance_class !== undefined ? 3 : 12} style={{width: "100%"}}>
                                        {(stage === "intent" || stage === "utterance" || stage === "modify")
                                        &&
                                            <div style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginTop:20, marginRight: 0, padding: 20, backgroundColor: stage=== "intent" ? theme.palette.white : "transparent"}}>
                                                <p style={{color: theme.palette.secondary.light}}>4. Select intention and timing of question:</p>
                                                {this.state.availableUtterances?.map((itemUtter, indexUtter) => (
                                                    <div key={itemUtter}
                                                        style={{maxWidth: '100%', width: '100%', borderRadius: theme.borderRadius, padding: 20, backgroundColor: (this.state.newUtterance?.utterance_class === (itemUtter) ? theme.palette.themeAlertGreen+"55" : 'transparent') }}
                                                    >
                                                        <strong>
                                                            {UtterancesDescriptions?.[itemUtter]?.type || "Questions"} &gt; <strong style={{color: 'green'}}>{UtterancesDescriptions?.[itemUtter]?.name || itemUtter}</strong>
                                                        </strong>
                                                        <Checkbox
                                                            onClick={ () =>{
                                                                if(UtterancesLeadQuestions.includes(itemUtter)){
                                                                    this.setState({newUtterance: {...this.state.newUtterance, utterance_class: itemUtter, newQuestionTypeLead: true}});
                                                                }
                                                                else{
                                                                    this.setState({newUtterance: {...this.state.newUtterance, utterance_class: itemUtter, newQuestionTypeLead: false}});
                                                                }
                                                            }}
                                                            checked={this.state.newUtterance?.utterance_class === (itemUtter) || false}
                                                        />
                                                        <br/> {UtterancesDescriptions?.[itemUtter]?.description || ""}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={this.state.newUtterance?.utterance_class !== undefined ? 9 : false}>
                                        {this.state.newUtterance?.utterance_class !== undefined
                                            &&
                                            <>
                                            <Grid container spacing={0} style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginTop: 20, marginLeft: 20, padding: 20, width: "calc( 100% - 20px )", backgroundColor: !(this.state.newUtterance?.motherQuality?.length > 0) ? theme.palette.white : "transparent"}}>
                                                <Grid item xs={12}>
                                                    <p style={{color: theme.palette.secondary.light}}>5. Select question parameters and filters:</p>
                                                </Grid>
                                                {(this.state.newUtterance?.questionSentiment?.includes("question") || this.state.newUtterance?.questionSentiment?.includes("chat"))
                                                    ?
                                                        <>
                                                            <Grid item xs={4}>
                                                                Response Question
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Switch
                                                                    uncheckedIcon={true}
                                                                    checked={this.state.newUtterance?.newQuestionTypeLead || false}
                                                                    onChange={(c, event, id) => {
                                                                        if(c){
                                                                            this.setState({newUtterance: {...this.state.newUtterance, newQuestionTypeLead: true}});
                                                                        }
                                                                        else{
                                                                            this.setState({newUtterance: {...this.state.newUtterance, newQuestionTypeLead: false}});
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Lead Question
                                                            </Grid>
                                                        </>
                                                    :
                                                        <>
                                                            Training Data
                                                        </>
                                                } 
                                                {(this.state.newUtterance?.newQuestionTypeLead === true || !(this.context.adminContent?.includes('default') || this.context.adminContent?.includes('default_all')))
                                                    ?
                                                        <>
                                                            <Grid item xs={12}>
                                                                <SettingContentSelectCombi
                                                                    title={"Choose a Quest"} 
                                                                    content={this.state.newUtterance || {}}
                                                                    labels={["Choose the most relevant Quest"]} 
                                                                    params={['motherQuality']}
                                                                    selectOptions={this.state.leadershipQualitiesValues || []}
                                                                    selectOptionsText={this.state.leadershipQualitiesValuesText || []}
                                                                    selectOptionsFilter={this.state.programList || []}
                                                                    selectOptionsFilterText={this.state.programListNames || []}
                                                                    leadershipQualities={this.props.leadershipQualities}
                                                                    leadershipCategories={this.props.leadershipCategories}
                                                                    updateState={dict => this.setState({newUtterance: {...this.state.newUtterance, ...dict}})}
                                                                />
                                                                <br/>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Allow connection to other Quests in Program
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Switch
                                                                    uncheckedIcon={true}
                                                                    checked={this.state.newUtterance?.newQuestionTypeSpecific || false}
                                                                    onChange={(c, event, id) => {
                                                                        if(c){
                                                                            this.setState({newUtterance: {...this.state.newUtterance, newQuestionTypeSpecific: true}});
                                                                        }
                                                                        else{
                                                                            this.setState({newUtterance: {...this.state.newUtterance, newQuestionTypeSpecific: false}});
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Allow only the original Quest
                                                            </Grid>
                                                        </>
                                                    :
                                                        <>
                                                            <Grid item xs={4}>
                                                                Response to ANY Quest
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Switch
                                                                    uncheckedIcon={true}
                                                                    checked={this.state.newUtterance?.newQuestionTypeSpecific || false}
                                                                    onChange={(c, event, id) => {
                                                                        if(c){
                                                                            this.setState({newUtterance: {...this.state.newUtterance, newQuestionTypeSpecific: true}});
                                                                        }
                                                                        else{
                                                                            this.setState({newUtterance: {...this.state.newUtterance, newQuestionTypeSpecific: false}});
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Specific Quest ONLY
                                                            </Grid>
                                                        </>
                                                } 
                                                {this.state.newUtterance?.newQuestionTypeSpecific === true && this.state.newUtterance?.newQuestionTypeLead !== true
                                                    &&
                                                        <Grid item xs={12}>
                                                            <SettingContentSelectCombi
                                                                title={"Choose a Quest"} 
                                                                content={this.state.newUtterance || {}}
                                                                labels={["Specific Quest selection"]} 
                                                                params={['motherQuality']}
                                                                selectOptions={this.state.leadershipQualitiesValues || []}
                                                                selectOptionsText={this.state.leadershipQualitiesValuesText || []}
                                                                selectOptionsFilter={this.state.programList || []}
                                                                selectOptionsFilterText={this.state.programListNames || []}
                                                                leadershipQualities={this.props.leadershipQualities}
                                                                leadershipCategories={this.props.leadershipCategories}
                                                                updateState={dict => this.setState({newUtterance: {...this.state.newUtterance, ...dict}})}
                                                            />
                                                            <br/>
                                                        </Grid>
                                                }
                                                {(this.context.adminContent?.includes('default') || this.context.adminContent?.includes('default_all') || this.state.newUtterance?.motherQuality?.length > 0)
                                                &&
                                                    <>
                                                        <Grid item xs={12}>
                                                            {this.state.newUtterance?.utterance_class !== undefined
                                                            &&
                                                                <SettingContentSelect
                                                                    title={"Optional: User's Mood (negative, neutral, positive)"} 
                                                                    content={this.state.newUtterance || {}}
                                                                    params={['sentiment']}
                                                                    noLabel={true}
                                                                    emptyStage={'Any mood'}
                                                                    showButtons={true}
                                                                    selectOptionsText={['negative - the mood is low', 'neutral - can be always asked', 'positive - the mood is good' ]}
                                                                    selectOptions={['negative', 'default', 'positive']}
                                                                    updateState={dict => this.setState({newUtterance: {...this.state.newUtterance, ...dict}})}
                                                                />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.state.newUtterance?.utterance_class !== undefined
                                                            &&
                                                                <>
                                                                    <SettingContentArrayCheckbox
                                                                        title={"Optional: User Life-stage"} 
                                                                        content={this.state.newUtterance || {}}
                                                                        params={['requirements']}
                                                                        noLabel={true}
                                                                        emptyStage={"Every Life-Stage"}
                                                                        showLifeStageButtons={true}
                                                                        selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                                                        selectOptionsText={['Question for every life-stage', 'Executive/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'Teenager / K-12' ]}
                                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                        updateState={dict => this.setState({newUtterance: {...this.state.newUtterance, ...dict}})}
                                                                    />
                                                                </>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.state.newUtterance?.utterance_class !== undefined
                                                            &&
                                                                <> 
                                                                    <SettingContentArrayCheckbox
                                                                        title={"Optional: User Chat Session Level"} 
                                                                        content={this.state.newUtterance || {}}
                                                                        params={['session']}
                                                                        noLabel={true}
                                                                        showSessionLevelButtons={true}
                                                                        emptyStage={"Every chat, from first to advanced"}
                                                                        selectOptions={['default', 'first', 'basic', 'intermediate', 'advanced']}
                                                                        selectOptionsText={['Any (from first to advanced chats)', 'First Chats', 'Beginner Chats', 'Intermediate Chats', 'Advanced Chats']}
                                                                        updateState={dict => this.setState({newUtterance: {...this.state.newUtterance, ...dict}})}
                                                                    />
                                                                </>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} style={{marginTop: 40, textAlign: 'right'}}>
                                                            {this.state.newUtterance?.utterance_class !== undefined
                                                                &&  
                                                                <>
                                                                    {this.state.questionAvailable === true 
                                                                        ?
                                                                            <Button onClick={() => this.addUpdateQuestionSet(this.state.newUtterance || {})}>
                                                                                Modify
                                                                            </Button>
                                                                        :   
                                                                            <Button onClick={() => this.addNewQuestionSet(this.state.newUtterance || {})}>
                                                                                Save
                                                                            </Button>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                            {this.state.newUtterance?.motherQuality?.length > 0 && this.state.newUtterance?.utterance_class !== undefined
                                                &&  
                                                <Grid container spacing={0} style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginTop: 20, marginLeft: 20, padding: 20, width: "calc( 100% - 20px )", backgroundColor: (stage === "modify" || (this.state.newUtterance?.motherQuality?.length > 0)) ? theme.palette.white : "transparent"}}>
                                                    <Grid item xs={12}>
                                                        <p style={{color: theme.palette.secondary.light}}>6. Save, adjust or add next question:</p>
                                                    </Grid>
                                                    <Grid item xs={12} style={{marginTop: 40, textAlign: 'right'}}>
                                                        {this.state.questionAvailable === true 
                                                            ?
                                                                <Button onClick={() => this.setState({newUtterance: undefined, newQuestionText: "", selectedText: "", questionAvailable: false, userIntentResult: undefined })}>
                                                                    Add New
                                                                </Button>
                                                            :   
                                                                <Button onClick={() => this.addNewQuestionSet(this.state.newUtterance || {})}>
                                                                    Save
                                                                </Button>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} style={{marginTop: 40, textAlign: 'right'}}>
                                                        {this.state.questionAvailable === true &&
                                                            "This question is (now) available. Do you want to add a new item?"
                                                        }
                                                    </Grid> 
                                                </Grid>
                                            }
                                            </>
                                        }
                                    </Grid>
                                </>
                            }
                            {(this.state.newUtterance?.questionSentiment?.includes("question") || this.state.newUtterance?.questionSentiment?.includes("chat"))
                                &&
                                    <Grid item xs={12} style={{borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginTop:20, marginRight: 0, padding: 20 }}>
                                        <Grid item xs={12}>
                                            <p style={{color: theme.palette.secondary.light}}>Reference questions and samples:</p>
                                        </Grid>
                                        <MongoUtterances 
                                            searchTextArray={this.state.selectedText || ""}
                                            showVague={false} 
                                            showMore={true}
                                            searchOption={false}
                                            textOnly={true}
                                            tableView={true}
                                            searchByText={true}
                                            loadQualities={false}
                                            resultUtters={(utters) => {
                                                //console.log('utters2', utters); 
                                                this.setState({availableUtterances : [...new Set(utters.concat(this.state.availableUtterances || []))] })
                                            }}
                                            resultOverlapMatch={(questionAvailable, questionUpdateId, questionUpdateUtter) => {
                                                this.setState({questionAvailable, questionUpdateId, questionUpdateUtter})
                                            }}
                                            openOverlapMatch={this.state.openOverlapMatch || false}
                                            closeOverlapMatch={() => this.setState({openOverlapMatch: false})}
                                            leadershipCategories={this.props.leadershipCategories}
                                            leadershipQualities={this.props.leadershipQualities}
                                            leadershipCategoriesValues={this.state.leadershipCategoriesValues || []}
                                            leadershipCategoriesValuesText={this.state.leadershipCategoriesValuesText || []}
                                            leadershipQualitiesValues={this.state.leadershipQualitiesValues || []}
                                            leadershipQualitiesValuesText={this.state.leadershipQualitiesValuesText || []}
                                            leadershipQualitiesGroups= { this.state.leadershipQualitiesGroups}
                                            leadershipQualitiesGroupsText= {this.state.leadershipQualitiesGroupsText}
                                            intentIds={this.state.intentIds}
                                            intentIdsText={this.state.intentIdsText}
                                            intentIdsDict={this.state.intentIdsDict}
                                            intentIdsDictText={this.state.intentIdsDictText}
                                            referenceUtters={UtterancesMainQuestions}
                                            searchAutoUpdateFlag={true}
                                        />
                                        <MongoUtterances 
                                            searchTextArray={this.state.selectedText || ""}
                                            showVague={true} 
                                            searchOption={false}
                                            textOnly={true}
                                            tableView={true}
                                            searchByText={true}
                                            loadQualities={false}
                                            resultUtters={(utters) => {
                                                //console.log('utters1', utters); 
                                                this.setState({availableUtterances : [...new Set(utters.concat(this.state.availableUtterances || []))] })
                                            }}
                                            leadershipCategories={this.props.leadershipCategories}
                                            leadershipQualities={this.props.leadershipQualities}
                                            leadershipCategoriesValues={this.state.leadershipCategoriesValues || []}
                                            leadershipCategoriesValuesText={this.state.leadershipCategoriesValuesText || []}
                                            leadershipQualitiesValues={this.state.leadershipQualitiesValues || []}
                                            leadershipQualitiesValuesText={this.state.leadershipQualitiesValuesText || []}
                                            leadershipQualitiesGroups= { this.state.leadershipQualitiesGroups}
                                            leadershipQualitiesGroupsText= {this.state.leadershipQualitiesGroupsText}
                                            intentIds={this.state.intentIds}
                                            intentIdsText={this.state.intentIdsText}
                                            intentIdsDict={this.state.intentIdsDict}
                                            intentIdsDictText={this.state.intentIdsDictText}
                                            referenceUtters={UtterancesMainQuestions}
                                            searchAutoUpdateFlag={true}
                                        />
                                    </Grid>
                            }
                            <Grid item xs={12} style={{padding: 40}}>
                                <h4>Coaching Question Guideline</h4>
                                <li>
                                    Open-ended: Avoid yes/no questions. Opt for "what," "how," "when," and "why" questions that encourage exploration and deeper reflection. Ex: "What are some challenges you see in achieving your goal?" vs. "Do you think this is achievable?"
                                </li>
                                <li>
                                    Client-centered: Focus on the client's perspective and experiences, not your assumptions or biases. Use phrases like "tell me more" and "can you elaborate?" to encourage them to share their story and insights.
                                </li>
                                <li>
                                    Future-oriented: While acknowledging the past, steer the conversation towards the future and potential solutions. Ask questions like "What would you like to achieve?" and "What steps can you take to move forward?"
                                </li>
                                <li>
                                    Powerful prompts: Employ powerful prompts like "What would be different if...?" or "Imagine yourself achieving this goal, what does it look like?" to spark new perspectives and possibilities.
                                </li>
                                <li>
                                    Specific and focused: Avoid overly broad questions. Instead, tailor your questions to the specific topic at hand and the client's current development stage. Think of your coaching modules.
                                </li>
                                <li>
                                    Reflective and insightful: Ask questions that encourage self-reflection and deeper understanding. Use phrases like "What are your thoughts on...?"
                                </li>
                                <li>
                                    Non-judgmental and neutral: Maintain a neutral and objective stance in your questions. Avoid leading questions or phrases that imply judgment or pre-determined answers.
                                </li>
                            </Grid>
                        </Grid>
                    :   this.state.loading
                        ?
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"loading"}
                                allowRefresh={true}
                            />
                        :
                            <span style={theme.textSupport}>Please create your own 'Programs & Quests' or obtain administrative rights for existing ones.</span>
                }
            </div>
        )
    }

    renderPercentageValue(percentage){
        if(percentage){
            var newPercentage = parseFloat((Number(percentage)*100)).toFixed(0);
            if(newPercentage > 20){
                return(
                    <strong>
                        { newPercentage + "%"}
                    </strong>
                )
            }
            else{
                return(
                    <span>
                        { newPercentage + "%"}
                    </span>
                )
            }
        }
    }

    dialogueIntents = () => {
        const {theme} = this.props;
        return(
            <RemoveScroll enabled={this.state.openDialogueIntents || false}>
                <Dialog
                    key={'dialogueutterance'}
                    open={this.state.openDialogueIntents|| false}
                    //fullScreen={true}
                    onClose={() => this.setState({openDialogueIntents: false})}
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={() => this.setState({openDialogueIntents: false})}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Select Intent
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.state.openDialogueIntents &&
                            <div>
                                <p>Search: {this.state.selectedText || "n/a"}</p>
                                <p style={{color: theme.palette.secondary.light}}>Click through the Topic-Tree to find a Topic:  </p>
                                {false && this.state.intentList !== undefined  &&
                                    <Select
                                        value={""}
                                        onChange={(e) => {
                                            try{
                                                console.log("change event first", e)
                                                if(e?.target?.value !== ""){
                                                    this.updateSelectedFullIntent("inform_extra/"+(e?.target?.value), true);
                                                    this.setState({openDialogueIntents: false});
                                                }
                                            }
                                            catch (err) {
                                                console.log('change error', err)
                                            }
                                        }}
                                        name='class_option'
                                    >
                                        <MenuItem key={"0000000"} id={"0000000"} value={""} >
                                            Please select
                                        </MenuItem>
                                        {
                                        Object.keys(this.state.intentList)?.map( (intentKey, index) => {
                                            var intent = this.state?.intentList[intentKey];
                                            var exampleText = intent.intent;
                                            if(intent?.intent?.includes("inform_indicator_")){
                                                exampleText = intent.exampleResponse;
                                                if(exampleText.length > 30){
                                                    exampleText = exampleText.substring(0,30) + "..."
                                                }
                                                return (
                                                    <MenuItem key={index+"_add_"+intent.id} id={index} value={intent.intent} 
                                                        selected={intent.intent === this.state.selectedIntent} style={{backgroundColor: intent.intent === this.state.selectedIntent ? 'lightgreen' : this.props.nluIntent !== undefined && intent.intent === this.props.nluIntent.intent ? 'lightblue' : 'white'}}
                                                        >
                                                        <strong>{intent.description}</strong> {" - Response: '" + exampleText + "'"}
                                                    </MenuItem>
                                                );
                                            }
                                            else return ""
                                        })}
                                    </Select>
                                }
                                <div id={'bubbleChartDiv'} 
                                    style={{
                                        'textAlign': 'center', 
                                        width: '100%', 
                                        height: '100vh'
                                        }} 
                                    ref={this.refBubble}
                                >
                                    {this.state.intentTree !== undefined
                                        ?
                                            <>
                                                <Grid container spacing={1} direction='row' alignItems="center" style={{width:"100%"}}>
                                                    {this.state.intentTree && (["", ...(this.state.intentTreePath || [])])?.map((mainKey, indexMain) => {
                                                        var subList = ( Object.keys(this.state.intentTree) || []);
                                                        var intentTreePath = this.state.intentTreePath || [];
                                                        if(indexMain > 0){
                                                            var subDict = this.state.intentTree;
                                                            for(let i = 0; i < (indexMain); i++){
                                                                subDict = subDict?.[intentTreePath[i]]
                                                            }
                                                            subList = Object.keys(subDict || []);
                                                        }
                                                        return (
                                                            <React.Fragment key={mainKey+"_"+indexMain}>
                                                                {indexMain >= 0 &&
                                                                    <Grid item xs={12} key={"header"+indexMain}>
                                                                        Topic Level {indexMain+1}
                                                                    </Grid>
                                                                }
                                                                { subList?.map((treeKey, indexKey) => {
                                                                    var itemName = "" + treeKey;
                                                                    itemName = itemName?.charAt(0)?.toUpperCase() + itemName?.slice(1);
                                                                    var treePath = this.state.intentTreePath;
                                                                    if(treePath === undefined){
                                                                        treePath = [];
                                                                    } 
                                                                    var newtreePath = [];
                                                                    //console.log('treePath', treePath)
                                                                    if (treePath?.includes(treeKey)){
                                                                        //console.log('treepop ',treeKey,  newtreePath, treePath)
                                                                        return(
                                                                            <Grid item xs={3} key={treeKey+"_"+indexKey}>
                                                                                <Card 
                                                                                    style={{backgroundColor: "gold" }}
                                                                                >
                                                                                    {itemName}
                                                                                </Card>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                    else{
                                                                        //console.log('treePath',treeKey,  newtreePath, treePath)
                                                                        return(
                                                                            <Grid item xs={3} key={treeKey+"_"+indexKey}>
                                                                                <Card onClick={() => {
                                                                                    var newPath = [...treePath];
                                                                                    newPath[indexMain] = treeKey;
                                                                                    newPath = newPath.slice(0, indexMain+1);
                                                                                    this.setState({intentTreePath: newPath})
                                                                                }}
                                                                                    style={{backgroundColor: "transparent" }}
                                                                                >
                                                                                    {itemName}
                                                                                </Card>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                }) }
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Grid>
                                                {this.state.intentTreePath?.length>0
                                                    && <p style={{color: theme.palette.secondary.light, marginTop:20, textAlign: 'left'}}>Select most relevant Topic:  </p>
                                                }
                                                <Grid container spacing={2} direction='column' alignItems="center" style={{width:"100%"}}>
                                                    {([...(this.state.intentTreePath || [])])?.map((mainKey, indexRound) => {
                                                        var intentName = "inform_indicator"
                                                        for(let i = 0; i < (indexRound + 1); i++){
                                                            intentName = intentName + "_" + this.state.intentTreePath?.[i]
                                                        }
                                                        var refIntentName = "inform_extra/"+intentName;
                                                        var refIntentsKeys = intentName?.split("_");
                                                        for (let i = 0; i < refIntentsKeys.length; i++) {
                                                            refIntentsKeys[i] = refIntentsKeys[i][0].toUpperCase() + refIntentsKeys[i].substr(1);
                                                        }
                                                        return (
                                                            <>
                                                                <Grid item xs={3} key={'Extra_'+indexRound} 
                                                                    style={{maxWidth: '100%', width: '100%', borderRadius: theme.borderRadius, padding: 20, marginTop: 20, backgroundColor: (this.state.newUtterance?.selectedIntentPackage === refIntentName ? theme.palette.themeAlertGreen+"55" : 'lightgrey') }}
                                                                >
                                                                    {TopicIntentsPrefixes.some( r => refIntentName?.includes(r)) 
                                                                        ?   refIntentsKeys?.slice(-2).join(" > ")
                                                                        :   "Other: " + refIntentsKeys?.slice(-1)
                                                                    }
                                                                    <Checkbox
                                                                        onClick={(event) =>
                                                                            { 
                                                                                if(TopicIntentsPrefixes.some( r => refIntentName?.includes(r)) ){
                                                                                    this.updateSelectedFullIntent(refIntentName) 
                                                                                }
                                                                                else {
                                                                                    this.updateSelectedFullIntent(refIntentName, false)
                                                                                }
                                                                                this.setState({openDialogueIntents: false});
                                                                            }
                                                                        }
                                                                        checked={(this.state.newUtterance?.selectedIntentPackage === (refIntentName || "xcvdbfe")) || false}
                                                                    />
                                                                    <br/>
                                                                    {this.state?.intentList?.[intentName]?.exampleResponse}
                                                                </Grid>
                                                            
                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </>
                                        :
                                            <LoadingProgress 
                                                defaultSet={this.props.defaultSet}
                                                type={'LoadingPageAnimated'} 
                                                loadingText={"many, many, data"} 
                                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                                allowRefresh={true}
                                            />
                                    }
                                </div>
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

    async updateSelectedFullIntent(fullintent, overwrite=true){
        var intentPackage = fullintent.split("/");
        var newSentiment = intentPackage?.[0];
        var newIntent = intentPackage?.[1];
        var sentiment = this.state.newUtterance?.questionSentiment;
        if(sentiment !== newSentiment){
            TopicIntentsPrefixes?.map(intent => {
                newIntent = newIntent.replace(intent, SentimentTopicIntentsPrefixes[sentiment]);
            })
            //console.log('replaced', newIntent)
        }
        var updatePackage = {
            selectedIntentPackage: fullintent, 
            questionIntent: newIntent
        }
        const altIntent = SentimentPrefixChanger('inform_indicator', newIntent);
        //console.log('updatePackage', updatePackage, altIntent, this.state.intentList, this.state.intentList?.[altIntent])
        if(this.state.intentList?.[altIntent]?.qualities?.length > 0){
            var newMotherQuality = this.state.intentList[altIntent].qualities[0];
            if(this.state.leadershipQualitiesValues?.includes(newMotherQuality)){
                updatePackage['motherQuality'] = newMotherQuality;
                //console.log('updatePackage after', updatePackage)
            }
            else{
               //console.log('this.state.qualitiesValues not includes', newMotherQuality, this.state.leadershipQualitiesValues) 
            }
        }
        this.setState({newUtterance: {...this.state.newUtterance, ...updatePackage}});
        if(overwrite){
            await this.updateSelectedIntent(sentiment, newIntent || undefined);
        }
    }

    async updateSelectedIntent (sentiment, intent){
        //console.log("selected inteent", sentiment, intent)
        if(intent && sentiment){
            AddExampletoNLUTraining(this, this.state.selectedText, sentiment, intent).then( qualities => {
                //console.log('qualities from add', qualities)
                if(false && qualities?.length > 0 && qualities[0]?.length > 2){
                    this.setState({newUtterance : {...this.state.newUtterance, motherQuality: (qualities[0] || undefined ) } });
                }
            })
            false && GetAlternativeRewriteArrayRockyAI("It is clear that, ", 3, this.state.selectedText, true).then(rewrites => {
                this.setState({inputRewrites: rewrites})
            })
        }
    }

    async checkExistingQuestions(text){
        if(this.state.timeoutSearch !== true){
            console.log('TIS', text)
            if(text?.length > 5 && this.state.selectedText !== text){
                console.log('new text', text)
                if(this.state.timeoutSearch !== true){
                    setTimeout(() => {
                        if(this._isMounted){
                            this.setState({timeoutSearch: false});
                            //console.log('search timer finished 3sec', text)
                            if(this.state.newQuestionText !== text){
                                this.checkExistingQuestions(this.state.newQuestionText);
                            }
                        }
                    }, 5000, text)
                }
                if(this._isMounted){
                    this.setState({
                        timeoutSearch: true, 
                        userIntentResult: undefined,
                        newUtterance: undefined,
                        selectedText: text
                    });
                }
                setTimeout(() => {
                    if(this._isMounted){
                        text = this.state.newQuestionText;
                        chatAgent.sendMessage({ 'text': text }, 'nluAnalysis').then(userIntentResult => {
                            if(userIntentResult !== undefined && userIntentResult.intent !== undefined){
                                //console.log('Search Result Then: ', userIntentResult?.intent?.name, userIntentResult)
                                this.setState({userIntentResult, newUtterance : { questionSentiment: (userIntentResult?.intent?.name || undefined )}});
                            }
                        })
                    }
                }, 2000)
            }
        }
    }
}

export async function GetNewSentencesRockyAI(newText, prompt = ''){
    let inputTexts = [newText];
    ////phase 1 questioningAI
    var qAIMessage = undefined;
    //var qAIMessage = await chatAgent.sendMessage({ "sender": 'universal', "story": newText }, 'questioningAI', undefined);
    //console.log('qAIMessage', qAIMessage, newText)
    var qAIExtra = '';
    if(qAIMessage?.output?.questions?.[0]?.markdown !== undefined){
        qAIExtra = qAIMessage?.output?.questions?.[0]?.markdown.replaceAll('**', "'").trim();
    }
    ////phase 2 wordai
    var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_sentences'
    let data = {
        'input': newText,
        'rewrite_num' : 6,
        'uniqueness': 3,
        'level' : 4,
    }
    if(prompt?.length > 10){
        data['phrase'] = "open";
        data['input'] = prompt;
        inputTexts = [prompt];
    }
    var inputTextChain = ''
    var counter = 0;
    inputTexts?.map( item => {
        if( inputTextChain === ''){
            inputTextChain = item
        }
        else{
            inputTextChain = inputTextChain + '\n' + item
        }
        counter += 1;
        return true;
    })
    if(counter > 30) data['rewrite_num'] = 1;
    else if(counter > 20) data['rewrite_num'] = 3;
    if(counter > 0 && counter < 60){
        data['input'] = inputTextChain;
        let formData = {
            method: 'post',
            body: JSON.stringify(data)
        }
        //console.log('input', formData)
        var response = await requestAPI(url, formData);
        let result = await response.json();
        if (result.length === 0){
            if(qAIExtra!== ''){
                result = [newText].push(qAIExtra);
                return result;
            }
            else return [newText];
        }
        else{
            console.log('result', result)
            if(qAIExtra!== ''){
                result.push(qAIExtra);
                return result;
            }
            else return result;
        }
    }
    else if(qAIExtra!== ''){
        return [newText].push(qAIExtra);
    }
    else return [newText]
}

export async function GetNewExplanationRockyAI(newText){
    ////phase 1 openai
    var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_airesponse'
    let data = {
        'input': newText,
        'quality': 1
    }
    let formData = {
        method: 'post',
        body: JSON.stringify(data)
    }
    //console.log('input', formData)
    var response = await requestAPI(url, formData);
    //console.log('response', response)
    let result = await response.json();
    if (result.length === 0){
        return ''
    }
    else{
        console.log('result', result)
        return result.join(" ");
    }
}

export async function GetAlternativeRewriteArrayRockyAI(newText, rewrite_num=3, phrase='', internal = false){
    ////phase 1 openai
    var url = "https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_sentences";
    if(internal){
        url = "https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_paraphraseinternal";
    }
    let data = {
        'input': newText,
        'rewrite_num': rewrite_num,
    }
    if(phrase.length>3){
        data['phrase'] = phrase;
    }
    let formData = {
        method: 'post',
        body: JSON.stringify(data)
    }
    //console.log('input', formData)
    var response = await requestAPI(url, formData);
    //console.log('response', response)
    let result = await response.json();
    if (result.length === 0){
        return []
    }
    else{
        console.log('result', result)
        return result
    }
}

function requestAPI(url, options) {
    //console.log('options', options)
    //console.log(options)
    return fetch(url, {
        mode: 'cors',
        headers: {
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Headers': "*",
            'Content-Type': 'application/json',
        },
        ...options
    });
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(ManageQuestions);

//export const WizardQuestion = withFirebase(WizardQuestionClass);
export const WizardQuestion = compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(WizardQuestionClass);