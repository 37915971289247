import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';

import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
//import { Link } from "react-router-dom";
import { SetAgentStatus } from '../Routine/SetAgentStatus';
import BadgeCard from '../Modules/BadgeCard';

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

import DialogTutorialPage from '../Onboarding/DialogTutorialPage';

import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import {SetStateLoadingBatches} from '../Charts/LoadingBatches';

import logo from "../../images/rocky-logo-square.png";
import logoWhatsNext from "../../images/Badge_XP_whatsnext.png";
import logoHotline from "../../images/Badge_hotline.png";

import AvatarImage from '../Modules/AvatarImage';
//import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';


//import ListItemText from '@material-ui/core/ListItemText';


// added plus for choosing daily topic
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});


class TodayCardsDesktopCoach extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            disabled: false,
            text: "Default Message",
            title: "Rocky.AI",
            insights: [],
            todaysLeadershipQuality: "",
            scope: 'todays',
            primingStatus: 'START',
            reflectionStatus: 'START',
        };
    }

    componentDidMount() {
        this.setState(SetAgentStatus(this.props.user));
        this.getLatestBatch();
    }

    handleStartRoutine = () => {
        this.setState({ disabled: true });
        this.props.history.push(ROUTES.ROUTINE);
    }

    getLatestBatch(){
        SetStateLoadingBatches(this, this.props.firebase, this.context.uid, 
            undefined, 10)
    }

    getLatestBatchOld() {
        var foundBatch = false;
        this.props.firebase.journeyBadges(this.context.uid)
        .where('active', '==', true)
        .where('completed', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(10)
        .get()
        .then((snapshot) => {
        if (snapshot.size) {
            snapshot.forEach(doc => {
                //console.log(doc.data())
                if (!foundBatch){
                    var badgeName = doc.data().description;
                    var badgeIcon = doc.data().iconUrl;
                    if(badgeIcon !== undefined && badgeIcon !== ''){
                    this.setState({badgeName, badgeIcon, badge: doc.data()})
                    if(doc.data().category !== 'total') {foundBatch = true}
                    }
                }
            })
            }
        })
    }

    render() {
        const { theme } = this.props;
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        const greetings = ["Hello", "Hi", "Cheers", "Hi", "Hello",
            "Good morning", "Morning,", "Good morning", "Morning,", "Good morning",
            "Hi", "Cheers", "Hi",
            "Good afternoon", "Good afternoon", "Good afternoon",
            "Hello", "Cheers", "Hi",
            "Good evening", "Evening", "Good evening",
            "Hello", "Hi", "Cheers"]
        const today = new Date();
        var logoProgram = logo;
        if (this.context.programLogo !== "" && this.context.programLogo !== undefined) {
            logoProgram = this.context.programLogo;
        }
        var icon = undefined;
        try{
            icon = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality, 'icon');
        }
        catch(e){
            icon = undefined;
        }
        var bgColor = "white";
        try {
            bgColor = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality, 'backgroundColor');
            //console.log('try:', bgColor);
        } catch (e) {
            bgColor = theme.palette.secondary.light;
            //console.log('catch:', bgColor);
        }
        return (
            <Card style={theme.CardTop}>
            
                <p style={theme.textTodayDate}>{weekdays[today.getDay()] + ', ' + months[today.getMonth()] + ' ' + today.getDate()}</p>
                <h4 style={theme.CardContent.titleText}>{greetings[today.getHours()] + ' ' + this.props.user.username}</h4>
                <Grid container spacing={2} >
                    <Grid item sm={6}>
                        <BadgeCard
                            backgroundColor ={"white"}
                            image={logoProgram} 
                            avatarImage = {undefined}
                            title = {"Open Coach Panel"}
                            subtitle = {"Team Insights"}
                            strongSubtitle = {true}
                            onClick={ () => {
                                (process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM!==undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER" )
                                    ? this.props.history.push(ROUTES.COACH)
                                    : void(0)
                                } }
                            clickText= {undefined}
                            button={
                                (process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM!==undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER" )
                                    ? <>Admin&nbsp;<RecordVoiceOverIcon/></>
                                    : undefined
                                }
                            />
                    </Grid>
                    {/** 
                    <Grid item sm={6}>
                        <BadgeCard
                            backgroundColor ={"white"}
                            image={undefined} 
                            avatarImage = 
                                {icon!== undefined && icon!== ""
                                    ?
                                        <AvatarImage type='imageIcon' image={icon}  vertical-align="middle" squareSize={"auto"}  backgroundColor={bgColor} />
                                    :
                                        <AvatarImage type='quality' vertical-align="middle" item={this.props.user.currentLeadershipQuality} squareSize={"auto"} backgroundColor={bgColor} />
                                }
                            title = {"Rocky.ai for Individuals"}
                            subtitle = {FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.user.currentLeadershipQuality)}
                            strongSubtitle = {true}
                            onClick={ window.location?.hostname?.includes('coach')
                                ?   () => {window.open('https://app.rocky.ai', 'Rocky.ai APP'); window.name = 'Rocky for TEAMS';}
                                :   () => { if(this.props.hideCoachView !== undefined) this.props.hideCoachView(); else this.props.history.push(ROUTES.PROFILE) } 
                            }
                            //clickText= {"Show"}
                            button={<>Rocky&nbsp;<RockyIcon /></>}
                            />                    
                    </Grid>
                    */}
                    {/** 
                    <Grid item sm={6}  > 
                        <BadgeCard
                            backgroundColor ={"white"}
                            image={logoHotline} 
                            avatarImage = {undefined}
                            title = {'Book 1-on-1'}
                            subtitle = {'Service Call'}
                            strongSubtitle = {true}
                            onClick={ () => window.open("https://calendly.com/rocky-ai/30min", "_blank") }
                            button={<>Book&nbsp;<HeadsetMicIcon /></>}
                            />                    
                    </Grid>

                    */}
                    <Grid item sm={6}  > 
                        <DialogTutorialPage 
                            handleBadgeFamily={'teams'} 
                            handleOpen={this.state.handleTutorialOpen || false}
                            handleClose={() => {this.setState({handleTutorialOpen: false})}}
                        />
                        <BadgeCard
                            backgroundColor ={"white"}
                            image={logoWhatsNext} 
                            avatarImage = {undefined}
                            title = {'Instructions to'}
                            subtitle = {'Get started'}
                            strongSubtitle = {true}
                            onClick={ () => { this.setState({handleTutorialOpen: true}) } }
                            clickText= {undefined}
                            button={<>Tutorial&nbsp;<HelpOutlineIcon /></>}
                        />
                    </Grid>
                </Grid>
            
            </Card>
        );
    }
}

const condition = authUser => 
    authUser && 
    (   authUser.roles?.includes(ROLES.ADMIN)
        || authUser.roles?.includes(ROLES.TEAM)
        || authUser.roles?.includes(ROLES.COACHADMIN)
        || authUser.roles?.includes(ROLES.REMOTECOACH)
    );

export default compose(
    withFirebase,
    withRouter,
    withStyles(styles, { withTheme: true }),
    withAuthorization(condition),
)(TodayCardsDesktopCoach);
