import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { AddAnalytics } from '../Modules/AddAnalytics';

import { IsMobileDevice } from "../Modules/IsMobileDevice";
import { CreateUserWithEmail } from ".";
import { MyInstallationOfflineDataSet, AddCodeToExistingAccountSet, GetUltimimUserSetup} from "../Modules/DefaultCode";

import withStyles from "@material-ui/core/styles/withStyles";
// style used same as from singin module
import {stylesSignin, linksInfo} from '../SignIn';
import {SignInSocialForward} from '../SignIn/SignInPage';
import {pageText} from '../SignIn';
import * as ROUTES from "../../constants/routes";

import ChatBlock from '../Routine/ChatBlock';

import logo from "../../images/rockyai-logo-image-only.png";
// import { SignInGoogle, SignInFacebook, SignInTwitter } from '../SignIn';
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
//import backgroundImage from "../../images/login-background.jpg";
//import people from "../../images/register-screen-people.png";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import EmailIcon from '@material-ui/icons/Email';
import SecurityIcon from '@material-ui/icons/Security';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LoadingProgress from '../Modules/LoadingProgress';

//HN
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import queryString from 'query-string';

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: '#20a5e6',
            },
            '&:hover fieldset': {
            borderColor: '#20a5e6',
            },
            '&.Mui-focused fieldset': {
            borderColor: '#20a5e6',
            },
        },
    },
})(TextField);

class SignUpPage extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        
        var loginType = 'normal';
        if (window.location && window.location.search !== undefined && window.location.search!==""){
            //var query = queryString.parse(window.location.search)
            var urlSearch = window.location.href.slice(window.location.href.indexOf('?'));
            var query = queryString.parse(urlSearch);
            console.log("detail loc sup", query)
            if(query.scope!==undefined && query.scope === "profile:user_id"){
                loginType = "alexaLinking"
            }
        }

        this.state = { ...INITIAL_STATE , loginType};
    }

    componentDidMount() {
        this._isMounted = true;
        if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            //console.log('browser')
        }
        else{
            this.internetConnectionCheck();
        }
        const { theme } = this.props;
        if(this._isMounted){
            if(window.StatusBar !== undefined && this.state.firebaseConnectionError !== true){
                window.StatusBar.overlaysWebView(false);
                window.StatusBar.backgroundColorByHexString(theme.palette.themeAltLoading);
                window.StatusBar.styleLightContent();
            }
        }
        AddAnalytics('screen_view', 'PageSignUp');
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    internetConnectionCheck() {
        this.props.firebase.onDisconnectSimple(this, (connected) => {
            if(connected === false && this.state.firebaseConnectionError === undefined){
                if(this._isMounted){
                    this.setState({firebaseConnectionMessage : 'checking your internet...'})
                }
            }
            else if(connected === false){
                if(this._isMounted){
                    this.setState({firebaseConnectionMessage : 'Trying to connect with the internet!'})
                }
            }
        });
    }

    render () {
        const { classes, theme, defaultSet } = this.props;

        if(this.state.firebaseConnectionError===true){
            return(
                <div style={theme.rootFrame}>
                    <div className={classes.rootSignupApp}>
                        <div>
                            <div style={{paddingTop: '25vH', paddingBottom: 20}} >
                                <LoadingProgress 
                                    defaultSet={defaultSet}
                                    type={'LoadingPageAnimated'} color='light' loadingText={"I'm getting ready"} />
                            </div>
                            <div >
                                {this.state.firebaseConnectionMessage !== undefined && this.state.firebaseConnectionMessage !== ''
                                    &&
                                    <p style={theme.textCenterPadding}>
                                        &nbsp; {this.state.firebaseConnectionMessage}
                                    </p> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div style={theme.rootFrame}>
                    <div className={(IsMobileDevice() || this.props.defaultSet?.programColorBack?.includes('#')) ? classes.rootSignupApp : classes.rootSignup}>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} style={{ width:'100%', maxWidth: 500 }}>
                                <div
                                    style={IsMobileDevice() ? theme.WelcomeLogin : theme.WelcomeLoginDT}
                                >
                                    <div>
                                        <div style={theme.logoContainer}>
                                            <img src={defaultSet?.programLogo?.length > 5 
                                                ?   this.props.defaultSet.programLogo
                                                :   logo
                                                }
                                                className={classes.logo}
                                                alt="AI Coaching Chatbot Journaling" 
                                            />
                                            <p style={theme.divCenter}>
                                                <strong>Sign Up for FREE</strong><br/><br/>
                                                {this.state.loginType === "alexaLinking"
                                                    ?  <strong> <br/> and connect with Alexa!</strong>
                                                    :  <span></span>
                                                }
                                            </p>
                                        </div>
                                        <SignUpForm
                                            defaultSignUpCode = {this.props.defaultSet?.programCode?.length > 0 ? this.props.defaultSet.programCode : undefined}
                                            defaultSet= {this.props.defaultSet || {}}
                                            installationSet = {this.props.installationSet || {}}
                                            loginType= {this.state.loginType}
                                        />
                                    </div>
                                    <div>
                                        <table width="100%" style={{paddingTop: 20}}>
                                            <tbody>
                                                <tr>
                                                    <td><hr /></td>
                                                    <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                        <span className={classes.socialLoginPrompt}>Already have an account?</span>
                                                    </td>
                                                    <td><hr /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className={classes.socialLogins}>
                                            <Link to={ROUTES.SIGN_IN}>
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    className={classes.buttonBottom}>
                                                    Log In
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={classes.footer}>
                                        {linksInfo(defaultSet)}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ); //end return
        }
    }; //end redner
}; //end class

const INITIAL_STATE = {
    username: "Padawan",
    email: "",
    passwordOne: "",
    coachcode: "",
    error: null,
    openCode: false,
};

class SignUpFormBase extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        var loadedCode = "";
        var coachcode = window?.sessionStorage?.getItem('urlCoachCode') || "";
        if(coachcode?.length > 3 && coachcode[0] === "#"){
            loadedCode = coachcode;
        }
        //console.log('loaded form gtemp', coachcode, dataSet)
        this.state = { 
            ...INITIAL_STATE, 
            falseCode:false, 
            coachcode: loadedCode,
            askCode: (loadedCode !== ""? false: undefined),
        };
        this.userId = "";
    }

    
    componentDidMount() {
        if (window.sessionStorage.getItem('username')) {
            var username = window.sessionStorage.getItem('username');
            window.sessionStorage.removeItem('username');
            //console.log('redirect username sign up', username )
            this.setState({username: username});
        }
        this._isMounted = true;
        if (this.props.location && this.props.location.state && this.props.location.state.checkAnonymous){
            if((this.props.coach === undefined || this.props.coach !== true )
                && this.state.openCode !== true 
                && this.state.loginType !== "alexaLinking"){
                this.setState({
                    //checkAnonymous: this.props.location.state.checkAnonymous,
                    username: "Padawan",
                    //username: "",
                    //error:{ message : 'You can add en email address later if you want to get notifications or access your account from other devices!'}
                }); 
            }
        }
        /// this function is used by SignUpCoachPage and overwrites the default setup
        var defaultDataSet = this.state.installationSet;
        GetUltimimUserSetup(this.props.firebase, MyInstallationOfflineDataSet, defaultDataSet, this.props.defaultSignUpCode, this.props.defaultSet?.programCode || "").then((newResult) => {
            if(newResult?.programs?.length > 0){
                this.setState({installationSet: newResult});
                //console.log('GetUltimimUserSetup newResult', newResult)
            }
        })
        /***
        if(this.props.defaultSignUpCode !== undefined && this.props.defaultSignUpCode!==''){
            GetDefaultUserSetup(this.props.firebase, defaultDataSet, this.props.defaultSignUpCode).then((result) => {
                if(this._isMounted){
                    this.setState({installationSet: result}); 
                }
            });
        }
        else{
            GetDefaultUserSetup(this.props.firebase, defaultDataSet).then((result) => {
                if(this._isMounted){
                    this.setState({installationSet: result}); 
                }
            });
        }
         */
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSubmitSignUpPage = async (event) => {
        this.setState({signUpDetected : true, error: null})
        //console.log('init db', this.state.installationSet);
        const { username, passwordOne, coachcode, installationSet } = this.state;
        var password  = passwordOne;
        var email  = this.state.email;
        var falseCodeProvided = false;
        const timestamp = this.props.firebase.fieldValue.serverTimestamp();
        var myInstallationUserDataSet = installationSet;
        if(this.props.defaultAccount?.newUser !== undefined){
            myInstallationUserDataSet = Object.assign(myInstallationUserDataSet, this.props.defaultAccount?.newUser);
            //console.log('added special myInstallationUserDataSet account', myInstallationUserDataSet)
        }
        if(this.state.checkAnonymous){
            var dateEmail = new Date();
            var timestampEmail = dateEmail.getTime();
            email = "anonymous" + timestampEmail + '@rocky.ai';
            password = Math.random().toString(36).slice(-8);
            //console.log(this.state.checkAnonymous, 'anonymous email',  email)
        }
        const isInvalid = password === "" || email === "" || username === "";
        if(isInvalid){
            event.preventDefault();
            var errorMissingData = new Error(pageText.ERROR_MSG_ADD_INFO);
            if(this._isMounted){
                this.setState({ error : errorMissingData });
            }
        }
        else{
            try{
                email = email.toLowerCase();
            }
            catch(err) {
                console.log(err)
            }
        }
        //console.log('coachcode starting', coachcode)
        if (coachcode?.includes("#") ){
            event.preventDefault();
            //const [codeExists, newSet] = ['false', {}];
            //let codeResults = this.checkForCode(coachcode);
            //console.log('is a coachcode there?', codeResults.codeExists)
            //console.log('newSet', codeResults.newSet)
            var codeSet = await this.props.firebase.signupCodeByName(coachcode).get()
            if(codeSet.exists){
                var newSet = codeSet.data().addingCode; 
                myInstallationUserDataSet['code'] = coachcode;
                myInstallationUserDataSet = AddCodeToExistingAccountSet(myInstallationUserDataSet, newSet);
                //console.log('singup set second', myInstallationUserDataSet)
                //this.setState({ error: null, falseCode: false});
                event.persist();
            }
            else{
                falseCodeProvided = true;
                //console.log('singup no extra data', myInstallationUserDataSet);
                var error = new Error(pageText.ERROR_MSG_INVALID_CODE); 
                if(this._isMounted){
                    this.setState({ error, falseCode: true});
                }
            }
        };//end of if coachcode
        //console.log('falseCodeProvided', falseCodeProvided);
        // this falseCode does not work yet due to async
        if(!falseCodeProvided && !isInvalid){
            if(this.state.checkAnonymous){
                Object.assign(myInstallationUserDataSet, {username, email, journeyStartedAt: timestamp, anonymousSignup: true});
                myInstallationUserDataSet['emailNotifications'] = 'inactive';
                myInstallationUserDataSet['anonymousPassword'] = password;
            }
            else{
                Object.assign(myInstallationUserDataSet, {username, email, journeyStartedAt: timestamp});
            }
            //console.log('im in', myInstallationUserDataSet);
            CreateUserWithEmail(this, username, email, password, myInstallationUserDataSet);
        }// end FalseCode
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    onChangeName = event => {
        var newName = event.target.value;
        if(newName === 'Padawa' || newName === 'Padawan'){
            newName = '';
        }
        if(newName.length > 1){
            newName = newName.charAt(0).toUpperCase() + newName.slice(1);
        }
        this.setState({ [event.target.name]: newName });
    };
    onChangeCode = event => {
        var newCode = event.target.value.trim()
        if(newCode.length > 0) { 
            newCode = newCode.replace(/[^a-z0-9-]/gi, '');
            newCode = newCode.toUpperCase();
            if(newCode.charAt(0) !== '#'){
                newCode = '#'+newCode;
            }
        }
        this.setState({ [event.target.name]: newCode });
    };
    onChangeEmail = event => {
        this.setState({ [event.target.name]: event.target.value.trim() });
    };
    onChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };
    // HN handler for coachcode
    handleCodeClick = () => {
        if(this.state.openCode === false && (this._isMounted)){
            this.setState({openCode: true, checkAnonymous: false, showSignCoachCode: true, showSignUpButtons: true});
        }
        if(this.state.openCode === true && (this._isMounted)){
            this.setState({openCode: false, coachcode:'', showSignCoachCode: true, showSignUpButtons: true});
        }        
        //console.log('close', this.state.openCode);
    };

    render() {
        //console.log(this.state)
        const { classes, theme } = this.props;
        const {
            username,
            email,
            passwordOne,
            coachcode,
            error,
            showSignUpButtons,
            showSignCoachCode
        } = this.state;
        return(
            <div>
                <Dialog
                    open={this.state.signUpDetected || false}
                    onClose={()=>{this.setState({signUpDetected:false})}}
                    PaperProps={{style:{borderRadius: theme.borderRadius}}}
                    >
                    <DialogContent>
                        <div className={classes.form}>
                            {!error && 
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} loadingText={"creating account"} />
                            }
                            {error && error.message !== undefined && error.message !== '' && <p className={classes.error}>{error.message}</p>}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{this.setState({signUpDetected:false})}} color="primary">
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {false &&
                <>
                    <ChatBlock
                        style={{marginTop:10, marginBottom:10}}
                        key={'askName'}
                        variant={'askName'}
                        //messages={[ {text: [ "Let us create your account so that you can personalize your journey.", "And start with your digital coach to develop yourself.",'Please tell me your name:'], isCoach: true, uid: 'askName'} ]} 
                        messages={[ {text: [ "Let us create your account so that you can personalize your journey.", 'You can change your name:'], isCoach: true, uid: 'askName'} ]} 
                        
                        //typingBotFinishTypingNow={this.state.askName || false}
                        typingBotFinishTypingNow={true}
                        typingDoneHandler={() => {
                            if(this._isMounted){
                                this.setState({askName: true});
                                //console.log('message end')
                                if(this.refAskName !== null && this.refAskName !== undefined){
                                    //console.log('message scrooll')
                                    //this.refAskName.scrollIntoView({ behavior: "smooth" });
                                }
                            }
                        }}
                    />
                    <div ref={(el) => { this.refAskName = el; }} style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                        {this.state.askName &&
                            <CssTextField
                                //autoFocus
                                name="username"
                                label={"Preferred nickname"}
                                value={username === undefined ? 'Padawan' : username}
                                onChange={this.onChangeName}
                                type="text"
                                variant="outlined"
                                placeholder={pageText.form_username}
                                //fullWidth
                                style = {{width: '80%', fontSize: '16px'}} 
                            />
                        }
                    </div>
                </>
                }
                {(true || (username!==undefined && username.length>0)) &&
                    <div>
                        <ChatBlock
                            style={{marginTop:10, marginBottom:10}}
                            key={'askCode'}
                            variant={'askCode'}
                            messages={[ {text: [
                                this.state.openCode !== true && !(coachcode?.length > 0)
                                    ?   "Have you been invited by someone and/or do you have a Coach-Code?"
                                    :   "Your special access coach-code:"
                                ], 
                                isCoach: true, 
                                uid: 'askName'} ]} 
                            //typingBotFinishTypingNow={this.state.askName || false}
                            typingBotFinishTypingNow={true}
                            typingDoneHandler={() => {
                                if(this._isMounted){
                                    this.setState({askCode: this.state.askCode !== false ? true: false, showSignUpButtons: true});
                                    //console.log('message end')
                                    if(this.refAskCode !== null && this.refAskCode !== undefined){
                                        //console.log('message scrooll')
                                        //this.refAskCode.scrollIntoView({ behavior: "smooth" });
                                    }
                                }
                            }}
                        />
                        <div ref={(el) => { this.refAskCode = el; }}></div>
                        {this.state.askCode &&
                            <div  style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                                <Button onClick={() => this.setState({showSignUpButtons: true, openCode: true, showSignCoachCode: true})}>
                                    Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button 
                                    onClick={() => {
                                        this.setState({showSignUpButtons: true, showSignCoachCode: false, coachcode:''});
                                        if(this.refAskLogin!== null && this.refAskLogin !== undefined){
                                            this.refAskLogin.scrollIntoView({ behavior: "smooth" });
                                        }
                                    }}>
                                    No
                                </Button>
                            </div>
                        }
                        <div>
                            {showSignCoachCode!==false &&
                                <div style={{ marginTop: 10, marginBottom:  10, textAlign: 'right' }}> 
                                    {this.state.openCode !== true && !(coachcode?.length > 0)  &&
                                        <p onClick={this.handleCodeClick} style={{  marginBottom:0  }}>
                                            <IconButton  aria-label="Add your Coach-Code" >
                                                <AddCircleOutlineIcon  fontSize="small" />
                                            </IconButton>
                                            {pageText.form_code}
                                        </p>
                                    }
                                    <Collapse in={this.state.openCode || coachcode?.length > 0} timeout="auto" unmountOnExit>
                                        <CssTextField
                                            name="coachcode"
                                            label={"Coach-Code"}
                                            value={coachcode}
                                            onChange={this.onChangeCode}
                                            type="text"
                                            variant="outlined"
                                            placeholder={pageText.form_code}
                                            //fullWidth
                                            style = {{width: '80%', fontSize: '16px'}} 
                                            />
                                    </Collapse>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div ref={(el) => { this.refAskLogin = el; }}>
                <ChatBlock
                    style={{marginTop:10, marginBottom:10}}
                    key={'askLogin'}
                    variant={'askLogin'}
                    messages={[ {text: ["What should I call you?"], isCoach: true, uid: 'askName'} ]} 
                    //messages={[ {text: ["How would you like to be connected with me on a regular basis?"], isCoach: true, uid: 'askName'} ]} 
                    typingBotFinishTypingNow={this.state.askName || false}
                    typingDoneHandler={() => {
                        this.setState({askLogin: true});
                        //console.log('message end')
                        if(this.refAskLogin!== null && this.refAskLogin !== undefined){
                            //console.log('message scrooll')
                            if(this.state.openCode && this.refAskCode!== null && this.refAskCode !== undefined){
                                //this.refAskCode.scrollIntoView({ behavior: "smooth" });
                            }
                            else{
                                //this.refAskLogin.scrollIntoView({ behavior: "smooth" });
                            }
                        }
                    }}
                />
                {this.state.askLogin
                &&
                    <div ref={(el) => { this.refAskName = el; }} style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                        <CssTextField
                            //autoFocus
                            name="username"
                            label="Nick name"
                            value={username === undefined ? "" : username === "Padawan" ? "" : username}
                            onChange={this.onChangeName}
                            type="text"
                            variant="outlined"
                            placeholder={pageText.form_username}
                            //fullWidth
                            style = {{width: '80%', fontSize: '16px'}} 
                        />
                    </div>
                }
                {(showSignUpButtons !== true || this.state.askLogin !== true || username==="Padawan" || username==="")
                    ?
                        <div className={classes.socialLogins}>
                            <Button 
                                className={classes.buttonBottom}
                                variant = "contained"
                                disabled = {true}
                            >
                                Add your Name
                            </Button>
                        </div>
                    : 
                    <form onSubmit={this.onSubmitSignUpPage}>
                        <div style={theme.logoContainer}>
                            <p style={theme.divCenter}>
                                <span className={classes.socialLoginPrompt}>
                                    {/***
                                        <br/>Sign in to continue with:&nbsp;
                                    */}
                                </span>
                            </p>
                        </div>
                        {this.state.showEmailSignInForm !== true && (coachcode===undefined || coachcode ==='') && !(this.props?.defaultSet?.programCode?.length > 5) && this.state.checkAnonymous &&
                            <>
                                <p style={theme.divCenter}>
                                    <span style={{color: theme.palette.secondary.light}}>
                                        Anonymous accounts are temporary, cannot be traced back and make sense if you want to test Rocky!
                                    </span>
                                </p>
                                <div className={classes.socialLogins}>
                                    <Button 
                                        className={classes.buttonBottomGold}
                                        //variant = "contained"
                                        type="submit"
                                        >
                                        <SecurityIcon/>&nbsp;Start Anonymously Now!
                                    </Button>
                                </div>
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td><hr /></td>
                                            <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                <span className={classes.socialLoginPrompt}>or</span>
                                            </td>
                                            <td><hr /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        }
                        {(true || coachcode===undefined || coachcode ==='') &&
                            <>
                                <span style={theme.textSupportCenter}>
                                    <span className={classes.socialLoginPrompt}>Sign Up with</span>
                                </span>
                                <SignInSocialForward 
                                    defaultSet={this.props.defaultSet}
                                    coachcode={coachcode || undefined}
                                    myInstallationOfflineDataSet= {this.state.installationSet || undefined}
                                    username={username!==undefined && username.length>1 ? username : undefined}
                                    //  installationCode= {coachcode || undefined}
                                />
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td><hr /></td>
                                            <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                <span className={classes.socialLoginPrompt}>or</span>
                                            </td>
                                            <td><hr /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        }
                        {error && error && error.message !== undefined && error.message !== '' && <p className={classes.error}>{error.message}</p>}
                        {this.state.showEmailSignInForm !== true &&
                            <div className={classes.socialLogins}>
                                <Button 
                                    onClick={() => this.setState({showEmailSignInForm: true, checkAnonymous: false})}
                                    className = {classes.buttonBottom}
                                    variant = {this.state.checkAnonymous? "outlined" : "text"}
                                    color = {this.state.checkAnonymous? "secondary" : "primary"}
                                    >
                                    <EmailIcon/>&nbsp;Sign Up with Email
                                </Button>
                            </div>
                        }
                        {(this.state.showEmailSignInForm) &&
                            <div>
                                <CssTextField
                                    name="email"
                                    label={pageText.form_email}
                                    value={email}
                                    onChange={this.onChangeEmail}
                                    type="text"
                                    variant="outlined"
                                    placeholder={pageText.form_email}
                                    fullWidth
                                    className={classes.textField}
                                />
                                <CssTextField
                                    name="passwordOne"
                                    label={pageText.form_password}
                                    value={passwordOne}
                                    onChange={this.onChange}
                                    type={this.state.showPassword ? "text" : "password"}
                                    variant="outlined"
                                    placeholder={pageText.form_password}
                                    fullWidth
                                    className={classes.textField}
                                    InputProps={{ // <-- This is where the toggle button is added.
                                        endAdornment: (
                                        <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.setState({showPassword: !this.state.showPassword})}
                                                >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                />
                                <div className={classes.socialLogins}>
                                    <Button
                                        className={classes.buttonBottom}
                                        //disabled={isInvalid}
                                        //variant = "contained"
                                        type="submit"
                                    >
                                        Sign&nbsp;up
                                    </Button>
                                </div>
                            </div>
                        }
                        {this.state.showEmailSignInForm !== true && (coachcode===undefined || coachcode ==='') 
                        && !(this.props?.defaultSet?.programCode?.length > 5) && !this.state.checkAnonymous
                        //// not for browser signups possible, only mobile app and only without coach coachcode
                        && !(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER")
                            && this.props.loginType !== "alexaLinking" 
                            &&
                            <>
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td><hr /></td>
                                            <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                <span className={classes.socialLoginPrompt}>or</span>
                                            </td>
                                            <td><hr /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={classes.socialLogins}>
                                    <Button 
                                        className={classes.buttonBottom}
                                        variant = "contained"
                                        onClick={() => {
                                            this.setState({checkAnonymous: true});
                                        } }
                                        >
                                        Try Anonymous Account 
                                    </Button>
                                </div>
                            </>
                        }
                        <div className={classes.socialLogins}>
                            <p style={{textAlign: 'center', fontSize: 14, marginTop:40}}> 
                                By signing up, you agree to the Rocky.ai
                                {" "}
                                <span style={{color: theme.palette.secondary.main, textDecoration: 'underline'}}
                                    onClick={ () => window.open("https://www.rocky.ai/terms-and-conditions", "_blank")}>
                                    Terms of Service
                                </span>
                                {
                                //<a href="https://www.rocky.ai/terms-and-conditions" target="_blank" rel="noopener noreferrer"> Terms of Service </a>
                                }
                                {". "}
                                Read our
                                {" "}
                                <span style={{color: theme.palette.secondary.main, textDecoration: 'underline'}}
                                    onClick={ () => window.open("https://www.rocky.ai/privacy-policy", "_blank")}>
                                    Privacy Policy
                                </span>
                                {
                                    //<a href="https://www.rocky.ai/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                }
                            </p>
                        </div>
                    </form>
                }
                </div>
            </div>
        );
    }
}



export const SignUpForm = compose(
    withStyles(stylesSignin, { withTheme: true }),
    withFirebase,
    withRouter,
)(SignUpFormBase);


export default compose(
    withRouter,
    withStyles(stylesSignin, { withTheme: true }),
    withFirebase,
)(SignUpPage);